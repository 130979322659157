import "../styles/_Common.scss";
import "../styles/_Login.scss";

import ReactDOM from 'react-dom';

class ChangeAvatar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            imageErrorText:         '',
            generalErrorText:     '',
        };
        
        this.doNotSubmit    = this.doNotSubmit.bind(this);
        this.handleSubmit    = this.handleSubmit.bind(this);
    }


      doNotSubmit(e) {
          e.preventDefault();
          console.log('prevented');
          return false;
      }
      
      handleSubmit(e) {
          e.preventDefault();
        
          var csrf_token =                ReactDOM.findDOMNode(this.refs.csrf_token).value;
          var image =                     ReactDOM.findDOMNode(this.refs.image).files[0];
          var user_id =                   ReactDOM.findDOMNode(this.refs.user_id).value.trim();

          this.setState(
              {
                                          imageErrorText:         '',
                                          generalErrorText:       '',
              }
          )
        
          allowSubmit = true;
        
          if (user_id.replace(/\s/g, '') == '') {
              allowSubmit = false;
              this.setState(
                  {
                                      generalErrorText:      "User ID required."
                  }
              )
          }
        

        
          if (image != undefined) {
              /*  image.type only checks the file extension, not the actual mimetype.  Lame.
              if ( ['jpg', 'jpeg', 'png', 'gif'].indexOf(image.name.split('.')[image.name.split('.').length - 1].toLowerCase()) == -1 ) {
                  allowSubmit = false;
                  this.setState(
                      {
                                      imageErrorText:  "Only images are allowed. Must be jpg, png, or gif.  " + " "
                      }
                  )
              }
              */
              if ( ['image/png', 'image/jpeg', 'image/gif'].indexOf(image.type) == -1 ) {
                  allowSubmit = false;
                  this.setState(
                      {
                                      imageErrorText:  "Only images are allowed." + " " + "Must be jpg, png, or gif.  " + " " + "Image has invalid mimetype: " + image.type + "."
                      }
                  )
              }
          } else {
              allowSubmit = false;
              this.setState(
                  {
                                  imageErrorText:  "You forgot the image file."
                  }
              )
          }
        
          if (allowSubmit) {
              ReactDOM.findDOMNode(this.refs.submitButton).value = 'Processing...';
            
              var formData = new FormData();
                  formData.append("csrf_token",               csrf_token);
                  formData.append("image",                    image);
                  formData.append("user_id",                  user_id);
                
              var ajaxReq =             new XMLHttpRequest();
                  ajaxReq.addEventListener(
                      "load", 
                      function() {
                          var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                          console.log(responseJSON)
                        
                          if ( responseJSON["msg"] == "success" ) {
                              this.setState( {
                                  "generalErrorText": responseJSON["msg"]
                              });
                              return;

                          } else {
                              // give user msg
                              this.setState( {
                                  "generalErrorText": responseJSON["msg"]
                              });
                              ReactDOM.findDOMNode(this.refs.submitButton).value = 'Submit';
                          }
                   
                      }.bind(this), 
                      false
                  );
                  ajaxReq.addEventListener(
                      "error", 
                      function(xhr, status, err) {
                          console.error("/change_avatar/", status, err.toString());
                          ReactDOM.findDOMNode(this.refs.submitButton).value = 'Submit';
                      }.bind(this), 
                      false
                  );
                  ajaxReq.open( "post", "/change_avatar/", true );
                  ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                  //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                  ajaxReq.send(formData);
          }
          return;
        
        }

    render() {
        var imageErrorDOM = null;
        var generalErrorDOM = null;
        
        if (this.state.imageErrorText) {
            imageErrorDOM =        <div className="errors" style={{"margin": "5px 0px 18px 0px","width": "100%"}}>
                                        { this.state.imageErrorText }
                                    </div>;
        }
        
        if (this.state.generalErrorText) {
            generalErrorDOM =       <div className="errors" style={{"margin": "20px 0px 18px 0px","width": "100%"}}>
                                        { this.state.generalErrorText }
                                    </div>;
        }
        return  (
            <div className="choose_username_div" style={{"paddingTop":"20px"}}>
            	<form   method="post" 
                        encType="multipart/form-data"
                        onSubmit={ this.doNotSubmit }
                >
            		<input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    { generalErrorDOM }
                                    { imageErrorDOM }
                                </td>
                            </tr>
                            <tr>
                                <td style={{"textAlign":"right"}}>
                                    User ID: 
                                </td>
                                <td>
                                    <input type="text" ref="user_id" style={{"width":"250px"}} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{"textAlign":"right"}}>
                                    Avatar File: 
                                </td>
                                <td>
                                    <input type="file" ref="image" accept="image/gif, image/jpeg, image/png" style={{"width":"250px"}} /><br />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{"paddingTop":"20px"}}>
                                    <input ref="submitButton" type="button" defaultValue="submit" className="form_button" onClick={this.handleSubmit} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                     
                </form>
            </div>
        );
    }
}

export default ChangeAvatar;
