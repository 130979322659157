import "../styles/_Profile.scss";
import "../styles/_Toggle.scss";
import "../styles/_PostContainer.scss";
import PostContainer  from "./PostContainer.jsx";
import SearchForms  from "./SearchForms.jsx";

import ReactDOM     from 'react-dom';

var Toggle =        require('react-toggle')


class ProfileForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            imageErrorText:             '',
            generalErrorText:           '',
            nameErrorText:              '',
            usernameErrorText:          '',
            emailErrorText:             '',
            postsCompleteJSON:          this.props.childProps.postsJSON, 
            postsVisibleJSON:           this.props.childProps.postsJSON.slice(0, 1),
            postsMore:                  this.props.childProps.more,
            postsCursor:                this.props.childProps.nextCursor,
            postLoadMsg:                null,
            lastPostShown:              1,
            userFinderText:             null,
            userFinderStyle:            { "display": "none" },
            followRequests:             this.props.childProps.followRequests,
            suggestedFollows:           this.props.childProps.suggestedFollows,
            followersCount:             this.props.childProps.profileJSON.followersCount,
            followingCount:             this.props.childProps.profileJSON.followingCount,
        };
        
        this.busyFetching                   = false;
        this.goToProfile                    = this.goToProfile.bind(this);
        this.getMorePosts                   = this.getMorePosts.bind(this);
        this.setVisiblePosts                = this.setVisiblePosts.bind(this);
        this.addPost                        = this.addPost.bind(this);
        this.editPost                       = this.editPost.bind(this);
        this.deletePost                     = this.deletePost.bind(this);
        this.doSearch                       = this.doSearch.bind(this);
        this.insertUser                     = this.insertUser.bind(this);
        this.searchFieldInit                = this.searchFieldInit.bind(this);
        this.findUser                       = this.findUser.bind(this);
        this.hideUserFinder                 = this.hideUserFinder.bind(this);
        this.handleSubmit                   = this.handleSubmit.bind(this);
        this.approveFollowHandler           = this.approveFollowHandler.bind(this);
        this.denyFollowHandler              = this.denyFollowHandler.bind(this);
        this.approveFollowSuggestionHandler = this.approveFollowSuggestionHandler.bind(this);
        this.denyFollowSuggestionHandler    = this.denyFollowSuggestionHandler.bind(this);
    }


    goToProfile(username) {
        window.location.href = "/profile/" + username;
    }
    
    getMorePosts() {
        if (this.busyFetching) {
            return;
        }
        this.busyFetching = true;
        
        if (this.state.postsMore) {
            this.setState( {
                postLoadMsg:      "loading..."          
            });
        
            var url =   "/get_filtered_posts/u/" + this.props.childProps.profileJSON.username + 
                        "?cursor=" + this.state.postsCursor;
                    
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                    
                        if ( responseJSON["msg"] == "success" ) { 
                            var newPosts =          responseJSON["json"].childProps.postsJSON;
                        
                            this.setState( {
                                postsCompleteJSON:  this.state.postsCompleteJSON.concat(newPosts), 
                                postLoadMsg:        "more loaded ↓",
                                postsMore:          responseJSON["json"].childProps.more,
                                postsCursor:        responseJSON["json"].childProps.nextCursor,
                                lastPostShown:      this.state.lastPostShown + 1,
                                postsVisibleJSON:   this.state.postsVisibleJSON.concat(newPosts[0])
                            }); 
                            /*
                            var newchildProps = update(this.props.childProps,
                                                  {
                                                      postsJSON: {$set: previousPosts.concat(newPosts)}
                                                  }
                                              );
                            this.setState( {
                                childProps: newchildProps         
                            });
                            */
                            this.busyFetching = false;
                                       
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                errorMsg: responseJSON["msg"]
                            });
                        }
               
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error( url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                                    
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.send();
        
        } else {
            this.setState( {
                postLoadMsg:      "-- end of feed --"          
            });
        }
    
    }

    setVisiblePosts() {
        var postsCompleteJSON = this.state.postsCompleteJSON;
        var lastPostShown =     this.state.lastPostShown;
    
        if ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) && (lastPostShown == postsCompleteJSON.length)) {
            this.getMorePosts();
        }
    
        // this operates the infinite scroll
        while ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) &&                                  (this.state.lastPostShown < postsCompleteJSON.length)) {
        
            lastPostShown = this.state.lastPostShown + 1;
            this.setState(
                { 
                    lastPostShown:      lastPostShown,
                    postsVisibleJSON:   this.state.postsVisibleJSON.concat(postsCompleteJSON[this.state.lastPostShown])
                }
            );
        }
    }

    componentDidMount() {    
        // didMount not finished yet, because post not loaded?
        window.addEventListener(
            "load", 
            function() {
                this.setVisiblePosts();
                window.addEventListener(
                    "resize", 
                    function() {
                                this.setVisiblePosts();
                            }.bind(this), 
                    false
                );
                window.addEventListener(
                    "scroll", 
                    function() {
                            this.setVisiblePosts();
                        }.bind(this), 
                    false
                );
            }.bind(this), 
            false
        );
    }

    addPost(postFormComponent) {
        var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        var videoWidth =    ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        var videoHeight =   ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;
    
        var formData = new FormData();
            formData.append("video",            video);
            formData.append("caption",          caption);
            formData.append("videoDuration",    duration);
            formData.append("videoWidth",       videoWidth);
            formData.append("videoHeight",      videoHeight);
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =              JSON.parse(ajaxReq.responseText);
                
                    if ( responseJSON["msg"] == "success" ) { 

                        var newPost = {
                            //"type":                  'post',
                            "postID":                responseJSON["postID"],
                            "userID":                currentUserID,
                            "username":              currentUsername,
                            "userFullName":          currentUserFullName,
                            "video":                 {
                                "url":                  GCS_VIDEO_PATH + 'p/' + responseJSON["postID"],
                                "time":                 responseJSON["time"],
                                "duration":             duration,
                                "width":                videoWidth,
                                "height":               videoHeight,
                                "caption":              caption,
                                "hashtags":             []
                                                     },
                            "likes":                 [],
                            "comments":              []
                        }
                
                 
                        var statePostsJSON =        this.state.postsCompleteJSON;
                        statePostsJSON.unshift(newPost);
                        this.setState( {
                            postsCompleteJSON:              statePostsJSON
                        });
                        postFormComponent.resetForm();
                    
                        return;

                    } else {
                        console.error("error posting " + responseJSON["postID"]);
                        return "error posting " + responseJSON["postID"];
                    }
               
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/add_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/add_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
            
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }

    editPost(postFormComponent) {
        var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        var postKeyJoin =   postFormComponent.props.postKeyJoin;
        //var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        //var videoWidth =   ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        //var videoHeight =  ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;
      
        var formData = new FormData();
        if (video != undefined) {
            formData.append("video", video);
        }
            formData.append("caption", caption);
            formData.append("postKeyJoin", postKeyJoin);
            //formData.append("videoDuration", duration);
            //formData.append("videoWidth", videoWidth);
            //formData.append("videoHeight", videoHeight);
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
      
                    if (responseJSON["msg"] == "success") {

                        var statePostsJSON =        this.state.postsCompleteJSON;
                        for (var index in statePostsJSON) {
                            if ( statePostsJSON[index]["postKeyJoin"] == postKeyJoin ) {
                                statePostsJSON[index].video.caption = caption;
                                this.setState( 
                                    {
                                        postsCompleteJSON:          statePostsJSON
                                    }
                                );
                                break;
                            }
                        }
                        postFormComponent.resetForm();

                    } else {
                        console.error("error posting " + responseJSON["msg"]);
                        return "error posting " + responseJSON["msg"];
                    }
               
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/edit_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/edit_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
            
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }

    deletePost(postKeyJoin) {
    
        var formData = new FormData();
            formData.append("postType",     "post");
            formData.append("parentPostId", postKeyJoin);
        
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
      
                    if (responseJSON["msg"] == "success") { 

                        var postsJSON =           this.state.postsCompleteJSON; 
                        for (var index in postsJSON) {
                            if ( postsJSON[index]["postKeyJoin"] == postKeyJoin ) {
                                postsJSON.splice(index, 1);
                                this.setState( 
                                    {
                                        postsCompleteJSON:          postsJSON
                                    }
                                );
                                break;
                            }
                        }

                    } else {
                        console.error("error deleting post " + postKeyJoin);
                    }
               
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/delete_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/delete_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
    }
 
    doSearch(e) {
        e.preventDefault();
        var goto = ReactDOM.findDOMNode(this.refs.searchInput).value;
        if (goto.startsWith("@")) {
            window.location.href = "/u/" + goto.substring(1);
        } else if (goto.trim().length == 0) {
            window.location.href = "/";
        } else {
            window.location.href = "/t/" + encodeURIComponent(goto.replace('#', 'ht-'));
        }
        return false;
    }

    insertUser(username) {
        var thisTextDOM =           ReactDOM.findDOMNode(this.refs.searchInput)
        var thisTextDOMValue =      thisTextDOM.value;
        var newText =               thisTextDOMValue.slice(0,thisTextDOMValue.lastIndexOf("@")+1) + username;
        thisTextDOM.value =         newText;
        this.setState(
            {
                                    userFinderText:     null,
                                    userFinderStyle:    { "display": "none" }
            }
        );
    }

    searchFieldInit() {
        var thisText =              ReactDOM.findDOMNode(this.refs.searchInput).value;
        var followingDOM =          (this.props.globalProps.currentUser.username == "anonymous")
                                        ? null
                                        : <div key={`search_following`}><a href="/">following</a></div>
        var recentSearches =        this.props.childProps.sortedRecentSearches;
    
        var recentSearchesDOM = recentSearches.map(
            function (search) {
                if (search.indexOf('@') == 0) {
                    var searchUrlEnc = search.substring(1);
                    return (
                        <div key={`search_${search}`}><a href={`/u/${searchUrlEnc}`}>{search}</a></div>
                    );
                } else {
                    var searchUrlEnc = encodeURIComponent(search.replace('#', 'ht-'));
                    return (
                        <div key={`search_${search}`}><a href={`/t/${searchUrlEnc}`}>{search}</a></div>
                    );
                }
            }
        );
    
        if (thisText.length == 0) {
            this.setState(
                {
                                userFinderStyle: { "display": "block", "margin":"0px" },
                                userFinderText: <div> 
                                                    <div key={`search_trending`}><a href="/p/trending">trending</a></div>
                                                    <div key={`search_recent`}><a href="/p/recent">recent</a></div>
                                                    { followingDOM }
                                                    { recentSearchesDOM }
                                                </div>
                }
            );
        } else {
            this.setState(
                {
                                userFinderStyle:    { "display": "none" }
                }
            );
        }
    }

    findUser() {
        var thisText =              ReactDOM.findDOMNode(this.refs.searchInput).value;
        var words =                 thisText.split(" ");
        var lastWord =              words[words.length-1];
        var insertUser =            this.insertUser;
        //var userFinderDiv =         ReactDOM.findDOMNode(this.refs.userFinderDiv);
        var userFinderText =        '';
        var followingJSON =         this.props.globalProps.currentUser.followingJSON;
    
        if (lastWord[0] == "@") {
            var userFinderText = followingJSON.map(
                function (member) {
                    if ( (member[0].indexOf(lastWord.slice(1)) == 0) || (lastWord.length == 1) ) {
                        var member0 = member[0];
                        var member1 = member[1];
                        return (
                            <div    className="tappable"
                                    onClick={ function(){ 
                                         insertUser( member0 ) 
                                        } 
                                    }
                            > 
                                    { member0 } { member1 }
                            </div>
                        );
                    }
                }
            );
        
            // remove empty items from mapped list
            userFinderText = userFinderText.filter(function(e){return e});
         
            if (userFinderText.length > 0) {
                this.setState(
                    {
                                    userFinderStyle: { "display": "block", "margin":"0px" },
                                    userFinderText: userFinderText
                    }
                );
            } else {
                this.setState(
                    {
                                    userFinderStyle:    { "display": "none" }
                    }
                );
            }
        
        } else {
            this.setState(
                {
                                    userFinderStyle:    { "display": "none" }
                }
            );
        }     
    }

    hideUserFinder() {
        this.setState(
            {
                                    userFinderStyle:    { "display": "none" }
            }
        );
    }

    ////////////////
    
    handleSubmit(e) {
        e.preventDefault();
        
        var userID =                    this.props.globalProps.currentUser.userID;
        var username =                  this.props.globalProps.currentUser.username;
        var privacy =                   (ReactDOM.findDOMNode(this.refs.privacy).checked)
                                            ? "public"
                                            : "private"
        
        var csrf_token =                ReactDOM.findDOMNode(this.refs.csrf_token).value;
        var image =                     ReactDOM.findDOMNode(this.refs.image).files[0];
        var hide_name =                 ReactDOM.findDOMNode(this.refs.hide_name).checked;
        var fullName =                  ReactDOM.findDOMNode(this.refs.fullName).value.trim();
        var slogan =                    ReactDOM.findDOMNode(this.refs.slogan).value.substring(0, 500);
        //var username =                 this.refs.username).value.trim();
        var hide_email =                ReactDOM.findDOMNode(this.refs.hide_email).checked;
        var email =                     ReactDOM.findDOMNode(this.refs.email).value.replace(/\s/g, "");
        
        this.setState(
            {
                                        imageErrorText:         '',
                                        generalErrorText:       '',
                                        nameErrorText:          '',
                                        usernameErrorText:      '',
                                        emailErrorText:         ''
            }
        )
        
        var allowSubmit = true;
        
        if (fullName.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    nameErrorText:      "Name required. Use the 'hide' feature if you do not want it made public."
                }
            )
        }
        
        if (username.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    usernameErrorText:      "Username required"
                }
            )
        }
        
        if (email.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    emailErrorText:      "Email address required"
                }
            )
        } else {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email)) {
                allowSubmit = false;
                this.setState(
                    {
                                    emailErrorText:      "Invalid email format"
                    }
                )
            }
        }
        
        if (image != undefined) {
            /*  image.type only checks the file extension, not the actual mimetype.  Lame.
            if ( ['jpg', 'jpeg', 'png', 'gif'].indexOf(image.name.split('.')[image.name.split('.').length - 1].toLowerCase()) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    imageErrorText:  "Only images are allowed. Must be jpg, png, or gif.  " + " "
                    }
                )
            }
            */
            if ( ['image/png', 'image/jpeg', 'image/gif'].indexOf(image.type) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    imageErrorText:  "Only images are allowed." + " " + "Must be jpg, png, or gif.  " + " " + "Image has invalid mimetype: " + image.type + "."
                    }
                )
            }
        } 
        
        if (allowSubmit) {
            var formData = new FormData();
                formData.append("csrf_token",               csrf_token);
                formData.append("image",                    image);
                formData.append("hide_name",                hide_name);
                formData.append("privacy",                  privacy);
                formData.append("name",                     fullName);
                formData.append("userID",                   userID);
                formData.append("username",                 username);
                formData.append("slogan",                   slogan);
                formData.append("hide_email",               hide_email);
                formData.append("email",                    email);
                formData.append("apns_on_like",             this.refs.apnsOnLike.state.checked);
                formData.append("apns_on_comment",          this.refs.apnsOnComment.state.checked);
                formData.append("apns_on_follow",           this.refs.apnsOnFollow.state.checked);
                formData.append("apns_on_mention",          this.refs.apnsOnMention.state.checked);
                formData.append("email_on_like",            this.refs.emailOnLike.state.checked);
                formData.append("email_on_comment",         this.refs.emailOnComment.state.checked);
                formData.append("email_on_follow",          this.refs.emailOnFollow.state.checked);
                formData.append("email_on_mention",         this.refs.emailOnMention.state.checked);
                
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        if ( responseJSON["msg"] == "success" ) {
                            location.href = '/profile/' + username;
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                generalErrorText: responseJSON["msg"]
                            });
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/edit_profile/", status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/edit_profile/", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.send(formData);
        }
        return;
        
      }
      
      approveFollowHandler(userID) {
          var formData = new FormData();
              formData.append("userID",     userID);
            
          var ajaxReq =             new XMLHttpRequest();
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
                      if ( responseJSON["msg"] == "success" ) { 

                          var followRequests =  this.state.followRequests;
                          
                          for (var index in followRequests) {
                              if (followRequests[index]["userID"] == userID) {
                        	    followRequests.splice(index, 1);
 
                                this.setState( {
                                    followRequests: followRequests,
                                    followersCount: this.state.followersCount + 1       
                                });
                                break;
                              }
                          }
                          return;

                      } else {
                          this.setState( 
                              {
                                  errorText:      "error"
                              }
                          );
                      }
                   
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/approve_follow_request/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/approve_follow_request/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
              ajaxReq.send(formData);
      }
      
      denyFollowHandler(userID) {
          var formData = new FormData();
              formData.append("userID",     userID);
            
          var ajaxReq =             new XMLHttpRequest();
              //ajaxReq.addEventListener(
              //      "progress", this.updateProgress or serve some wait msg, false
              //);
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
                      if ( responseJSON["msg"] == "success" ) { 

                          var followRequests =  this.state.followRequests;

                          for (var index in followRequests) {
                              if (followRequests[index]["userID"] == userID) {
                        	    followRequests.splice(index, 1);

                                this.setState( {
                                    followRequests: followRequests         
                                });
                                break;
                              }
                          }
                          return;

                      } else {
                          this.setState( 
                              {
                                  errorText:      "error"
                              }
                          );
                      }
                   
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/deny_follow_request/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/deny_follow_request/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
              ajaxReq.send(formData);
      }
      
      approveFollowSuggestionHandler(userID, index) {
          
          var myUserID =           this.props.globalProps.currentUser.userID;

          if (userID == undefined || myUserID == undefined) {
              this.setState( {
                  errorMsg: "user undefined"
              });
              return; // should never get here
          }

          var formData = new FormData();
              formData.append("profileUserID", userID);
    
          var ajaxReq =             new XMLHttpRequest();
              //ajaxReq.addEventListener(
              //      "progress", this.updateProgress or serve some wait msg, false
              //);
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
            
                      if ( responseJSON["msg"] == "success.  pending approval" ) { 
                          //var profileFollowers =        this.state.childProps.profileJSON.followers;
                          //profileFollowers.push(myUsername);
                
                          //var iAmFollowing = this.state.following;
                          //iAmFollowing.push(followUsername);
                          var suggestedFollows = this.state.suggestedFollows;
                              
                          for (var index in suggestedFollows) {
                              if (suggestedFollows[index]["userID"] == userID) {
                                  suggestedFollows.splice(index, 1)
                
                                  this.setState( {
                                      suggestedFollows: suggestedFollows         
                                  });
                                  break;
                              }
                          }
                          return;
                      } else if ( responseJSON["msg"] == "success" ) { 
                          /*
                          var profileFollowers =        this.state.childProps.profileJSON.followers;
                          profileFollowers.push(myUsername);
            
                          var iAmFollowing = this.state.following;
                          iAmFollowing.push(userID);
                          */
                          
                          var suggestedFollows = this.state.suggestedFollows;
                              
                          for (var index in suggestedFollows) {
                              if (suggestedFollows[index]["userID"] == userID) {
                                  suggestedFollows.splice(index, 1)
                                  
                                  this.setState( {
                                      suggestedFollows: suggestedFollows,
                                      followingCount:   this.state.followingCount + 1         
                                  });
                                  break;
                              }
                          }
                          return;

                      } else {
                          // give user msg
                          this.setState( {
                              errorMsg: responseJSON["msg"]
                          });
                      }
           
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/follow/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/follow/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
              ajaxReq.send(formData);
          return;

      }
      
      denyFollowSuggestionHandler(userID, index) {
          var formData = new FormData();
              formData.append("userID",     userID);
            
          var ajaxReq =             new XMLHttpRequest();
              //ajaxReq.addEventListener(
              //      "progress", this.updateProgress or serve some wait msg, false
              //);
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
                      if ( responseJSON["msg"] == "success" ) { 
                          
                          var suggestedFollows = this.state.suggestedFollows;
                              suggestedFollows.splice(index, 1)

                          this.setState( {
                              suggestedFollows: suggestedFollows         
                          });
                          return;

                      } else {
                          this.setState( 
                              {
                                  errorText:      "error"
                              }
                          );
                      }
                   
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/deny_follow_suggestion/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/deny_follow_suggestion/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
              ajaxReq.send(formData);
      }


    render() {
        
        var profileJSON =           this.props.childProps.profileJSON;
        var privacyBool =           (profileJSON.privacy != "private");
        
        var imageErrorDOM =         null;
        var generalErrorDOM =       null;
        var nameErrorDOM =          null;
        var usernameErrorDOM =      null;
        var emailErrorDOM =         null;
        
        if (this.state.imageErrorText) {
            imageErrorDOM =        <div className="errors" style={{"margin": "5px 0px 18px 0px","width": "100%"}}>
                                        { this.state.imageErrorText }
                                    </div>;
        }
        
        if (this.state.generalErrorText) {
            generalErrorDOM =       <div className="errors" style={{"margin": "20px 0px 18px 0px","width": "100%"}}>
                                        { this.state.generalErrorText }
                                    </div>;
        }
        if (this.state.nameErrorText) {
            nameErrorDOM =          <span className="errors">{ this.state.nameErrorText }</span>;
        }
        if (this.state.usernameErrorText) {
            usernameErrorDOM =      <span className="errors">{ this.state.usernameErrorText }</span>;
        }
        if (this.state.emailErrorText) {
            emailErrorDOM =         <span className="errors">{ this.state.emailErrorText }</span>;
        }
        
        var postLoadMsg = null;
        if (this.state.postLoadMsg) {
            postLoadMsg = <div className="center_this">
                            { this.state.postLoadMsg }
                       </div>
        }
        
        var followRequests =    this.props.childProps.followRequests;
        
        var followRequestsDOM = null;
        if (followRequests != undefined && followRequests.length > 0 ) {
            followRequestsDOMinner =      followRequests.map(
                                            function (followRequest) {
                                              return (
                                                <tr key={`fr-${followRequest.userID}`}
                                                    className="flex"
                                                >
                                                  <td style={{"width":"200px", "minWidth":"260px"}}>
                                                      <img  src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ followRequest.userID }_av_sm`}                                                                          style={{"verticalAlign":"middle", "marginRight":"10px"}} 
                                                            className="avatar_sm margin4px"
                                                            alt=""
                                                            onClick={function() {this.goToProfile(followRequest.username) }.bind(this)}
                                                      />
                                                      <div style={{"display":"inline-block"}}>
                                                            <a href={`/profile/${followRequest.username}`}>{`${ followRequest.name } (@${ followRequest.username })`}</a>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div  className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} 
                                                            onClick={ function() {this.approveFollowHandler(followRequest.userID) }.bind(this)}>
                                                          Approve
                                                      </div>
                                                      <div  className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} 
                                                            onClick={ function() {this.denyFollowHandler(followRequest.userID) }.bind(this)}>                             
                                                          Deny
                                                      </div>
                                                  </td>
                                                </tr>
                                              );
                                          }.bind(this))
            followRequestsDOM =       <div className="choose_username_div" style={{"paddingTop":"20px", "width":"400px"}}>
                                          <div style={{"textAlign":"center"}}>
                                              The following user(s) would like to follow your posts:
                                          </div>
                                          <table style={{"fontSize":"11px"}}>
                                              <tbody>
                                              { followRequestsDOMinner }
                                              </tbody>
                                          </table>
                                      </div>
                                
        }
        
        //var suggestedFollows =  this.props.childProps.suggestedFollows;
        
        var followSuggestionsDOM = null;
        /*  moved to public profile
        if (suggestedFollows != undefined && suggestedFollows.length > 0 ) {
            followSuggestionsDOMinner =   suggestedFollows.map(
                                            function (followSuggestion, i) {
                                              return (
                                                <tr key={`sf-${followSuggestion.userID}`}
                                                    className="flex"
                                                >
                                                  <td style={{"width":"200px", "minWidth":"260px"}}>
                                                          <img  src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ followSuggestion.userID }_av_sm`} 
                                                                style={{"verticalAlign":"middle"}} 
                                                                className="avatar_sm margin4px"
                                                                alt=""
                                                                onClick={function() {this.goToProfile(followSuggestion.username) }.bind(this)}
                                                          />
                                                          <div style={{"display":"inline-block"}}>
                                                                <a href={`/profile/${followSuggestion.username}`}>{`${ followSuggestion.name } (@${ followSuggestion.username })`}</a>
                                                          </div>
                                                  </td>
                                                  <td>
                                                      <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.approveFollowSuggestionHandler(followSuggestion.userID, i) }.bind(this)}>
                                                          Follow
                                                      </div>
                                                      <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.denyFollowSuggestionHandler(followSuggestion.userID, i) }.bind(this)}>                             
                                                          Remove
                                                      </div>
                                                  </td>
                                                </tr>
                                              );
                                          }.bind(this))
            followSuggestionsDOM =       <div className="choose_username_div width462" style={{"paddingTop":"20px"}}>
                                          <div style={{"textAlign":"center"}}>
                                          Here are some popular users, followed by your friends:
                                          </div>
                                          <table style={{"fontSize":"11px"}}>
                                              <tbody>
                                              { followSuggestionsDOMinner }
                                              </tbody>
                                          </table>
                                      </div>
                                
        }
        */

        var postNodes =      this.state.postsVisibleJSON.map(
            function (singlePostJSON) {
              return (
                  <div className = "feed" key={`postCntr_${ singlePostJSON.postID }`}>
                      <PostContainer 
                          currentUser =       { this.props.globalProps.currentUser }
                          singlePostJSON =    { singlePostJSON }
                          addPost =           { this.addPost }
                          editPost =          { this.editPost }
                          deletePost =        { this.deletePost }
                          globalProps =       { this.props.globalProps }
                          childProps=         { this.props.childProps }
                          key =               { `${singlePostJSON.postID}` }
                     />
                  </div>
              );
        }.bind(this));
        
        var followingDOM =   (this.state.followingCount == 0)
                                  ? "Following: 0"
                                  : <a href={`/following/${profileJSON.username}`}>
                                        {` Following:  ${this.state.followingCount}` }
                                    </a>;
           
        var followersDOM =   (profileJSON.followersCount == 0)
                                  ? "Followers: 0"
                                  : <a href={`/followers/${profileJSON.username}`}>
                                        {` Followers:  ${profileJSON.followersCount}` }
                                    </a>;
                        
        var postsDOM =       (profileJSON.postsCount == 0)
                                  ? "Posts: 0"
                                  : <a href={`/profile/${profileJSON.username}`}>
                                        {` Posts:  ${profileJSON.postsCount}` }
                                    </a>;
                 
        
        
                                         
        
        var messagesDOM = <div>
                                        {` Messages:  ${profileJSON.messagesCount}` }
                            </div>;
                                        
        return  (    
            <div>     
               <div className="search_forms">
                   <SearchForms 
                        currentUser =          { this.props.globalProps.currentUser }
                        sortedRecentSearches = { this.props.childProps.sortedRecentSearches }
                   />
               </div>                                       
            { followRequestsDOM }
            { followSuggestionsDOM }
            
            
            <div className="choose_username_div width462" style={{"paddingTop":"20px"}}>
            	
				<form method="post" encType="multipart/form-data">
            		<input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />
	
    
                    <div className="profile_header">User Profile for { profileJSON.username }</div>
                    <div className="profile_explain">(Note: Fields marked with an asterisk(*) are mandatory.  If you wish to hide a field from public view, check its "hide" option below.)</div>
    
                    <div className="profile_avatar">
                        <img src={`${this.props.globalProps.GCS_VIDEO_PATH}a/${profileJSON.userID}_av_lg`} className="profile_avatar_url" alt={ `${profileJSON.username} avatar` }/><br />

                        <label>Profile image</label><br />
                        <input ref="image" type="file" accept="image/gif, image/jpeg, image/png" /><br />
                    </div>
                    
                    { imageErrorDOM }
                    { generalErrorDOM }
                    
                    <div className="profile_field width462">
                        <div className="inline_block"><label>Name*:</label> </div> 
                        <div className="profile_hide">
                            <input ref="hide_name" type="checkbox" defaultChecked={ profileJSON.hide_name } /> <label className="text_small">hide</label>
                        </div>
                        <div>
                            { nameErrorDOM }
                            <input ref="fullName" className="form_input width460" type="text" defaultValue={ profileJSON.name } />
                        </div>
                    </div>
                        
                    <div className="profile_field width462">
                        <div className="inline_block"><label>Privacy*:</label> </div> 
                        <div className="profile_hide">
                            <input ref="privacy" type="checkbox" defaultChecked={ privacyBool } /> <label className="text_small">make posts public</label>
                        </div>
                        <div>
                        </div>
                    </div>
    
                    <div className="profile_field width462">
                        <div className="inline_block"><label>Email*:</label> </div>
                        <div className="profile_hide">
                            <input ref="hide_email" type="checkbox" defaultChecked={ profileJSON.hide_email } /> <label className="text_small">hide</label>
                        </div>
                        <div className="width_400">
                            { emailErrorDOM }
                            <input ref="email" className="form_input width460" type="email" defaultValue={ profileJSON.email } />     
                        </div>
                    </div>
   
                    <div className="profile_field width462">
                        <div className="inline_block">Password: <a href="/reset_password">Reset Password</a></div> 

                    </div>

                            
                    <div className="profile_field width462">
                        <div className="inline_block"><label>Slogan / bio / other info:</label> </div>
                        <div>
                            <textarea ref="slogan" defaultValue={ profileJSON.slogan } className="slogan" />
                        </div>
                    </div>
                            
                    <div className = "toggle_22px" style={{"marginTop":"20px"}}>
                            Push notification on Like:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.apns_on_like}
                                                    ref="apnsOnLike"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Push notification on Comment:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.apns_on_comment}
                                                    ref="apnsOnComment"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Push notification on Follow:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.apns_on_follow}
                                                    ref="apnsOnFollow"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Push notification on Mention:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.apns_on_mention}
                                                    ref="apnsOnMention"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Email on Like:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.email_on_like}
                                                    ref="emailOnLike"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Email on Comment:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.email_on_comment}
                                                    ref="emailOnComment"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Email on Follow:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.email_on_follow}
                                                    ref="emailOnFollow"
                                                    value="yes"
                                                />
                            </div>
                    </div>
                                
                    <div className = "toggle_22px">
                            Email on Mention:
                            <div  className="profile_hide" style={{"right":"14px"}}>
                                                <Toggle
                                                    defaultChecked={this.props.childProps.profileJSON.email_on_mention}
                                                    ref =           "emailOnMention"
                                                    value =         "yes"
                                                />
                            </div>
                    </div>
                                
                            
                    <div style={{"marginTop":"20px"}}>
                        <div>
                            { followingDOM }
                        </div>
                        <div>
                            { followersDOM }
                        </div>
                        <div>
                            { postsDOM }
                        </div>
                        {/*
                        <div>
                            { messagesDOM }
                        </div>
                        */}
                    </div>

    
    
                    <br /><br />
                    <input type="button" defaultValue="save, then view public profile" className="form_button" style={{"width":"170px"}} onClick={this.handleSubmit} />
    
                </form>
				
            </div>
            <div ref="main" key="posts">
                { postNodes }
                { postLoadMsg }
            </div>
            </div>
        );
    }
}


export default ProfileForm;
