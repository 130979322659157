import "../styles/_Common.scss";
import "../styles/_Login.scss";
import ReactDOM             from 'react-dom';

class ResetPassword extends React.Component {
    
    constructor(props) {
        super(props);
          
        this.state = {
            generalErrorText:     '',
            passwordErrorText:    '',
            passwordErrorText2:   '',
            passwordErrorText3:   '',
            emailErrorText:       ''
        };

        this.handlePasswordClick    = this.handlePasswordClick.bind(this);
        this.handleEmailClick       = this.handleEmailClick.bind(this);
        this.doNotSubmit            = this.doNotSubmit.bind(this);
    }
    

    
    //contextTypes: {
    //    router: React.PropTypes.func
    //},

    
    handlePasswordClick(e) {
        e.preventDefault();
        
        var csrf_token =            ReactDOM.findDOMNode(this.refs.csrf_token).value;
        var password1 =             ReactDOM.findDOMNode(this.refs.password1).value;
        var password2 =             ReactDOM.findDOMNode(this.refs.password2).value;
        var token =                 this.context.router.getCurrentParams().token;
        
        this.setState(
            {
                                    generalErrorText:       '',
                                    passwordErrorText:      '',
                                    passwordErrorText2:     '',
                                    passwordErrorText3:     ''
            }
        )
        
        var allowSubmit = true;
        
        if ((password1.replace(/\s/g, '') == '') || (password2.replace(/\s/g, '') == '')) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText:      "Both password fields required."
                }
            )
        }
        
        if ((password1.indexOf(' ') != -1) || (password2.indexOf(' ') != -1)) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText2:      "Spaces not allowed in password."
                }
            )
        }
        
        if (password1 != password2) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText3:      "Passwords entered do not match."
                }
            )
        }
        
        if (allowSubmit) {
            var formData = new FormData();
                formData.append("csrf_token",               csrf_token);
                formData.append("password1",                password1);
                formData.append("password1",                token);
                
            var ajaxReq =             new XMLHttpRequest();
                //ajaxReq.addEventListener(
                //      "progress", this.updateProgress or serve some wait msg, false
                //);
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        console.log(responseJSON)
                        
                        if ( responseJSON["msg"] == "success" ) {
                            location.href = '/reset_password_complete'
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                "generalErrorText": responseJSON["msg"]
                            });
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/reset_password/" + token + "/", status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/reset_password/" + token + "/", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                ajaxReq.send(formData);
        }
        return;
        
      }
      
      handleEmailClick(e) {
          e.preventDefault();
          
          var email =                     ReactDOM.findDOMNode(this.refs.email).value.trim();
          var csrf_token =                ReactDOM.findDOMNode(this.refs.csrf_token).value;
        
          this.setState(
              {
                                          generalErrorText:       '',
                                          emailErrorText:         ''
              }
          )
        
          var allowSubmit = true;
        
          if (email.replace(/\s/g, '') == '') {
              allowSubmit = false;
              this.setState(
                  {
                                      emailErrorText:      "Email address required."
                  }
              )
          } else {
              var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!re.test(email)) {
                  allowSubmit = false;
                  this.setState(
                      {
                                      emailErrorText:      "Invalid email format."
                      }
                  )
              }
          }
          
          if (allowSubmit) {
              var formData = new FormData();
                  formData.append("csrf_token",               csrf_token);
                  formData.append("email",                    email);
                
              var ajaxReq =             new XMLHttpRequest();
                  //ajaxReq.addEventListener(
                  //      "progress", this.updateProgress or serve some wait msg, false
                  //);
                  ajaxReq.addEventListener(
                      "load", 
                      function() {
                          var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                          console.log(responseJSON)
                        
                          if ( responseJSON["msg"] == "success" ) {
                              location.href = '/reset_password_emailed'
                              return;

                          } else {
                              // give user msg
                              this.setState( {
                                  "generalErrorText": responseJSON["msg"]
                              });
                          }
                   
                      }.bind(this), 
                      false
                  );
                  ajaxReq.addEventListener(
                      "error", 
                      function(xhr, status, err) {
                          console.error("/reset_password", status, err.toString());
                      }.bind(this), 
                      false
                  );
                  ajaxReq.open( "post", "/reset_password", true );
                  ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                  ajaxReq.send(formData);
          }
          return;
      }
      
      doNotSubmit(e) {
          e.preventDefault();
          console.log('prevented');
          return false;
      }

    render() {
        //var prop =                  this.props.globalProps.currentUser;
        var state =                 this.state;
        var step =                  this.props.childProps.step;
        var next =                  this.props.childProps.next;
        
        var generalErrorDOM =       null;
        var passwordErrorDOM =      null;
        var passwordErrorDOM2 =     null;
        var passwordErrorDOM3 =     null;
        var emailErrorDOM =         null;
        
        if (state.generalErrorText) {
            generalErrorDOM =       <div className="errors">{ state.generalErrorText }</div>;
        }
        if (state.passwordErrorText) {
            passwordErrorDOM =      <div className="errors">{ state.passwordErrorText }</div>;
        }
        if (state.passwordErrorText2) {
            passwordErrorDOM2 =      <div className="errors">{ state.passwordErrorText2 }</div>;
        }
        if (state.passwordErrorText3) {
            passwordErrorDOM3 =      <div className="errors">{ state.passwordErrorText3 }</div>;
        }
        if (state.emailErrorText) {
            emailErrorDOM =          <div className="errors">{ state.emailErrorText }</div>;
        }

        if (step == "getEmail") {
            formDOM =   <div>
                            <div className="reset_password_header">Reset Password</div>
                        Email address: {` `}
                            { generalErrorDOM }
                            { emailErrorDOM }
                            <input ref="email" className="form_input" style={{"width":"288px"}} type="text" /> 
                            <input type="button" value="submit" className="form_button" onClick={ this.handleEmailClick } />
                        </div>
        } else if (step == "enterNewPassword") {
            formDOM =   <div>
                            <div className="profile_field">
                                <div className="password_1_field">
                                    <div>New Password:</div>
                                    <div>
                                        <input ref="password1" className="form_input" style={{"width":"190px"}} type="password" />
                                    </div> 
                                </div>
        
                                <div className="password_2_field">
                                    <div>Re-enter New Password:</div>
                                    <div>
                                        <input ref="password2" className="form_input" style={{"width":"190px"}} type="password" />
                                    </div> 
                                </div>
        
                                <div style={{"width":"400px"}}>
                                    { generalErrorDOM }
                                    { passwordErrorDOM }
                                    { passwordErrorDOM2 }
                                    { passwordErrorDOM3 }
                                    <input type="button" value="submit" className="form_button" onClick={ this.handlePasswordClick } />
                                </div>
                            </div> 
                        </div>
        } else if (step == "resetPasswordComplete") {
            formDOM =   <div>
                            <div className="reset_password_header">Password Reset Complete</div>
                            <div className="password_form_login"><a href={`/login/?next=${ next }`}>Log in</a></div>
                        </div>
        } else if (step == "tokenError") {
            formDOM =   <div style={{"textAlign":"center"}}>
                            { this.props.childProps.msg }
                        </div>
        } else {
            formDOM =   <div style={{"textAlign":"center"}}>
                            { state.generalErrorText }
                        </div>
        }

        return  (
            <div className="choose_username_div" style={{"paddingTop":"20px"}}>
            	<form onSubmit={ this.doNotSubmit }>
                    <input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />

                    { formDOM }

                </form>
            </div>
        );
    }
}


export default ResetPassword;
