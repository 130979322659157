import "../styles/_PostContainer.scss";
import "../styles/_Login.scss";
                    
import PostContainer  from "./PostContainer.jsx";
import FollowHandler  from "./FollowHandler.jsx";
import SearchForms  from "./SearchForms.jsx";
import ReactDOM     from 'react-dom';

import { CSSTransition } from 'react-transition-group';

class Profile extends React.Component {
      // this.getParams() gets params from query string
      // this.props is passed as spread: {...this.state} from parent
      // this.state is loaded with props in compWillRcvProps

    constructor(props) {
        super(props);
        
        var followersCounters = {};
            followersCounters[this.props.childProps.profileJSON.userID] = this.props.childProps.profileJSON.followersCount;
                            
        this.state = {
            errorMsg:           '',
            // here state.postsVisibleJSON are those on the page.  state.postsCompleteJSON is the entire feed avail
            postsCompleteJSON:  this.props.childProps.postsJSON, 
            postsVisibleJSON:   this.props.childProps.postsJSON.slice(0, 1),    
            postsMore:          this.props.childProps.more,
            postsCursor:        this.props.childProps.nextCursor,
            postLoadMsg:        null,
            lastPostShown:      1,
            userFinderText:     null,
            userFinderStyle:    { "display": "none" },
            followingCount:     this.props.childProps.profileJSON.followingCount,
            followersCounters:  followersCounters,
            suggestedFollows:   this.props.childProps.suggestedFollows,
            showFollowSuggs:    false
        };
        
        this.busyFetching = false;
        this.incrementFollowersCount        = this.incrementFollowersCount.bind(this);
        this.getMorePosts                   = this.getMorePosts.bind(this);
        this.setVisiblePosts                = this.setVisiblePosts.bind(this);
        this.addPost                        = this.addPost.bind(this);
        this.editPost                       = this.editPost.bind(this);
        this.deletePost                     = this.deletePost.bind(this);
        this.approveFollowSuggestionHandler = this.approveFollowSuggestionHandler.bind(this);
        this.denyFollowSuggestionHandler    = this.denyFollowSuggestionHandler.bind(this);
        this.showFollowSuggestions          = this.showFollowSuggestions.bind(this);
        this.gotoEdit                       = this.gotoEdit.bind(this);
        
    }
    
    
    //contextTypes: {
    //    router: React.PropTypes.func
    //},


    incrementFollowersCount(userID, amount, msg) { 
        // amount can be negative to decrement
        msg = null; // not used in this view
        var followersCounters = this.state.followersCounters;

        followersCounters[userID] = followersCounters[userID] + amount;

        this.setState( {
            followersCounters: followersCounters         
        });
    }
    
    getMorePosts() {
        if (this.busyFetching) {
            return;
        }
        this.busyFetching = true;
        
        if (this.state.postsMore) {
            this.setState( {
                postLoadMsg:      "loading..."          
            });
            
            var url =   "/get_filtered_posts/u/" + this.props.childProps.profileJSON.username + 
                        "?cursor=" + this.state.postsCursor;
                        
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        if ( responseJSON["msg"] == "success" ) { 
                            var newPosts =          responseJSON["json"].childProps.postsJSON;

                            this.setState( {
                                postsCompleteJSON:  this.state.postsCompleteJSON.concat(newPosts), 
                                postLoadMsg:        "more loaded ↓",
                                postsMore:          responseJSON["json"].childProps.more,
                                postsCursor:        responseJSON["json"].childProps.nextCursor,
                                lastPostShown:      this.state.lastPostShown + 1,
                                postsVisibleJSON:   this.state.postsVisibleJSON.concat(newPosts[0])
                            }); 
                            /*var newchildProps = update(this.props.childProps,
                                                   {
                                                      postsJSON: {$set: previousPosts.concat(newPosts)}
                                                   }
                                                );
                            this.setState( {
                                childProps: newchildProps         
                            });
                            */
                            this.busyFetching = false;
                                           
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                errorMsg: responseJSON["msg"]
                            });
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error( url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                ajaxReq.send();
            
        } else {
            this.setState( {
                postLoadMsg:      "-- end of feed --"          
            });
        }
        
    }
    
    setVisiblePosts() {
        var postsCompleteJSON = this.state.postsCompleteJSON;
        var lastPostShown =     this.state.lastPostShown;
        
        if ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) && (lastPostShown == postsCompleteJSON.length)) {
            this.getMorePosts();
        }
        
        // this operates the infinite scroll
        while ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) &&                                  (this.state.lastPostShown < postsCompleteJSON.length)) {
            
            lastPostShown = this.state.lastPostShown + 1;
            this.setState(
                { 
                    lastPostShown:      lastPostShown,
                    postsVisibleJSON:   this.state.postsVisibleJSON.concat(postsCompleteJSON[this.state.lastPostShown])
                }
            );
        }
    }

    
    componentDidMount() {    
        // didMount not finished yet, because post not loaded?
        window.addEventListener(
            "load", 
            function() {
                this.setVisiblePosts();
                window.addEventListener(
                    "resize", 
                    function() {
                        this.setVisiblePosts();
                    }.bind(this), 
                    false
                );
                window.addEventListener(
                    "scroll", 
                    function() {
                        this.setVisiblePosts();
                    }.bind(this), 
                    false
                );
            }.bind(this), 
            false
        );
    }
    
    //********************************************************************
    //************  Note: addPost, editPost, deletePost  *****************
    //***************  are duplicated in ShowPosts.jsx  ******************
    //********************************************************************
    
    addPost(postFormComponent) {
        var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        var videoWidth =    ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        var videoHeight =   ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;
        
        var formData = new FormData();
            formData.append("video",            video);
            formData.append("caption",          caption);
            formData.append("videoDuration",    duration);
            formData.append("videoWidth",       videoWidth);
            formData.append("videoHeight",      videoHeight);
                
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =              JSON.parse(ajaxReq.responseText);
                    
                    if ( responseJSON["msg"] == "success" ) { 

                        var newPost = {
                            "postID":                responseJSON["postID"],
                            "userID":                this.props.globalProps.currentUser.userID,
                            "username":              this.props.globalProps.currentUser.username,
                            "userFullName":          this.props.globalProps.currentUser.fullName,
                            "video":                 {
                                "url":                  this.props.globalProps.GCS_VIDEO_PATH + 'p/' + responseJSON["postID"],
                                "time":                 responseJSON["time"],
                                "duration":             duration,
                                "width":                videoWidth,
                                "height":               videoHeight,
                                "caption":              caption,
                                "hashtags":             []
                                                     },
                            "likes":                 [],
                            "comments":              []
                        }
                    
                     
                        var statePostsJSON =        this.state.postsJSON;
                        statePostsJSON.unshift(newPost);
                        this.setState( {
                            postsCompleteJSON:              statePostsJSON
                        });
                        postFormComponent.resetForm();
                        
                        return;

                    } else {
                        console.error("error posting " + responseJSON["postID"]);
                        return "error posting " + responseJSON["postID"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/add_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/add_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
                
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }
    
    editPost(caption) {
        // this is not used.  Since we only edit caption, moved to PostCaption
        
        //var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        //var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        //var postID =        postFormComponent.props.postID;
        //var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        //var videoWidth =   ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        //var videoHeight =  ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;

        var formData = new FormData();
        //if (video != undefined) {
        //    formData.append("video", video);
        //}
            formData.append("caption", caption);
            formData.append("postID", postID);
            //formData.append("videoDuration", duration);
            //formData.append("videoWidth", videoWidth);
            //formData.append("videoHeight", videoHeight);
                
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") {
 
                        var statePostsJSON =        this.state.postsCompleteJSON;
                        for (var index in statePostsJSON) {
                            if ( statePostsJSON[index]["postID"] == postID ) {
                                statePostsJSON[index].video.caption = caption;
                                this.setState( 
                                    {
                                        postsCompleteJSON:          statePostsJSON
                                    }
                                );
                                break;
                            }
                        }
                        postFormComponent.resetForm();

                    } else {
                        console.error("error posting " + responseJSON["msg"]);
                        return "error posting " + responseJSON["msg"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/edit_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/edit_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
                
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }
    
    deletePost(postID) {

        var formData = new FormData();
            formData.append("postID", postID);
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") { 

                        var postsJSON =           this.state.postsVisibleJSON;
                         
                        for (var index in postsJSON) {
                            if ( postsJSON[index]["postID"] == postID ) {
                                postsJSON.splice(index, 1);
                                this.setState( 
                                    {
                                        postsVisibleJSON:          postsJSON
                                    }
                                );
                                break;
                            }
                        }

                    } else {
                        console.error("error deleting post " + postID);
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/delete_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/delete_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            ajaxReq.send(formData);
    }
    
    
    approveFollowSuggestionHandler(userID, index) {
        
        var myUserID =           this.props.globalProps.currentUser.userID;

        if (userID == undefined || myUserID == undefined) {
            this.setState( {
                errorMsg: "user undefined"
            });
            return; // should never get here
        }

        var formData = new FormData();
            formData.append("profileUserID", userID);
  
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if ( responseJSON["msg"] == "success.  pending approval" ) { 
                        //var profileFollowers =        this.state.childProps.profileJSON.followers;
                        //profileFollowers.push(myUsername);
              
                        //var iAmFollowing = this.state.following;
                        //iAmFollowing.push(followUsername);
                        var suggestedFollows = this.state.suggestedFollows;
                            
                        for (var index in suggestedFollows) {
                            if (suggestedFollows[index]["userID"] == userID) {
                                suggestedFollows.splice(index, 1)
              
                                this.setState( {
                                    suggestedFollows: suggestedFollows         
                                });
                                break;
                            }
                        }
                        return;
                    } else if ( responseJSON["msg"] == "success" ) { 
                        /*
                        var profileFollowers =        this.state.childProps.profileJSON.followers;
                        profileFollowers.push(myUsername);
          
                        var iAmFollowing = this.state.following;
                        iAmFollowing.push(userID);
                        */
                        
                        var suggestedFollows = this.state.suggestedFollows;
                            
                        for (var index in suggestedFollows) {
                            if (suggestedFollows[index]["userID"] == userID) {
                                suggestedFollows.splice(index, 1)
                                
                                this.setState( {
                                    suggestedFollows: suggestedFollows,
                                    followingCount:   this.state.followingCount + 1         
                                });
                                break;
                            }
                        }
                        return;

                    } else {
                        // give user msg
                        this.setState( {
                            errorMsg: responseJSON["msg"]
                        });
                    }
         
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/follow/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/follow/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
        return;

    }
    
    denyFollowSuggestionHandler(userID, index) {
        var formData = new FormData();
            formData.append("userID",     userID);
          
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    if ( responseJSON["msg"] == "success" ) { 
                        
                        var suggestedFollows = this.state.suggestedFollows;
                            suggestedFollows.splice(index, 1)

                        this.setState( {
                            suggestedFollows: suggestedFollows         
                        });
                        return;

                    } else {
                        this.setState( 
                            {
                                errorText:      "error"
                            }
                        );
                    }
                 
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/deny_follow_suggestion/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/deny_follow_suggestion/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
    }
     
    
    showFollowSuggestions() {
        var toggled = !this.state.showFollowSuggs;
        
        this.setState(
            { 
                showFollowSuggs:    toggled
            }
        );
    }
    
    gotoEdit() {
        window.location.href = "/profile/" + this.props.globalProps.currentUser.username + "/edit";
    }
    
  render() {
      var profileJSON =  this.props.childProps.profileJSON;
      
      if ((profileJSON.msg.indexOf("not found") != -1) || (profileJSON.msg.indexOf("not activated") != -1)) {
          return (
              <div className="choose_username_div" style={{"padding":"30px", "margin-bottom":"200px"}}>
              <div className="profile_header">{ profileJSON.msg }</div>
              </div>
          )
      } else {
          var banUserDOM = null;
          if (this.props.globalProps.currentUser.https == "superuser") {
              banUserDOM = <div style={{"textAlign":"center","padding":"25px"}}>
                              <a href={`/obfusG7Y6UA/ban_user/${ profileJSON.userID }`}>ban this user</a><br />
                              <a href={`/obfusG7Y6UA/ban_user/${ profileJSON.userID }/true`}>ban this user, and delete his posts</a>
                           </div>;
          }
      
          var usernameDOM;
          if (profileJSON["name"] != undefined) {
              usernameDOM = profileJSON["name"];
          } else {
              usernameDOM = ""; //"< private >";
          }
          
          var emailDOM;
          if (profileJSON["email"] != undefined) {
              emailDOM = <div>
                              <a href={`mailto:${profileJSON["email"]}`}>{ profileJSON["email"] }</a>
                         </div>
          }
          
          var sloganDOM = null;
          if (profileJSON["slogan"] != undefined) {
              var slogan = profileJSON["slogan"];
              sloganDOM =       <div>
                                    {`${slogan}`}
                                </div>
          }
             
      
          var followingDOM =   (profileJSON.followingCount == 0)
                                    ? <span>
										  0<br/>Following
                                      </span>
                                    : <a href={`/following/${profileJSON.username}`}>
										  {` ${profileJSON.followingCount}` }<br/>Following
                                      </a>;
                     
          var followersDOM =   (this.state.followersCounters[profileJSON.userID] == 0)
                                    ? <span>
										  0<br/>Followers
                                      </span>
                                    : <a href={`/followers/${profileJSON.username}`}>
                                          {` ${this.state.followersCounters[profileJSON.userID]}` }<br/>Followers
                                      </a>;
                                  
          var postsDOM =       <span>
									  {` ${profileJSON.postsCount}` }<br/>Posts
                               </span>;
                                
          var errorDOM = null;
          if (this.state.errorMsg) {
              errorDOM = <div className="center_this">
                              { this.state.errorMsg }
                         </div>
          }
          
          var postLoadMsg = null;
          if (this.state.postLoadMsg) {
              postLoadMsg = <div className="center_this">
                              { this.state.postLoadMsg }
                         </div>
          }
          
          var profileActions = null;
          if ( profileJSON.username == this.props.globalProps.currentUser.username ) {
              profileActions = <tr>
                                  <td>
                                      <div className="searchContainer">
                                          <div  className="search_input pointer"
                                                onClick = { this.showFollowSuggestions }
                                          >
                                               Follow Suggestions
                                          </div>
                                          <div  className="search_input pointer"
                                                onClick = { this.gotoEdit }
                                          >
                                               Edit Profile
                                          </div> 
                                      </div>
                                  </td>
                                </tr>;
          }
                  
          var suggestedFollows =  this.state.suggestedFollows;
        
          var followSuggestionsDOM = null;
          if (suggestedFollows != undefined && suggestedFollows.length > 0 && this.state.showFollowSuggs) {
              followSuggestionsDOMinner =   suggestedFollows.map(
                                              function (followSuggestion, i) {
                                                return (
                                                  <tr key={`sf-${followSuggestion.userID}`}
                                                      className="flex"
                                                  >
                                                    <td style={{"width":"200px", "minWidth":"260px"}}>
                                                            <img  src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ followSuggestion.userID }_av_sm`} 
                                                                  style={{"verticalAlign":"middle"}} 
                                                                  className="avatar_sm margin4px"
                                                                  alt=""
                                                                  onClick={function() {this.goToProfile(followSuggestion.username) }.bind(this)}
                                                            />
                                                            <div style={{"display":"inline-block"}}>
                                                                  <a href={`/profile/${followSuggestion.username}`}>{`${ followSuggestion.name } (@${ followSuggestion.username })`}</a>
                                                            </div>
                                                    </td>
                                                    <td>
                                                        <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.approveFollowSuggestionHandler(followSuggestion.userID, i) }.bind(this)}>
                                                            Follow
                                                        </div>
                                                        <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.denyFollowSuggestionHandler(followSuggestion.userID, i) }.bind(this)}>                             
                                                            Remove
                                                        </div>
                                                    </td>
                                                  </tr>
                                                );
                                            }.bind(this))
              followSuggestionsDOM =       <tr>
                                              <td>
                                                <div className="choose_username_div width462" style={{"paddingTop":"0px"}}>
                                                    <div style={{"paddingLeft":"20px"}}>
                                                    Here are some popular users, followed by your friends:
                                                    </div>
                                                    <table style={{"fontSize":"11px"}}>
                                                        <tbody>
                                                        { followSuggestionsDOMinner }
                                                        </tbody>
                                                    </table>
                                                </div>
                                             </td>
                                          </tr>                 
          }
          
          
          var postNodes =      this.state.postsVisibleJSON.map(
              function (singlePostJSON) {
                var addPost =                 this.addPost;
                var editPost =                this.editPost;
                var deletePost =              this.deletePost;
                  
                return (
                    <div className = "feed" key={`postCntr_${ singlePostJSON.postID }`}>
                        <PostContainer 
                            singlePostJSON =    { singlePostJSON }
                            addPost =           { addPost }
                            editPost =          { editPost }
                            deletePost =        { deletePost }
                            currentUser =       { this.props.globalProps.currentUser }
                            globalProps =       { this.props.globalProps }
                            childProps=         { this.props.childProps }
                            key =               { `${singlePostJSON.postID}` }
                       />
                    </div>
                );
          }.bind(this));

          return (
              <div>
              
                 <div className="search_forms">
                     <SearchForms 
                          currentUser =          { this.props.globalProps.currentUser }
                          sortedRecentSearches = { this.props.childProps.sortedRecentSearches }
                     />
                 </div>
              
              
                  <div className="choose_username_div" style={{"padding":"20px 0px", "width":"462px"}}>
              
                      <table style={{"width": "512px"}}>
                          <tbody>
                          <tr>
                              <td style={{"height": "125px", "width":"125px", "verticalAlign":"middle", "textAlign":"center"}}>
                                  <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ profileJSON.userID }_av_lg`} 
                                          className="profile_avatar_url"  
                                          alt={`${profileJSON.username } avatar`}
                                  />
	                          </td>
						  </tr>
						  <tr>
			                  <td style={{"width":"512px", "textAlign":"center", "padding":"0 0 2px 0"}}>
			                      <div className="profile_header">@{ profileJSON.username }</div>
			                      { banUserDOM }
			                  </td>		
			              </tr>
						  <tr>
                              <td style={{"width":"512px", "textAlign":"center", "padding":"0 0 2px 0"}}>
                                  { usernameDOM }
                              </td>							
                          </tr>
						  <tr>
                              <td style={{"width":"512px", "textAlign":"center", "padding":"0 0 2px 0"}}>
                                  { sloganDOM }								  
                              </td>
                          </tr>		  
						  <tr>
	                          <td style={{"width":"512px", "textAlign":"center", "padding":"0px"}}>
								  <span style={{"width":"512px"}}>
								  <span style={{"float": "left", "padding": "0 8px 0 151px", "textAlign":"center"}} onClick={  this.handleFollowingClick }>
			                          { followingDOM }
			                      </span>
			                      <span style={{"float": "left", "padding": "0 8px", "textAlign":"center"}} onClick={  this.handleFollowersClick }>
			                          { followersDOM }
			                      </span>
			                      <span style={{"float": "left", "padding": "0 8px", "textAlign":"center"}}>
			                          { postsDOM }
			                      </span>
									  </span>
	                          </td>
						
	                      </tr>
                          { profileActions }
                          { followSuggestionsDOM }
						  <tr>
                              <td style={{"width":"512px", "textAlign":"center", "padding":"4px"}}>
			                      <CSSTransition timeout={200} classNames="easeTransition">
			                          <FollowHandler    incrementFollowersCount =   { this.incrementFollowersCount }
			                                            profileJSON =     { profileJSON }
			                                            currentUser =     { this.props.globalProps.currentUser } 
			                                            globalProps =     { this.props.globalProps }
			                          />
			                      </CSSTransition>
                           
			                      { errorDOM }
								  
                              </td>
							
                          </tr>
						  </tbody>	  
                      </table>
								  			  								                            
                  </div>
                      
                  <div ref="main" key="posts">
                      { postNodes }
                      { postLoadMsg }
                  </div>
            </div>
          )            
      }  
  }
  
}


export default Profile;