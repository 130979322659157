import "../styles/_Profile.scss";
import "../styles/_PostContainer.scss";

import ReactDOM from 'react-dom';


class PreviewCreatorForm extends React.Component {
    
    constructor(props) {
        super(props);
                            
        this.state = {
            pathErrorText:              '',
            titleErrorText:             '',
            firstTextErrorText:         '',
            lastTextErrorText:          '',
            postIDArrayErrorText:           '',
            loopingVideosErrorText:     '',
            promoVideosErrorText:       '',
            generalErrorText:           '',
        };
        
        this.busyFetching   = false;
        this.handleSubmit   = this.handleSubmit.bind(this);
    }
    
    


    ////////////////
    
    handleSubmit(e) {
        
        e.preventDefault();

        var path =                      ReactDOM.findDOMNode(this.refs.path).value.trim();
        var title =                     ReactDOM.findDOMNode(this.refs.title).value;
        var firstText =                 ReactDOM.findDOMNode(this.refs.startText).value;
        var endText =                   ReactDOM.findDOMNode(this.refs.endText).value;
        var postIDArray =               ReactDOM.findDOMNode(this.refs.postIDArray).value;

		{/*
        var loopingVideo1 =             ReactDOM.findDOMNode(this.refs.loopingVideos).files[0];
        var promoVideo1 =               ReactDOM.findDOMNode(this.refs.promoVideos).files[0];
        */}
        this.setState(
            {
                                        pathErrorText:              '',
                                        titleErrorText:             '',
                                        firstTextErrorText:         '',
                                        lastTextErrorText:          '',
                                        postIDArrayErrorText:       '',
                                        loopingVideosErrorText:     '',
                                        promoVideosErrorText:       '',
                                        generalErrorText:           '',
            }
        )
        
        
        var allowSubmit = true;
        
        if (path.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    pathErrorText:      "Path required."
                }
            )
        }
        
        if (title.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    titleErrorText:      "Title required"
                }
            )
        }
        
        if (firstText.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    firstTextErrorText:   "First Text required"
                }
            )
        }
                
        if (endText.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    lastTextErrorText:      "Last Text required"
                }
            )
        } 
		
        if (postIDArray.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    postIDArrayErrorText:      "Post ID required"
                }
            )
        }
		
{/*        
        if (loopingVideo1 != undefined) {
	*/}
            /*  image.type only checks the file extension, not the actual mimetype.  Lame.
            if ( ['jpg', 'jpeg', 'png', 'gif'].indexOf(image.name.split('.')[image.name.split('.').length - 1].toLowerCase()) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    imageErrorText:  "Only images are allowed. Must be jpg, png, or gif.  " + " "
                    }
                )
            }
            */
	{/*
            if ( ['video/mp4'].indexOf(loopingVideo1.type) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    loopingVideoErrorText:  "Only mp4s are allowed." + " " + "Video has invalid mimetype: " + loopingVideo1.type + "."
                    }
                )
            }
        }
        
        if (promoVideo1 != undefined) {

            if ( ['video/mp4'].indexOf(promoVideo1.type) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    promoVideoErrorText:  "Only mp4s are allowed." + " " + "Video has invalid mimetype: " + promoVideo1.type + "."
                    }
                )
            }
        } 
*/}        
        
        if (allowSubmit && ReactDOM.findDOMNode(this.refs.submitButton).value != 'Processing...') {
            ReactDOM.findDOMNode(this.refs.submitButton).value = 'Processing...';
            
            var formData = new FormData();
                formData.append("path",                     path);
                formData.append("title",                    title);
                formData.append("firstText",                firstText);
                formData.append("endText",                  endText);
                formData.append("postIDArray",              postIDArray);
{/*                
                var filesLength = ReactDOM.findDOMNode(this.refs.loopingVideos).files.length;
                
                for (var x=0; x<filesLength; x++) {
                    formData.append("loopingVideos[]", ReactDOM.findDOMNode(this.refs.loopingVideos).files[x]);
                }
                //formData.append("loopingVideos",            ReactDOM.findDOMNode(this.refs.loopingVideos).files);
                formData.append("promoVideos[]",              ReactDOM.findDOMNode(this.refs.promoVideos).files[0]);
                
                //var xx = ReactDOM.findDOMNode(this.refs.loopingVideos).files;
                //var yy = ReactDOM.findDOMNode(this.refs.promoVideos).files;
                //debugger;
*/}
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        console.log(ajaxReq.responseText)
                        
                        if ( responseJSON["msg"] == "success" ) {
                            location.href = '/preview/' + path;
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                generalErrorText: responseJSON["msg"]
                            });
                            ReactDOM.findDOMNode(this.refs.submitButton).value = 'submit, then view';
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/preview_creator", status, err.toString());
                        ReactDOM.findDOMNode(this.refs.submitButton).value = 'submit, then view';
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/preview_creator", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.send(formData);
        }
        return;
        
      }


    render() {
        
        var pathErrorDOM =              null;
        var titleErrorDOM =             null;
        var firstTextErrorDOM =         null;
        var postIDArrayErrorDOM =       null;
        var lastTextErrorDOM =          null;
        var loopingVideosErrorDOM =     null;
        var promoVideosErrorDOM =       null;
        var generalErrorDOM =           null;
        
        if (this.state.pathErrorText) {
            pathErrorDOM =              <span className="errors">{ this.state.pathErrorText }</span>;
        }
        
        if (this.state.titleErrorText) {
            titleErrorDOM =             <span className="errors">{ this.state.titleErrorText }</span>;
        }
        if (this.state.firstTextErrorText) {
            firstTextErrorDOM =         <span className="errors">{ this.state.firstTextErrorText }</span>;
        }
        if (this.state.lastTextErrorText) {
            lastTextErrorDOM =          <span className="errors">{ this.state.lastTextErrorText }</span>;
        }
	    if (this.state.postIDArrayErrorText) {
	        postIDArrayErrorDOM =           <span className="errors">{ this.state.postIDArrayErrorText }</span>;
	    }

		
{/*        
		if (this.state.loopingVideosErrorText) {
           loopingVideosErrorDOM =     <span className="errors">{ this.state.loopingVideosErrorText }</span>;
        }
        if (this.state.promoVideosErrorText) {
            promoVideosErrorDOM =       <span className="errors">{ this.state.promoVideosErrorText }</span>;
        }
*/}
        if (this.state.generalErrorText) {
            generalErrorDOM =       <span className="errors">{ this.state.generalErrorText }</span>;
        }
                                        
        return  (    
            <div>
                <center>
                    <form action="" method="post" encType="multipart/form-data">                    

                        <h2>Freezify Promo Page Database Entry</h2>

                        <div>
                            <div>
                                <label>
                                    URL Path:
                                </label> 
                                { pathErrorDOM }
                            </div> 
                            <div>
                                <input ref="path" style={{"width":"250px","marginBottom":"20px","textAlign":"center"}} type="text" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <label>
                                    Page Title:
                                </label> 
                                { titleErrorDOM }
                            </div> 
                            <div>
                                <input ref="title" style={{"width":"250px","marginBottom":"30px","textAlign":"center"}} defaultValue="Welcome to freezify!" type="text" />
                            </div>
                        </div>


                        <div>
                            <div>
                                <label>
                                    First Text Block
                                </label> 
                                { firstTextErrorDOM }
                            </div>
                            <div>
                                <textarea ref="startText" style={{"width":"350px","marginBottom":"30px","height":"50px"}} >
                                </textarea>
                            </div>
                        </div>
			
                        <div>
                            <div>
                                <label>
                                    End Text Block
                                </label>
                                { lastTextErrorDOM }
                            </div>
                            <div>
                                <textarea ref="endText" style={{"width":"350px","marginBottom":"30px","height":"50px"}} >
                                </textarea>
                            </div>
                        </div>
			
                        <div>
                            <div>
                                <label>
                                    Post IDs
                                </label> 
                                { postIDArrayErrorDOM }
                            </div>
                            <div>
                                <textarea ref="postIDArray" style={{"width":"350px","marginBottom":"20px","height":"50px"}} >								
									{'{"postID": , "postName": "", "customVid":"true", "width":"512", "height":"512", "duration":"4"} | {"postID": 5739578472267776, "postName": "selfie", "customVid":"false", "width":"512", "height":"288", "duration":"4"} | {"postID": 8230039, "postName": "time-lapse", "customVid":"false", "width":"512", "height":"384", "duration":"4"} | {"postID": 6130005, "postName": "slow motion", "customVid":"false", "width":"512", "height":"512", "duration":"3.16"} | {"postID": 1180005, "postName": "light field", "customVid":"false", "width":"512", "height":"512", "duration":"0.96"} | {"postID": 6253835978801152, "postName": "panorama", "customVid":"false", "width":"512", "height":"672", "duration":"4"}'}
                                </textarea>
                            </div>
                        </div>
{/*			
                        <div>
                            <label>Looping Videos</label><br />
                            { loopingVideosErrorDOM }
                            <input  ref =       "loopingVideos" 
                                    type =      "file" 
                                    accept =    "video/mp4"  
                                    style =     {{"marginBottom":"10px"}} 
                                    multiple =  "true" 
                                    //required =  "true"
                            /><br />
                        </div>

                        <div>
                            <label>Promo Videos</label><br />
                            { promoVideosErrorDOM }
                            <input  ref =       "promoVideos" 
                                    type =      "file" 
                                    accept =    "video/mp4" 
                                    style =     {{"marginBottom":"10px"}} 
                            /><br />
                        </div>
*/}
                        { generalErrorDOM }
                        <input  ref = "submitButton"
                                type="button" 
                                defaultValue="submit, then view" 
                                className="form_button" 
                                style={{"marginTop":"40px","width":"200px"}} 
                                onClick={this.handleSubmit} 
                        />

                    </form>
                </center>				
            </div>
        );
    }
}



export default PreviewCreatorForm;
