import "../styles/_EditPost.scss";

import ReactDOM from 'react-dom';

class EditPost extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
        
        this.handleSubmit   = this.handleSubmit.bind(this);
    }
    
  handleSubmit(e) {
      e.preventDefault();
      var caption = ReactDOM.findDOMNode(this.refs.caption).value;
      this.props.editPost(caption)
      return;
  }
    
    render() {      
      return (
          <form style={{"padding":"26px"}}
                className="EditPost" 
                action="/" 
                method="post" 
                onSubmit={this.handleSubmit} 
          >
            <div id="edit_post_errors"/>
            <div>
                <label className="captionBar">
                    Caption:  <textarea 
                                        ref="caption" 
                                        defaultValue={ this.props.caption } 
                                        className="post_form_field" 
                              >
                              </textarea>
                </label>

                <div className = "submitBar">
                    <input type="submit" value="submit" className="formButton" />
                    <span className="cancelButton tappable" onClick={ this.props.hideForm }>
                      cancel
                    </span>
                </div>
             </div>
          </form>
      )   
    }
}


export default EditPost;
