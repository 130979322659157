import "../styles/_Common.scss";
import "../styles/_PostContainer.scss";
import LikesHandler from "./LikesHandler.jsx";
import CommentForm  from "./CommentForm.jsx";
import LoginForm    from "./LoginForm.jsx";
import EditPost     from "./EditPost.jsx";

import ReactDOM     from 'react-dom';

import { CSSTransition } from 'react-transition-group';

class PostCaption extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showForm:           "none",
            editingPost:        false,
            more:               "button",
            caption:            this.props.singlePostJSON.caption
        };

        this.checkLogin             = this.checkLogin.bind(this);
        this.editPost               = this.editPost.bind(this);
        this.onCommentClick         = this.onCommentClick.bind(this);
        this.hideForm               = this.hideForm.bind(this);
        this.onEditClick            = this.onEditClick.bind(this);
        this.onDeleteClick          = this.onDeleteClick.bind(this);
        this.onConfirmDeleteClick   = this.onConfirmDeleteClick.bind(this);
        this.onMoreClick            = this.onMoreClick.bind(this);
        this.onReportClick          = this.onReportClick.bind(this);
        this.onCopyClick            = this.onCopyClick.bind(this);
        this.onCancelClick          = this.onCancelClick.bind(this);
        this.focusLink              = this.focusLink.bind(this);
        this.share                  = this.share.bind(this);
        this.report                 = this.report.bind(this);
    }

    
    checkLogin(msg) {
        if (this.props.globalProps.currentUser.username == "anonymous"){
            this.setState(
                {
                    showForm:         "login",
                    loginMsg:         msg
                }
            );
            return false;
        } else {
            return true;
        }
    }
    
    editPost(caption) {
        if (caption == this.props.singlePostJSON.caption) {
            // no change.  no sense in submitting
            this.hideForm();
            return;
        }
        
        var formData = new FormData();
            formData.append("caption", caption);
            formData.append("postID", this.props.singlePostJSON.postID);
                
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") {
 
                        this.setState( 
                            {
                                caption:          caption
                            }
                        );
                        this.hideForm();
                        return;
                    } else {
                        console.error("error editing " + responseJSON["msg"]);
                        return "error posting " + responseJSON["msg"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/edit_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/edit_post/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
            ajaxReq.send(formData);       
    }
    
    onCommentClick() {
        if ( this.checkLogin("You must be logged in to Comment") ) {
            this.setState(
                {
                    showForm:         "comment"
                
                }
            );
        }
    }
    
    hideForm() {
        this.setState(
            {
                showForm:             "none",
                editingPost:          false,
                more:                 "button" 
            }
        );
    }
    
    onEditClick() {
        this.setState(
            {
                editingPost:         true
            }
        );
    }
    
    onDeleteClick() {
        this.setState( 
            {
                more:               "confirmDelete"
            }
        );
    }
    
    onConfirmDeleteClick() {
        this.props.deletePost( this.props.singlePostJSON.postID );
        this.hideForm();
    }
    
    onMoreClick() {
        this.setState( 
            {
                more:          "dialog"
            }
        );
    }
    
    onReportClick() {
        this.setState( 
            {
                more:          "report"
            }
        );
    }
    
    onCopyClick() {
        this.setState( 
            {
                more:          "link"
            }
        );
    }
    
    onCancelClick() {
        this.setState( 
            {
                more:          "button"
            }
        );
    }
    
    focusLink() {
        setTimeout(function () {  ReactDOM.findDOMNode(this.refs.linkInput).select(); }.bind(this), 100);
    }
    
    share(site) {
        var postID = this.props.singlePostJSON.postID;
        switch(site) {
            case "google":
                var url = "https://plus.google.com/share" + 
                          "?url=" + encodeURIComponent("https://www.freezify.com/i/" + postID) +  
                          "&description=" + encodeURIComponent("I think I just forgot about time.  Check out this post on freezify!  ");
                break;
            case "twitter":     
                var url = "http://twitter.com/share" + 
                          "?url=" + encodeURIComponent("https://www.freezify.com/i/" + postID) +  
                          "&text=" + encodeURIComponent("I think I just forgot about time.  Check out this post on freezify!  ");
                          
                break;
            case "pinterest":
                var url = "https://pinterest.com/pin/create/button/" + 
                          "?url=" + encodeURIComponent("https://www.freezify.com/i/" + postID) +  
                          "&media=" + encodeURIComponent("https://storage.googleapis.com/freezify.appspot.com/t/" + postID) +
                          "&description=" + encodeURIComponent("I think I just forgot about time.  Check out this post on freezify!  ");
                          
                break;
            default:
                // facebook
                var url = "https://www.facebook.com/sharer/sharer.php" + 
                          "?u=" + encodeURIComponent("https://www.freezify.com/i/" + postID) +  
                          "&" + encodeURIComponent("p[images][0]") + 
                          "=" + encodeURIComponent("https://storage.googleapis.com/freezify.appspot.com/t/" + postID) +
                          "&description=" + encodeURIComponent("I think I just forgot about time.  Check out this post on freezify!  ");
        }
        var win = window.open(url, '_blank');
        win.focus();
    }
    
    report(reason){
        var formData = new FormData();
            formData.append("reportAs", reason);
            formData.append("postID", this.props.singlePostJSON.postID);
                
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") {
                        this.props.reported();
                        return;
                    } else {
                        return "error posting " + responseJSON["msg"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/report_inappropriate/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/report_inappropriate/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
            ajaxReq.send(formData); 
    }
    

  render() {
      var singlePostJSON =    this.props.singlePostJSON;
      var postID =            singlePostJSON.postID;
      var share =             this.share;
      var report =            this.report;
      
      var transitionKey =     "commentform-" + postID;
      
      var allowDeleteDOM =     null;
      var allowEditDOM =       null;
      
      if ( (singlePostJSON.userID == this.props.globalProps.currentUser.userID) || this.props.globalProps.currentUser.https == "superuser" ) {
          allowDeleteDOM =     <div  className="tappable"
                                     onClick = { this.onDeleteClick }
                               >
                                    delete post 
                               </div>
          allowEditDOM =       <div  className="tappable"
                                     onClick = { this.onEditClick }
                               >
                                    edit caption 
                               </div>
      }
      
      var moreChoices = {
          "button":             <div className="more_button  l_c_button_off" onClick={ this.onMoreClick }>
                                       ...
                                </div>,
          "dialog":             <div className="more">
                                    <div className="sharing_container">
                                         <div  className="sharing_facebook_icon"
                                               onClick = { function(){ share("facebook") } }
                                         />
                                         <div  className="sharing_google_icon"
                                               onClick = { function(){ share("google") } } 
                                         />
                                         <div  className="sharing_twitter_icon"
                                               onClick = { function(){ share("twitter") } }
                                         />
                                         <div  className="sharing_pinterest_icon"
                                               onClick = { function(){ share("pinterest") } }
                                         />
                                    </div>
                                    { allowDeleteDOM }
                                    { allowEditDOM }
                                     <div  className="tappable"
                                           onClick = { this.onCopyClick }
                                     >
                                          copy link 
                                     </div>
                                     
                                     <div  className="tappable"
                                           onClick = { this.onReportClick }
                                     >
                                          report 
                                     </div>
                                     <div  className="tappable"
                                           onClick = { this.onCancelClick }
                                     >
                                          cancel 
                                     </div>
                                 </div>,
          "confirmDelete":       <div className="more">
                                        really delete?<br />
                                        <div className="l_c_button_off _red" onClick={ this.onConfirmDeleteClick }>
                                              Yes
                                        </div>
                                        <div className="l_c_button_off _green" onClick={ this.onCancelClick }>
                                              No
                                        </div>
                                  </div>,
          "report":               <div className="more">
                                        <div style={{"padding":"8px"}}> 
                                           report as:
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Offensive" ) }  }>
                                              Offensive
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Explicit" ) }  }>
                                              Explicit
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Illegal" ) }  }>
                                              Illegal
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Spam" ) }  }>
                                              Spam
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Copyright" ) }  }>
                                              Copyright
                                        </div>
                                        <div className="tappable" onClick={ function(){ report( "Other" ) }  }>
                                              Other
                                        </div>
                                        <div className="tappable" onClick={ this.onCancelClick }>
                                              cancel
                                        </div>
                                   </div>,
          "link":                  <div className="more" ref="wwwww">
                                        ctrl-c (cmd-c for Mac) to copy:<br />
                                        <input ref="linkInput"
                                               type="text"
                                               onFocus={ this.focusLink }
                                               defaultValue={`https://www.freezify.com/i/${postID}`}
                                               style={{"width":"250px"}}
                                               key={`link-${postID}`}
                                               readOnly
                                               autoFocus
                                        />
                                        <div className="tappable" onClick={ this.onCancelClick }>
                                               cancel
                                        </div>
                                            
                                   </div>
      };
      
      var formChoices = {
          "none":             <div key={transitionKey}></div>,
          "comment":          <CommentForm 
                                  singleCommentJSON =     { singlePostJSON }
                                  currentUser =           { this.props.globalProps.currentUser }
                                  globalProps =           { this.props.globalProps }
                                  childProps =            { this.props.childProps }  
                                  hideForm =              { this.hideForm }
                                  addComment =            { this.props.addComment }
                                  key =                   {`post_comment_form-${ postID }`}
                              />,
          "login":            <LoginForm 
                                  msg =                   { this.state.loginMsg }
                                  globalProps =           { this.props.globalProps }
                                  hideForm =              { this.hideForm }
                                  key =                   {`login_form-${ postID }`}
                              />
      };
      
      if (this.state.editingPost) {
          return (
              <EditPost
                  editPost =      { this.editPost }
                  key =           {`edit-post-${ postID }`}
                  caption =       { singlePostJSON.caption }
                  hideForm =      { this.hideForm }
              />
          );
      }
      
      
      return (
          <div>
              <div className="postContainer">
                  <div className="caption2">
                        <div id={`caption_${postID}`}>{ this.state.caption }</div>
                  </div>
                  <div className="upperRightLinks">
                      <LikesHandler 
                           numLikes =           { singlePostJSON.numLikes }
                           iLikedIt =           { singlePostJSON.likedIt }
                           postID =             { singlePostJSON.postID  }
                           globalProps =        { this.props.globalProps }
                           currentUser =        { this.props.globalProps.currentUser }
                           checkLogin =         { this.checkLogin }
                           key =                {`likes_handler_${ postID }`}
                      />
                      <div className="l_c_button_off" onClick={ this.onCommentClick }>
                          comment
                      </div>
                      <CSSTransition timeout={200} classNames="easeTransition">
                           { moreChoices[this.state.more] }
                      </CSSTransition>
                  </div>     
              </div>
              
              <CSSTransition timeout={200} classNames="easeTransition">
                  { formChoices[this.state.showForm] }
              </CSSTransition>
          </div>
      );

  }
}


export default PostCaption;