import "../styles/_Common.scss";

class TrendingHashtags extends React.Component {
    
  render() {
      // React is safe, and encodes <> to prevent XSS injections
      // so, we put separate paragraphs in a list
      
      var hashtags =      this.props.childProps.msg;
      
      var topRow =        <div style={{"fontSize":"28px", "lineHeight":"28px"}}>
                              <a href={`/t/ht-${hashtags[0]}`}>#{hashtags[0]}</a>
                          </div>; 
      var secondRow =     <div style={{"fontSize":"24px", "lineHeight":"24px"}}>
                              <a href={`/t/ht-${hashtags[1]}`}>#{hashtags[1]}</a>, 
                              <a href={`/t/ht-${hashtags[2]}`}>#{hashtags[2]}</a>
                          </div>;
      var thirdRow =      <div style={{"fontSize":"20px", "lineHeight":"20px"}}>
                              <a href={`/t/ht-${hashtags[3]}`}>#{hashtags[3]}</a>, 
                              <a href={`/t/ht-${hashtags[4]}`}>#{hashtags[4]}</a>, 
                              <a href={`/t/ht-${hashtags[5]}`}>#{hashtags[5]}</a>
                          </div>;
      var fourthRow =     <div style={{"fontSize":"16px", "lineHeight":"16px"}}>
                              <a href={`/t/ht-${hashtags[6]}`}>#{hashtags[6]}</a>, 
                              <a href={`/t/ht-${hashtags[7]}`}>#{hashtags[7]}</a>, 
                              <a href={`/t/ht-${hashtags[8]}`}>#{hashtags[8]}</a>, 
                              <a href={`/t/ht-${hashtags[9]}`}>#{hashtags[9]}</a>
                          </div>;
      var fifthRow =      <div style={{"fontSize":"14", "lineHeight":"14px"}}>
                              <a href={`/t/ht-${hashtags[10]}`}>#{hashtags[10]}</a>, 
                              <a href={`/t/ht-${hashtags[11]}`}>#{hashtags[11]}</a>, 
                              <a href={`/t/ht-${hashtags[12]}`}>#{hashtags[12]}</a>, 
                              <a href={`/t/ht-${hashtags[13]}`}>#{hashtags[13]}</a>, 
                              <a href={`/t/ht-${hashtags[14]}`}>#{hashtags[14]}</a>
                          </div>;
      var sixthRow =      <div style={{"fontSize":"13px", "textAlign":"center"}}>
                              <a href={`/t/ht-${hashtags[15]}`}>#{hashtags[15]}</a>, 
                              <a href={`/t/ht-${hashtags[16]}`}>#{hashtags[16]}</a>, 
                              <a href={`/t/ht-${hashtags[17]}`}>#{hashtags[17]}</a>, 
                              <a href={`/t/ht-${hashtags[18]}`}>#{hashtags[18]}</a>, 
                              <a href={`/t/ht-${hashtags[19]}`}>#{hashtags[19]}</a>
                          </div>;
      var seventhRow =    <div style={{"fontSize":"13px", "textAlign":"center"}}>
                              <a href={`/t/ht-${hashtags[20]}`}>#{hashtags[20]}</a>, 
                              <a href={`/t/ht-${hashtags[21]}`}>#{hashtags[21]}</a>, 
                              <a href={`/t/ht-${hashtags[22]}`}>#{hashtags[22]}</a>, 
                              <a href={`/t/ht-${hashtags[23]}`}>#{hashtags[23]}</a>, 
                              <a href={`/t/ht-${hashtags[24]}`}>#{hashtags[24]}</a>
                          </div>;
      var eighthRow =     <div style={{"fontSize":"13px", "textAlign":"center"}}>
                              <a href={`/t/ht-${hashtags[25]}`}>#{hashtags[25]}</a>, 
                              <a href={`/t/ht-${hashtags[26]}`}>#{hashtags[26]}</a>, 
                              <a href={`/t/ht-${hashtags[27]}`}>#{hashtags[27]}</a>, 
                              <a href={`/t/ht-${hashtags[28]}`}>#{hashtags[28]}</a>, 
                              <a href={`/t/ht-${hashtags[29]}`}>#{hashtags[29]}</a>
                          </div>;
      var ninthRow =      <div style={{"fontSize":"13px", "textAlign":"center"}}>
                              <a href={`/t/ht-${hashtags[30]}`}>#{hashtags[30]}</a>, 
                              <a href={`/t/ht-${hashtags[31]}`}>#{hashtags[31]}</a>, 
                              <a href={`/t/ht-${hashtags[32]}`}>#{hashtags[32]}</a>, 
                              <a href={`/t/ht-${hashtags[33]}`}>#{hashtags[33]}</a>, 
                              <a href={`/t/ht-${hashtags[34]}`}>#{hashtags[34]}</a>
                          </div>;
      var tenthRow =      <div style={{"fontSize":"13px", "textAlign":"center"}}>
                              <a href={`/t/ht-${hashtags[35]}`}>#{hashtags[35]}</a>, 
                              <a href={`/t/ht-${hashtags[36]}`}>#{hashtags[36]}</a>, 
                              <a href={`/t/ht-${hashtags[37]}`}>#{hashtags[37]}</a>, 
                              <a href={`/t/ht-${hashtags[38]}`}>#{hashtags[38]}</a>, 
                              <a href={`/t/ht-${hashtags[39]}`}>#{hashtags[39]}</a>
                          </div>;
                                  
      

      return (
          <div style={{"marginBottom":"20px", "textAlign":"center"}}>
              <div style={{"fontSize":"14px","margin":"20px 0px 20px"}}>
                  The following hashtags are currently trending:
              </div>
              { topRow }
              { secondRow }
              { thirdRow }
              { fourthRow }
              { fifthRow }
              { sixthRow }
              { seventhRow }
              { eighthRow }
              { ninthRow }
              { tenthRow }
          </div>
      )            
  }
}


export default TrendingHashtags;