require("../styles/_EditPost.scss");

import FollowHandler from "./FollowHandler.jsx";


class OauthFriendFinder extends React.Component {

  

    constructor(props) {
        super(props);
        this.state = {
            showFriends:             false,
            friendsJSON:              [],
            friendsCursor:            '0',
            friendsMore:              null,
            viewFriendsText:          null,
            errorMsg:               '',
        };
        
        this.busyFetching      = false;
        this.showFriends       = this.showFriends.bind(this);
        this.goToProfile       = this.goToProfile.bind(this);
        this.hideFriends       = this.hideFriends.bind(this);
    }

  
  showFriends() {
      if (this.busyFetching) {
          return;
      }
      this.busyFetching = true;
      
      this.setState( {
          showFriends:      true          
      });
    
      if (this.state.friendsMore) {
          this.setState( {
              viewFriendsText:      "loading..."          
          });
      }
    
      //var formData = new FormData();
      //    formData.append("postID",       this.props.postID );
      
      var url =   "/get_user_friends_json/" + this.props.globalProps.currentUser.userID + "/" + this.props.network + "?cursor=" + this.state.friendsCursor+ "&num_per_page=2";
    
      var ajaxReq =             new XMLHttpRequest();
          ajaxReq.addEventListener(
              "load", 
              function() {
                  var responseJSON =          JSON.parse(ajaxReq.responseText);
                  console.log(responseJSON)
    
                  if ( responseJSON["msg"] == "success" ) { 
                      var newFriends =            responseJSON["json"]["friendsJSON"];

                      var updatedFriends = this.state.friendsJSON.concat(newFriends);

                      this.setState( {
                          //commentsJSON:         newCommentsJSON,
                          friendsJSON:            updatedFriends, 
                          friendsMore:            responseJSON["more"],
                          friendsCursor:          responseJSON["nextCursor"]     
                      });
                      
                      if (this.state.friendsJSON.length == 0) {
                          this.setState( {
                              showFriends:         false    
                          });
                      }
                    
                      // whenever add a like, add it to visible.
                      // but after fetch of last like (more=false), only show fetched likes
                      if (responseJSON["more"]) {
                          this.setState({
                              viewFriendsText:       "view more friends"
                          });
                      } else {
                          this.setState({
                              friendsJSON:           updatedFriends
                          });
                      }
            
                      this.busyFetching = false;
                      return;

                  } else {
                      // give user msg
                      this.setState( {
                          errorMsg: responseJSON["msg"]
                      });
                  }
              }.bind(this), 
              false
          );
          ajaxReq.addEventListener(
              "error", 
              function(xhr, status, err) {
                  console.error( url, status, err.toString());
              }.bind(this), 
              false
          );
          ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
          ajaxReq.send();  
  }
  
  goToProfile(username) {
      window.location.href = "/profile/" + username;
  }
  
  hideFriends() {
      this.setState( 
          {
              showFriends:      false
          }
      );
  }
  
    render() {
    //var currentUser = this.props.globalProps.currentUser;
    //console.log(this.state.friendsJSON)
    var friendNodes =      this.state.friendsJSON.map(
        
        function (singleFriendJSON) {
          //if (singleFriendJSON.userID == this.props.globalProps.currentUser.userID) {
          //    return
              //} else {
          //var FollowsMeText = (singleFriendJSON.userFollowsMe)
          //                      ? "(@" + singleFriendJSON.username + " follows me.)"
          //                    : ""
          //;
           return (
              <div className="likes_feed" key={`likeCntr_${ singleFriendJSON.userID }`}>
                  <table style={{"width": "440px"}}>
                      <tr>
                          <td onClick={function() {this.goToProfile(singleFriendJSON.username) }.bind(this)} >
                              <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ singleFriendJSON.userID}_av_lg`} 
                                      className="profile_avatar_url"
                                      style={{"width":"44px", "height":"44px"}}
                                      alt="{{ singleFriendJSON.username }} avatar" 
                              />
                          </td>
                          <td style = {{"width":"300px", "paddingLeft":"20px"}}>
                              <div>
                                  <a href={`/profile/${singleFriendJSON.username}`}>@{ singleFriendJSON.username }</a>
                              </div>
                              <div>
                                  <a href={`/profile/${singleFriendJSON.username}`}>{ singleFriendJSON.name }</a>
                              </div>
                              <div>
                                  { singleFriendJSON.profileMsg }
                              </div>
                          </td>
                          <td>
                              <div style={{"marginRight":"20px"}}>
                                  <FollowHandler    profileJSON =     { singleFriendJSON }
                                                    currentUser =     { this.props.globalProps.currentUser } 
                                                    globalProps =     { this.props.globalProps }
                                  />
                              </div>
                          </td>
                      </tr>
                  </table>
              </div>
          );
       //}
    }.bind(this));
    
    
    var showFriendsDOM = (this.state.errorMsg.length == 0)
                          ? <div  className="button_square tappable"
                                  onClick = { this.showFriends }
                            >
                                { `Some of your ${ capitalizeFirst( this.props.network)} friends are on Freezify.  Click to find them.` }
                            </div>
                          : <div>
                                { this.state.errorMsg }
                            </div>;
                            
    if (this.state.showFriends) {
        showFriendsDOM = <div className="page_cover">
                            <div style={{"border":"1px solid goldenrod","backgroundColor":"black"}}>
                                <div style={{"textAlign":"center","fontSize":"11px", "width":"440px"}}>
                                    Follow these friends on Freezify:
                                    <div    className = "deleteLikesDiv tappable"
                                            onClick = { this.hideFriends }
                                    >
                                        x
                                    </div>
                                </div>  
                                { friendNodes }
                                { this.state.viewFriendsText }
                            </div>
                        </div>
    }

  return (
      <div className="flex">
          { showFriendsDOM }
      </div>
  );
}
}


export default OauthFriendFinder;
