import "../styles/_Comment.scss";
import CommentForm from "./CommentForm.jsx";
//import LikesHandler from "./LikesHandler.jsx";
import LoginForm from "./LoginForm.jsx";


import { CSSTransition } from 'react-transition-group';

class CommentDisplay extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showDelete:         "allowDelete",
            showForm:           "none",
            editingComment:     false
        };
        this.checkLogin             = this.checkLogin.bind(this);
        this.onCommentClick         = this.onCommentClick.bind(this);
        this.hideForm               = this.hideForm.bind(this);
        this.resetDeleteDialog      = this.resetDeleteDialog.bind(this);
        this.onDeleteClick          = this.onDeleteClick.bind(this);
        this.onDeleteCommentClick   = this.onDeleteCommentClick.bind(this);
        this.onEditClick            = this.onEditClick.bind(this);
    }

    
    checkLogin(msg) {

        if (this.props.globalProps.currentUser.username == "anonymous"){
            this.setState(
                {
                    "showForm":            "login",
                    "loginMsg":            msg
                }
            );
            return false;
        } else {
            return true;
        }
    }
    
    onCommentClick() {
        if ( this.checkLogin("You must be logged in to Comment") ) {
            this.setState(
                {
                    "showForm":            "comment"
                
                }
            );
        }
    }
    
    hideForm() {
        this.setState(
            {
                "showForm":                 "none",
                "editingComment":           false
                
            }
        );
    }
       
    resetDeleteDialog() {
        this.setState(
            {
                "showDelete":              "allowDelete" 
            }
        );
    }
    
    onDeleteClick() {
        this.setState(
            {
                "showDelete":              "confirmDialog"
            }
        );
    }
    
    onDeleteCommentClick() {
        var deleteComment =     this.props.deleteComment;
        
        deleteComment(this.props.singleCommentJSON.commentID);
    }
    
    onEditClick() {
        this.setState(
            {
                "editingComment":           true,
                "showDelete":               "allowDelete"
            }
        );
    }

    render() {    
        
        var singleCommentJSON = this.props.singleCommentJSON;
        var addComment =        this.props.addComment;
        var commentKeyJoin =    singleCommentJSON.commentKeyJoin;
        var transitionKey =     "commentform-" + commentKeyJoin;
        var transitionKey2 =    "commentdelete-" + commentKeyJoin;
        
        var allowDelteDOM =     <div  className="deletePostDiv" />;
        
        if ( (singleCommentJSON.username == this.props.globalProps.currentUser.username) || this.props.globalProps.currentUser.https != undefined ) {
            allowDelteDOM =     <div  className="deletePostDiv tappable"
                                      onClick = { this.onDeleteClick }
                                      key={transitionKey2} >
                                      x
                                 </div>
        }
        
        var deleteChoices = {
            "allowDelete":      allowDelteDOM,
            "confirmDialog":    <div  className="deleteConfirm"
                                      onMouseLeave={ this.resetDeleteDialog }
                                >
                                    Are you sure you want to delete this comment?
                                    <div>
                                        <div  className="no_yes_edit _red" 
                                              onClick={ this.onDeleteCommentClick }
                                        > delete
                                        </div>            
                                        <div  className="no_yes_edit _green" 
                                              onClick={ this.onEditClick } 
                                        > edit
                                        </div>
                                        <div  className="no_yes_edit" 
                                              onClick={ this.resetDeleteDialog } 
                                        > cancel
                                        </div>
                                    </div>
                                </div>
        }
        
        var formChoices = {
            "none":             <div key={transitionKey}></div>,
            "comment":          <CommentForm 
                                    singleCommentJSON =     { singleCommentJSON }
                                    currentUser =           { this.props.globalProps.currentUser }
                                    globalProps =           { this.props.globalProps }
                                    hideForm =              { this.hideForm }
                                    addComment =            { addComment }
                                />,
            "login":            <LoginForm 
                                    msg =                   { this.state.loginMsg }
                                    globalProps =               { this.props.globalProps }
                                    hideForm =              { this.hideForm }
                                />
        }
        
        if (this.state.editingComment) {
            return (
                <CommentForm 
                    singleCommentJSON =     { singleCommentJSON }
                    currentUser =           { this.props.globalProps.currentUser }
                    globalProps =           { this.props.globalProps }
                    hideForm =              { this.hideForm }
                    addComment =            { addComment }
                    editComment =           { this.props.editComment }
                    editText =              { singleCommentJSON.commentText }
                    editCommentID =         { singleCommentJSON.commentID }
                />
            );
        }
        
        return (
            <div>
                <div className="commentContainer">
                    <div>
                        {/*
                        <img src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ singleCommentJSON.commenterID }_av_sm`} 
                            alt="small avatar" 
                            className="avatar_sm" 
                        /> 
                        */} 
                        <span className="post_as">
                            &nbsp;<a href={ `/profile/${ singleCommentJSON.commenterUsername }` }>@{ singleCommentJSON.commenterUsername }</a>: &nbsp;
                            { singleCommentJSON.commentText }
                            {/*&nbsp;@{ singleCommentJSON.commenterUsername } */}
                        </span>
                    </div>
                    
                    <div className="upperRightLinks">
                        {/*
                        <LikesHandler 
                             likes =                { singleCommentJSON.likes } 
                             commentKeyJoin =       { singleCommentJSON.commentKeyJoin }
                             postKeyJoin =          { singleCommentJSON.postKeyJoin }
                             globalProps =              { this.props.globalProps }
                             checkLogin =           { this.checkLogin }
                        />
                        <div className="l_c_button_off" onClick={ this.onCommentClick }>
                            comment
                        </div>
                        */}
                        <CSSTransition timeout={200} classNames="easeTransition">
                             { deleteChoices[this.state.showDelete] }
                        </CSSTransition>
                    </div>
                    {/* 
                    <div className="comments">
                        { singleCommentJSON.commentText }
                    </div> 
                    */}     
                </div>
                <CSSTransition timeout={200} classNames="easeTransition">
                    { formChoices[this.state.showForm] }
                </CSSTransition>
            </div>
        );
    }
}


export default CommentDisplay;
