import "../styles/_Common.scss";
import "../styles/_Login.scss";
import ReactDOM             from 'react-dom';

class RegisterForm extends React.Component {
    
    constructor(props) {
        super(props);
          
        this.state = {
            imageErrorText:       '',
            generalErrorText:     '',
            usernameErrorText:    '',
            usernameValidText:    '',
            nameErrorText:        '',
            passwordErrorText:    '',
            passwordErrorText2:   '',
            passwordErrorText3:   '',
            emailErrorText:       ''
        };

        this.handleSubmit                           = this.handleSubmit.bind(this);
        this.checkUsernameAvailabilityPreRequest    = this.checkUsernameAvailabilityPreRequest.bind(this);
        this.checkUsernameAvailability              = this.checkUsernameAvailability.bind(this);
    }
    

    checkUsernameAvailabilityPreRequest() {
        // this function is duplicated in Activate.jsx.  Any changes here should also be done there.
        var reservedUsernames = ['anonymous', 'admin', 'info', 'guest', 'private', 'public', 'trending', 'recent', 'most', 'following', 'followers', 'follow', 'followed', 'freezify', 'like', 'liked', 'freezify', 'freezy', 'freeze', 'frozen', 'froze', 'freezing', 'slomo', 'slo_mo', 'selfie', 'selfy', 'slomotion', 'slo_motion', 'nflfilms', 'gopro', 'go_pro', 'redbull', 'red_bull', 'twitter', 'instagram', 'facebook', '3dselfy', '3dselfie', '3d_selfie', 'stopaction', 'stop_action', "kimkardashian", "kanye", "drake", "jayz", "beyonce", "arianagrande", "selenagomez", "justinbieber", "taylorswift", "kendalljenner", "mileycyrus", "nickiminaj", "katyperry", "kourtneykardash", "kourtneykardashian", "cristiano", "natgeo", "nike", "kevinhart", "kevinhart4real", "harrystyles", "victoriassecret", "messi", "leomessi", "lionelmessi", "caradelevingne", "kingjames", 'jason', 'sacca', '0360', '360', '360video', "0_360", 'pano', 'panos', '360s', 'porn', 'burst', 'xxxx', 'xxxxx' ];
        
        this.setState(
            {
                                    usernameErrorText:      '',
                                    usernameValidText:      '' 
            }
        )
        var username =              ReactDOM.findDOMNode(this.refs.username).value.trim();
    
        if (username.replace(/\s/g, '') == '') {
            this.setState(
                {
                                    usernameErrorText:      "Username required."
                }
            )
            return "no";
        }
    
        if (username.indexOf(' ') != -1) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, spaces not allowed in username."
                }
            )
            return "no";
        }
    
        if ( (username.length <= 3 ) || ( reservedUsernames.indexOf( username.toLowerCase() ) != -1 ) ) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, username " + username + " already in use."
                }
            )
            return "no";
        }
    
        if (username.length >= 20 ) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, username must be 30 characters or less."
                }
            )
            return "no";
        }
    
        var re = /^([a-zA-Z0-9_]*)$/;
        if (!re.test(username)) {
            this.setState(
                {
                                    usernameErrorText:      "Invalid username format.  Only letters, numbers, and underscores allowed."
                }
            )
            return "no";
        }
        return "OK";
    }
    
    checkUsernameAvailability() {
        // this function is duplicated in Activate.jsx.  Any changes here should also be done there.
        // first do a local check, to save request
        doRequest = ( this.checkUsernameAvailabilityPreRequest() == "OK" );
        
        if (doRequest) {
            var username =          ReactDOM.findDOMNode(this.refs.username).value.trim();
            var url =               "/check_username_availability/" + username;
            
            var ajaxReq =           new XMLHttpRequest();
                //ajaxReq.addEventListener(
                //      "progress", this.updateProgress or serve some wait msg, false
                //);
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
            
                        if ( responseJSON["msg"] == "Username valid!" ) {
                            this.setState(
                                {
                                                    usernameValidText:     responseJSON["msg"]
                                }
                            )
                            return;

                        } else {
                            this.setState(
                                {
                                                    usernameErrorText:     responseJSON["msg"]
                                }
                            )
                            return;
                        }
       
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error(url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.send(); 
        }
        return;
    }
    
    handleSubmit(e) {
        e.preventDefault();
        
        var csrf_token =                ReactDOM.findDOMNode(this.refs.csrf_token).value;
        var image =                     ReactDOM.findDOMNode(this.refs.image).files[0];
        var hide_name =                 ReactDOM.findDOMNode(this.refs.hide_name).checked;
        var username =                  ReactDOM.findDOMNode(this.refs.username).value.trim();
        var fullName =                  ReactDOM.findDOMNode(this.refs.fullName).value.trim();
        var password1 =                 ReactDOM.findDOMNode(this.refs.password1).value;
        var password2 =                 ReactDOM.findDOMNode(this.refs.password2).value;
        var slogan =                    ReactDOM.findDOMNode(this.refs.slogan).value.substring(0, 500);
        var hide_email =                 ReactDOM.findDOMNode(this.refs.hide_email).checked;
        var email =                     ReactDOM.findDOMNode(this.refs.email).value.trim();

        this.setState(
            {
                                        imageErrorText:         '',
                                        generalErrorText:       '',
                                        nameErrorText:          '',
                                        passwordErrorText:      '',
                                        passwordErrorText2:     '',
                                        passwordErrorText3:     '',
                                        emailErrorText:         '',
                                        websiteUrlErrorText:    '',
                                        googleUrlErrorText:     '',
                                        facebookUrlErrorText:   '',
                                        twitterUrlErrorText:    ''
            }
        )
        
        // first do a local check, to save request
        allowSubmit = ( this.checkUsernameAvailabilityPreRequest() == "OK" );
        
        if (fullName.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    nameErrorText:      "Name required. Use the 'hide' feature if you do not want it made public."
                }
            )
        }
        
        if ((password1.replace(/\s/g, '') == '') || (password2.replace(/\s/g, '') == '')) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText:      "Both password fields required."
                }
            )
        }
        
        if ((password1.indexOf(' ') != -1) || (password2.indexOf(' ') != -1)) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText2:      "Spaces not allowed in password."
                }
            )
        }
        
        if (password1 != password2) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText3:      "Passwords entered do not match."
                }
            )
        }
        
        if (email.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    emailErrorText:      "Email address required."
                }
            )
        } else {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email)) {
                allowSubmit = false;
                this.setState(
                    {
                                    emailErrorText:      "Invalid email format."
                    }
                )
            }
        }

        
        if (image != undefined) {
            /*  image.type only checks the file extension, not the actual mimetype.  Lame.
            if ( ['jpg', 'jpeg', 'png', 'gif'].indexOf(image.name.split('.')[image.name.split('.').length - 1].toLowerCase()) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    imageErrorText:  "Only images are allowed. Must be jpg, png, or gif.  " + " "
                    }
                )
            }
            */
            if ( ['image/png', 'image/jpeg', 'image/gif'].indexOf(image.type) == -1 ) {
                allowSubmit = false;
                this.setState(
                    {
                                    imageErrorText:  "Only images are allowed." + " " + "Must be jpg, png, or gif.  " + " " + "Image has invalid mimetype: " + image.type + "."
                    }
                )
            }
        } 
        
        if (allowSubmit) {
            ReactDOM.findDOMNode(this.refs.registerButton).value = 'Processing...';
            
            var formData = new FormData();
                formData.append("csrf_token",               csrf_token);
                formData.append("image",                    image);
                formData.append("hide_name",                 hide_name);
                formData.append("name",                     fullName);
                formData.append("username",                 username);
                formData.append("slogan",                   slogan);
                formData.append("hide_email",                hide_email);
                formData.append("tempEmail",                email);
                formData.append("password1",                password1);
                
            var ajaxReq =             new XMLHttpRequest();
                //ajaxReq.addEventListener(
                //      "progress", this.updateProgress or serve some wait msg, false
                //);
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        console.log(responseJSON)
                        
                        if ( responseJSON["msg"] == "success" ) {
                            location.href = '/registration_complete'
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                "generalErrorText": responseJSON["msg"]
                            });
                            ReactDOM.findDOMNode(this.refs.registerButton).value = 'Register';
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/register_process/", status, err.toString());
                        ReactDOM.findDOMNode(this.refs.registerButton).value = 'Register';
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/register_process/", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.send(formData);
        }
        return;
        
      }

    render() {
        //var prop =                  this.props.globalProps.currentUser;
        var state =                 this.state;
        
        var usernameValidDOM =      null;
        
        var imageErrorDOM =         null;
        var generalErrorDOM =       null;
        var usernameErrorDOM =      null;
        var usernameValidDOM =      null;
        var nameErrorDOM =          null;
        var passwordErrorDOM =      null;
        var passwordErrorDOM2 =     null;
        var passwordErrorDOM3 =     null;
        var emailErrorDOM =         null;
        var websiteUrlErrorDOM =    null;
        var googleUrlErrorDOM =     null;
        var facebookUrlErrorDOM =   null;
        var twitterUrlErrorDOM =    null;
        
        if (state.imageErrorText) {
            imageErrorDOM =        <div className="errors" style={{"margin": "5px 0px 18px 0px","width": "100%"}}>
                                        { state.imageErrorText }
                                    </div>;
        }
        
        if (state.usernameValidText) {
            usernameValidDOM =      <span className="valid" style={{"margin": "5px 0px 18px 0px","width": "100%"}}>
                                        { state.usernameValidText }
                                    </span>;
        }
        
        if (state.generalErrorText) {
            generalErrorDOM =       <div className="errors" style={{"margin": "20px 0px 18px 0px","width": "100%"}}>
                                        { state.generalErrorText }
                                    </div>;
        }
        if (state.usernameErrorText) {
            usernameErrorDOM =      <div className="errors">{ state.usernameErrorText }</div>;
        }
        if (state.nameErrorText) {
            nameErrorDOM =          <div className="errors">{ state.nameErrorText }</div>;
        }
        if (state.passwordErrorText) {
            passwordErrorDOM =      <div className="errors">{ state.passwordErrorText }</div>;
        }
        if (state.passwordErrorText2) {
            passwordErrorDOM2 =      <div className="errors">{ state.passwordErrorText2 }</div>;
        }
        if (state.passwordErrorText3) {
            passwordErrorDOM3 =      <div className="errors">{ state.passwordErrorText3 }</div>;
        }
        if (state.emailErrorText) {
            emailErrorDOM =         <div className="errors">{ state.emailErrorText }</div>;
        }
        if (state.websiteUrlErrorText) {
            websiteUrlErrorDOM =    <div className="errors">{ state.websiteUrlErrorText }</div>;
        }
        if (state.googleUrlErrorText) {
            googleUrlErrorDOM =     <div className="errors">{ state.googleUrlErrorText }</div>;
        }
        if (state.facebookUrlErrorText) {
            facebookUrlErrorDOM =   <div className="errors">{ state.facebookUrlErrorText }</div>;
        }
        if (state.twitterUrlErrorText) {
            twitterUrlErrorDOM =    <div className="errors">{ state.twitterUrlErrorText }</div>;
        }

        return  (
            <div className="choose_username_div" style={{"paddingTop":"20px"}}>
            	<form method="post" encType="multipart/form-data">
            		<input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />


                    <div className="profile_explain">(Note: Fields marked with an asterisk(*) are mandatory.  If you wish to hide a field from public view, check its "hide" option below.)</div>

                    <div className="profile_avatar">
                        <img src={`${this.props.globalProps.GCS_VIDEO_PATH}a/generic_av_lg`} className="profile_avatar_url" alt="freezify avatar" />

                        <label>Profile image</label><br />
                        <input ref="image" type="file" accept="image/gif, image/jpeg, image/png" /><br />
                    </div>
            
                    { imageErrorDOM }
                    { generalErrorDOM }
                            
                    <div className="profile_field">
                        <div className="inline_block"><label>Username *:</label> { usernameValidDOM }</div>
                        <div>
                            { usernameErrorDOM }
                            <input ref="username" className="form_input" type="text"  style={{"width":"216px"}}  />
                            <div    ref="submitButton"
                                    className="l_c_button_off check_availability_button" 
                                    onClick={this.checkUsernameAvailability}
                            > check availability
                            </div>
                        </div>
                    </div>
                    
                    <div className="profile_field">
                        <div className="inline_block"><label>Name *:</label> </div> 
                        <div className="profile_hide">
                            <input ref="hide_name" type="checkbox" /> <label className="text_small">hide</label>
                        </div>
                        <div>
                            { nameErrorDOM }
                            <input ref="fullName" className="form_input" type="text" />
                        </div>
                    </div>

                    <div className="profile_field">
                        <div className="inline_block"><label>Email *:</label> </div>
                        <div className="profile_hide">
                            <input ref="hide_email" type="checkbox" defaultChecked={true} /> <label className="text_small">hide</label>
                        </div>
                        <div className="width_400">
                            { emailErrorDOM }
                            <input ref="email" className="form_input" type="email" />     
                        </div>
                    </div>

                    <div className="profile_field">
                        <div className="profile_field_short">
                            <div>Password*</div>
                            <div><input ref="password1" className="form_input_short" type="password" /></div> 
                        </div>
        
                        <div className="profile_field_short" style={{"marginLeft":"16px"}}>
                            <div>Re-enter password*</div>
                            <div><input ref="password2" className="form_input_short" type="password" /></div> 
                        </div>
                    </div>
                            { passwordErrorDOM }
                            { passwordErrorDOM2 }
                            { passwordErrorDOM3 }
                            
                    <hr style={{"margin":"25px 0px 15px 0px"}}/>
                    
                    <div className="profile_field">
                        <div className="inline_block"><label>Slogan / bio / other info:</label> </div>
                        <div>
                            <textarea ref="slogan" className="slogan" />
                        </div>
                    </div>
                            

                    <br /><br />
                    <input ref="registerButton" type="button" defaultValue="register" className="form_button" onClick={this.handleSubmit} />

                </form>
            </div>
        );
    }
}


export default RegisterForm;
