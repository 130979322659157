require("../styles/_Common.scss");
require("../styles/_PostContainer.scss");



class PostHeader extends React.Component {

  render() {
      return (
          <div className="post_as">
             &nbsp;<a href={ `/profile/${ this.props.singlePostJSON.username }` }>@{ this.props.singlePostJSON.username }</a>:
             {/*&nbsp; @{ singlePostJSON.username } */}
          </div>
      );
  }
}


export default PostHeader;
