                    //require("../styles/_PostContainer.scss");
import Header               from "./Header.jsx";
import ShowPosts            from "./ShowPosts.jsx";
import RegisterForm         from "./RegisterForm.jsx";
import Profile              from "./Profile.jsx";
import ProfileForm          from "./ProfileForm.jsx";
import Message              from "./Message.jsx";
import Privacy              from "./Privacy.jsx";
import Terms                from "./Terms.jsx";
import Activate             from "./Activate.jsx";
import ResetPassword        from "./ResetPassword.jsx";
import Follows              from "./Follows.jsx";
import ChangeAvatar         from "./ChangeAvatar.jsx";
import Test                 from "./Test.jsx";
import TrendingHashtags     from "./TrendingHashtags.jsx";
import PreviewCreatorForm   from "./PreviewCreatorForm.jsx";
import Preview              from "./Preview.jsx";
import PressRelease         from "./PressRelease.jsx";
import FiveSpot             from "./FiveSpot.jsx";
import newHome              from "./newHome.jsx";

// to get rid of 'Set' error in IE 10.  adds 11k to size
import 'core-js/es/map';
import 'core-js/es/set';

import ReactDOM             from 'react-dom';

// uses HTML5 history API
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'



class MainContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentUser:        userJSON
        };
        
        this.handleLogin  = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogin(currentUserJSON) {
        this.setState( {
            currentUser:        currentUserJSON         
        });
    }
    
    handleLogout() {
        this.setState( {
            currentUser:    {
                username            : "anonymous",
                shortName           : "anonymous",  
                fullName            : "anonymous",
                loggedInto          : "",
                https               : "on",
                suggestedFollows    : [],
                userID              : 999
            }         
        });
    }
    
    
    render() {  
        var globalProps = {
            GCS_VIDEO_PATH  : GCS_VIDEO_PATH,
            csrfToken       : csrfToken,
            handleLogin     : this.handleLogin,
            handleLogout    : this.handleLogout,
            currentUser     : this.state.currentUser
        };

    return (
        
        /*
          <div>
              <Header         {...this.state}  
                              handleLogout =    { this.handleLogout }
                              childProps =      { childProps } 
                              globalProps =     { globalProps }
              />
              <RouteHandler   {...this.state}  
                              childProps =      { childProps }  
                              globalProps =     { globalProps } 
              />
          </div>
        */
        <div>
            <Header {...this.state} childProps = { childProps } globalProps = { globalProps } />
        
            <BrowserRouter>
                <Switch>

                    {/* main page */}
                    <Route exact path="/"                       render={(props) => <ShowPosts {...this.state} {...props} childProps = { childProps }  globalProps = { globalProps } />} />

                    <Route path="/test"                         render={(props) => <Test      {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/t/:searchFor"                 render={(props) => <ShowPosts {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/u/:searchFor"                 render={(props) => <ShowPosts {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/p/:searchFor"                 render={(props) => <ShowPosts {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/i/:searchFor"                 render={(props) => <ShowPosts {...props} childProps = { childProps }  globalProps = { globalProps } />} /> 
                    <Route path="/id/:searchFor"                render={(props) => <ShowPosts {...props} childProps = { childProps }  globalProps = { globalProps } />} />
	
                    <Route path="/new_home/"                    render={(props) => <NewHome   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
	
    
                    {/* auth urls */}
                    <Route path="/register/"                    render={(props) => <RegisterForm   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/activate/:activationKey/"     render={(props) => <Activate   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/profile/:username"            render={(props) => <Profile   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/profile/:username/edit"       render={(props) => <ProfileForm   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/followers/most/"              render={(props) => <Follows   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/following/:username"          render={(props) => <Follows   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/followers/:username"          render={(props) => <Follows   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/n/:searchFor"                 render={(props) => <Follows   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/reset_password"               render={(props) => <ResetPassword   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/reset_password/:token/"       render={(props) => <ResetPassword   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
    
                    {/* privacy, terms, about us, etc */}
                    <Route path="/privacy"                      render={(props) => <Privacy   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/terms"                        render={(props) => <Terms   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/terms/"                       render={(props) => <Terms   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/preview_creator"              render={(props) => <PreviewCreatorForm   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/preview/:path"                render={(props) => <Preview   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                	<Route path="/press_release"         	    render={(props) => <Presselease   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/5spot"                   	    render={(props) => <FiveSpot   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/5spot/"                       render={(props) => <FiveSpot   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
    
                    {/* simple messages */}
                    <Route path="/trending-hashtags"            render={(props) => <TrendingHashtags   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/registration_complete"        render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/not_logged_in"                render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/login_failed"                 render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/logged_out"                   render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/thank_you"                    render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/not_found"                    render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/reset_password_emailed"       render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/reset_password_complete"      render={(props) => <Message   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                    <Route path="/change_avatar/"               render={(props) => <ChangeAvatar   {...props} childProps = { childProps }  globalProps = { globalProps } />} />

                    {/* default */}
                    <Route path='*'                             render={(props) => <ShowPosts   {...props} childProps = { childProps }  globalProps = { globalProps } />} />
                </Switch>
            </BrowserRouter>
        </div>
    )
  }
}


ReactDOM.render((
    <MainContainer />
), document.getElementById('content'));


export default MainContainer;
// closure-compiler needs  format like these:

//module.exports.MainContainer = MainContainer;
//export default {
//    MainContainer   : MainContainer
    //}
