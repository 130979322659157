

class Test extends React.Component {
      
      
    render() {
        
      console.log("----+++++---")
      console.log(this.props)
      console.log(this.state)
      console.log("---++++++++----")
 
      return (
          <div className="flex">
              765432
          </div>
      );
    }
  }


export default Test;
