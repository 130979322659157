                    	require("../styles/_Common.scss");
                    	require("../styles/_PostContainer.scss");
                   	 	require("../styles/_VideoContainer.scss");
import VideoContainer  from "./VideoContainer.jsx";



class PressRelease extends React.Component { 
    
    constructor(props) {
        super(props);
                            
        this.state = {};
    }

	
	
    render() {
        					
        return  (    
            <div style={{"width":"550px"}}>

				<div className="previewBlock" style={{"width":"100%","fontSize":"13px","fontStyle":"italic","top":"30px","textAlign":"left"}}>
					Hold for August 20, 2015
				</div>

				<div className="previewBlock" style={{"width":"100%","fontSize":"13px","margin":"0px","textAlign":"right"}}>
					Morgan Simpson
				</div>

				<div className="previewBlock" style={{"width":"100%","fontSize":"13px","margin":"0px","textAlign":"right"}}>
					freezify
				</div>

				<div className="previewBlock" style={{"width":"100%","fontSize":"13px","margin":"0px","textAlign":"right"}}>
					<a href="mailto:morgan@freezify.com">morgan@freezify.com</a>
				</div>

				<div className="previewBlock" style={{"width":"100%","fontSize":"13px","margin":"0px","textAlign":"right"}}>
					<a href="https://www.freezify.com">https://www.freezify.com</a>
				</div>
 
				<div style={{"width":"100%","fontSize":"20px","fontWeight":"bold","margin":"25px 10px 0px 10px","textAlign":"center"}}>
					Slow motion video, time-lapses, and 3D selfies? 
                </div>
				<div style={{"width":"100%","fontSize":"20px","fontWeight":"bold","margin":"5px 10px 15px 10px","textAlign":"center"}}>
					There's an app for that: freezify.
                </div>
    
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					Reno, NV - August, 20 2015 - Tilt-to-view video, and interactive photography: that's the premise of "freezify", a newly released iPhone app.
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					Freezify lets users upload any video on their phone as an "interactive photograph". These interactive photographs are up to 120 frames, and those 120 frames can be played back as fast or slow as you want. You are in control.
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					Using the iPhone's gyroscope, freezify lets you move through a clip by rotating your wrist. This means you can go backward or forward, fast or slow, instantly. "Essentially, it's video without time," says freezify founder, Morgan Simpson. "It's really fun for slow motion and time-lapses, but the rotate gesture allows you to do some interesting spatial stuff, too - like 3D selfies and panoramas." 
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					So what's a 3D selfie? Basically, you record a video, starting on the right side of your face, and pan the camera to the left side of your face. In freezify, when the viewer rotates his or her wrist, it's like spinning around the person - in 3D. Of course, the background moves too, so you can see a lot more of the Grand Canyon, or wherever you go to take selfies.
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					The panorama feature is similar. You record a video and pan the camera from left-to-right. When you play it back in freezify, you can actually hold your phone out and spin around as if you are there. Your phone acts as a viewport.
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					The spatial features are interesting, if novel, but where the app really shines is in slow motion playback. Have you ever seen a clip (maybe a great catch, or a silly cat) that you just wanted to play back, over and over? With freezify you can. And you can turn slow motion into sloooowwww motion. Being able to move backwards through time, you can see some wild stuff. Like watching a broken glass fix itself. Or watching a sandwich get ... un-eaten? 
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					When asked about his favorite content, Simpson says, "I really like the nature and sports footage. Watching skateboarding tricks in slow motion is so cool, and I don't think there is a better platform to do that than freezify." 
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					All of the content you create on freezify is shared with your followers in the app, just like you are used to on Twitter and Instagram.  
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					Freezify also has built in a feature that makes every video VR-ready. That means, if you want to create your own Virtual Reality content, all you have to do is upload a video to freezify. To enter VR mode, you just turn your phone into landscape mode, and swipe up. Then, place your phone in your Cardboard, and you control the footage by simply looking left and right. The video becomes immersive. And like the old View-Master, you just press a button to go to the next video.  
				</div>
			
				<div style={{"width":"100%","fontSize":"13px","padding":"10px","textAlign":"left"}}>
					That's freezify, and it is available in the App Store today.  
				</div>
			
				<div style={{"width":"100%","text-align":"center","margin":"25px"}}>
					# # #
				</div>

				<div style={{"width":"100%","text-align":"center","margin":"25px"}}>
					View press release as PDF here:&nbsp; <a style={{"textDecoration":"underline"}} href="https://www.freezify.com/static/pdf/press_release_8_20_2015.pdf">Freezify Press Release (PDF)</a>
				</div>

				<div style={{"width":"100%","fontSize":"13px","textAlign":"left"}}>
					Press contact: Morgan Simpson, Founder, <a href="mailto:morgan@freezify.com">morgan@freezify.com</a>
				</div>

				<div style={{"width":"100%","fontSize":"13px","textAlign":"left"}}>
					To get early access on the freezify beta app, email Morgan.
				</div>
			
            </div>
        );
    }
}



export default PressRelease;
