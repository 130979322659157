require("../styles/_Common.scss");
require("../styles/_Privacy_TnC.scss");

class FiveSpot extends React.Component { 
    
  render() {

      return (
          <div style={{"width":"750px","margin":"30px","backgroundColor":"black","color":"#aaa","padding":"40px"}}>

              <div className="div_20 center">
              <span className="bold">          
	              5 Spot Friday
              </span>
              </div>
          
              <div className="div_20">
              <span className="bold">
	              On 5 Spot Friday, the best post on freezify gets 5 bucks. So get out there, post something awesome and share it on Facebook, Instagram and/or Twitter!!
              </span>
              </div>
		  
              <div className="div_20">
              <span className="bold">
			  Official Rules:
              </span>
              </div>

              <div>
              <span>
			  No purchase necessary. Must be at least 18 years of age and a legal resident of the U.S. to win.
			  </span>
              </div>
              <div className="div_20">
              <span>
		  	  Freezify Terms & Conditions and Privacy Policy apply.
              </span>
              </div>

              <div className="div_20">
              <span>
			  To enter, post something awesome to freezify on Friday October 2 with the hashtag “#5spot”, then share your post to Facebook, Instagram and/or Twitter. 
              </span>
              </div>
              <div>
              <span className="div_20">
			  Entries must be received between 12:00 a.m. and 11:59 p.m. PST on 10/2/2015.			  
              </span>
              </div>

              <div className="div_20">
              <span className="bold">
			  Prize:
              </span>
              </div>
              <div>
              <span>
			  A crisp Five Dollar Bill.
			  </span>
              </div>
          		  	
              <div className="div_20">
              <span className="bold">
			  Winner Selection:
              </span>
              </div>
              <div>
              <span>
			  At least one winner will be selected by our judge. Selection will be based on the post's quality, creativity, social reach.
			  </span>
              </div>
			  
              <div className="div_20">
              <span>
			  By entering the contest, you agree to release freezify, or its agents, from any claim, loss, liability, damage or injury of any kind arising from your participation in the contest or from your receipt or use of the contest prize.
			  </span>
              </div>
			  
              <div className="div_20">
              <span>
			  By entering this contest, you grant freezify permission, without further compensation from freezify, or its agents, to use your post for future promotional purposes, including, but not limited to, using your post on freezify's social media channels.
			  </span>
              </div>
			
              <div className="div_20 center">
              <span>
              &copy; 2015-2017 Freezify
              </span>
              </div>


          </div>

      )            
  }
}


export default FiveSpot;
