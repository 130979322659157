import "../styles/_PostForms.scss";
import ReactDOM             from 'react-dom';

import { CSSTransition } from 'react-transition-group';

class SearchForms extends React.Component {
    
    constructor(props) {
        super(props);
          
        this.state = {
            postFinderText:         null,
            postFinderStyle:        { "display": "none" },
            userFinderText:         null,
            userFinderStyle:        { "display": "none" },
        };
        this.doSearchPosts          = this.doSearchPosts.bind(this);
        this.doSearchUsers          = this.doSearchUsers.bind(this);
        this.hidePostFinder         = this.hidePostFinder.bind(this);
        this.hideUserFinder         = this.hideUserFinder.bind(this);
        this.onUserSearchFocus      = this.onUserSearchFocus.bind(this);
        this.onPostSearchFocus      = this.onPostSearchFocus.bind(this);
        this.insertUser             = this.insertUser.bind(this);
        this.postSearchFieldInit    = this.postSearchFieldInit.bind(this);
        this.userSearchFieldInit    = this.userSearchFieldInit.bind(this);
        this.findUser               = this.findUser.bind(this);
    }

    //contextTypes: {
    //    router: React.PropTypes.func
    //},
    

    doSearchPosts(e) {
        e.preventDefault();
        var goto = ReactDOM.findDOMNode(this.refs.searchPostsInput).value;
        
        if (goto.trim().length > 0) {
            if (goto.startsWith("@")) {
                window.location.href = "/u/" + goto.substring(1);
            } else {
                window.location.href = "/t/" + encodeURIComponent(goto.replace('#', 'ht-'));
            }
        } else {
            if (ReactDOM.findDOMNode(this.refs.searchUsersInput).value.length > 0) {
                // oops, wrong form
                this.doSearchUsers(e);
            } else {
                // show normal posts.  Should this be trending or recent instead?
                window.location.href = "/";
            }
        }
        return;
    }
    
    doSearchUsers(e) {
        e.preventDefault();
        var goto = ReactDOM.findDOMNode(this.refs.searchUsersInput).value;
        if (goto.trim().length == 0) {
            window.location.href = "/followers/most/";
        } else {
            if (goto.startsWith("@")) {
                window.location.href = "/u/" + goto.substring(1);
            } else {
                window.location.href = "/n/" + encodeURIComponent(goto);
            }
        }
        return;
    }
    
    hidePostFinder() {
        this.setState(
            {
                postFinderStyle:    { "display": "none" }
            }
        );
    }
    
    hideUserFinder() {
        this.setState(
            {
                userFinderStyle:    { "display": "none" }
            }
        );
    }
    
    onUserSearchFocus() {
        this.hidePostFinder();
        this.userSearchFieldInit();
        ReactDOM.findDOMNode(this.refs.searchPostsInput).value = '';
    }
    
    onPostSearchFocus() {
        this.hideUserFinder();
        this.postSearchFieldInit();
        ReactDOM.findDOMNode(this.refs.searchUsersInput).value = '';
    }
    
    insertUser(username) {
        var thisTextDOM =           ReactDOM.findDOMNode(this.refs.searchPostsInput)
        var thisTextDOMValue =      thisTextDOM.value;
        var newText =               thisTextDOMValue.slice(0,thisTextDOMValue.lastIndexOf("@")+1) + username;
        thisTextDOM.value =         newText;
        this.setState(
            {
                                    postFinderText:     null,
                                    postFinderStyle:    { "display": "none" }
            }
        );
    }
    
    postSearchFieldInit() {
        var thisText =              ReactDOM.findDOMNode(this.refs.searchPostsInput).value;
        var followingDOM =          (this.props.currentUser.username == "anonymous")
                                        ? null
                                        : <div key={`search_following`}><a href="/">following</a></div>
        var recentSearches =        this.props.sortedRecentSearches;
        
        var recentSearchesDOM = recentSearches.map(
            function (search) {
                if (search.indexOf('@') == 0) {
                    var searchUrlEnc = search.substring(1);
                    return (
                        <div key={`search_${search}`}><a href={`/u/${searchUrlEnc}`}>{search}</a></div>
                    );
                } else {
                    var searchUrlEnc = encodeURIComponent(search.replace('#', 'ht-'));
                    return (
                        <div key={`search_${search}`}><a href={`/t/${searchUrlEnc}`}>{search}</a></div>
                    );
                }
            }
        );
        
        if (thisText.length == 0) {
            this.setState(
                {
                                postFinderStyle: { "display": "block", "margin":"0px" },
                                postFinderText: <div> 
                                                    <div key={`search_trending`}><a href="/p/trending">trending posts</a></div>
                                                    <div key={`search_recent`}><a href="/p/recent">recent posts</a></div>
                                                    <div key={`search_liked`}><a href="/p/most-liked">most liked posts</a></div>
                                                    <div key={`search_hashtags`}><a href="/trending-hashtags">trending hashtags</a></div>
                                                    { followingDOM }
                                                    { recentSearchesDOM }
                                                </div>
                }
            );
        } else {
            this.setState(
                {
                                postFinderStyle:    { "display": "none" }
                }
            );
        }
    }
    
    userSearchFieldInit() {
        var thisText =              ReactDOM.findDOMNode(this.refs.searchUsersInput).value;
        var myUsername =            this.props.currentUser.username;
        var myFollowsDOM =          (myUsername == "anonymous")
                                        ? null
                                        : <div>
                                              <div key={`search_my_followers`}><a href={`/followers/${ myUsername }`}>my followers</a></div>
                                              <div key={`search_am_following`}><a href={`/following/${ myUsername }`}>my follows</a></div>
                                          </div>
        
        /*
        var recentSearches =        this.props.childProps.sortedRecentSearches;
        
        var recentSearchesDOM = recentSearches.map(
            function (search) {
                if (search.indexOf('@') == 0) {
                    var searchUrlEnc = search.substring(1);
                    return (
                        <div key={`search_${search}`}><a href={`/u/${searchUrlEnc}`}>{search}</a></div>
                    );
                } else {
                    var searchUrlEnc = encodeURIComponent(search.replace('#', 'ht-'));
                    return (
                        <div key={`search_${search}`}><a href={`/t/${searchUrlEnc}`}>{search}</a></div>
                    );
                }
            }
        );
        */
        
        if (thisText.length == 0) {
            this.setState(
                {
                                userFinderStyle: { "display": "block", "margin":"0px" },
                                userFinderText: <div> 
                                                    <div key={`search_most_followers`}><a href="/followers/most/">most followers</a></div>
                                                    { myFollowsDOM }
                                                </div>
                }
            );
        } else {
            this.setState(
                {
                                userFinderStyle:    { "display": "none" }
                }
            );
        }
    }
    
    findUser() {
        var thisText =              ReactDOM.findDOMNode(this.refs.searchPostsInput).value;
        var words =                 thisText.split(" ");
        var lastWord =              words[words.length-1];
        var insertUser =            this.insertUser;
        //var userFinderDiv =         ReactDOM.findDOMNode(this.refs.userFinderDiv);
        var postFinderText =        '';
        var followingJSON =         this.props.currentUser.followingJSON;
        
        if (lastWord[0] == "@") {
            var postFinderText = followingJSON.map(
                function (member) {
                    if ( (member[0].indexOf(lastWord.slice(1)) == 0) || (lastWord.length == 1) ) {
                        var member0 = member[0];
                        var member1 = member[1];
                        return (
                            <div    className="tappable"
                                    onClick={ function(){ 
                                         insertUser( member0 ) 
                                        } 
                                    }
                            > 
                                    { member0 } { member1 }
                            </div>
                        );
                    }
                }
            );
            
            // remove empty items from mapped list
            postFinderText = postFinderText.filter(function(e){return e});
             
            if (postFinderText.length > 0) {
                this.setState(
                    {
                                    postFinderStyle: { "display": "block", "margin":"0px" },
                                    postFinderText: postFinderText
                    }
                );
            } else {
                this.setState(
                    {
                                    postFinderStyle:    { "display": "none" }
                    }
                );
            }
            
        } else {
            this.setState(
                {
                                    postFinderStyle:    { "display": "none" }
                }
            );
        }     
    }
    
  render() {
                            
    var searchPostsDOM =  <div>
                              <form   ref="searchPostsForm"
                                      onSubmit={this.doSearchPosts}
                              >
                                <input ref="searchPostsInput" 
                                      type="text"
                                      onFocus={ this.onPostSearchFocus }
                                      placeholder={`search for posts`}
                                      className="search_input"
                                /> 
                              </form>
                              <CSSTransition timeout={200} classNames="easeTransition">
                                  <div    ref="postFinderDiv" 
                                          key="pfdiv"
                                          className="userFinderDiv tappable"
                                          style = { this.state.postFinderStyle }
                                          onMouseLeave={ this.hidePostFinder }
                                  >
                                     { this.state.postFinderText }
                                  </div>
                              </CSSTransition>
               
                          </div>
                                 
    var searchUsersDOM =  <div>
                             <form   ref="searchUsersForm"
                                     onSubmit={this.doSearchUsers}
                             >
                                <input ref="searchUsersInput"
                                       type="text"
                                       placeholder={`search for users`}
                                       className="search_input"
                                       onFocus={ this.onUserSearchFocus }
                                /> 
{/*                                <img  src="/static/img/magnifier_13.png" 
                                      className="search_magnifier" 
                                      alt="search"
                                      onClick={ this.doSearchPosts } 
                                />
*/}
                              </form>
                              <CSSTransition timeout={200} classNames="easeTransition">
                                  <div    ref="userFinderDiv"
                                          key="ufdiv" 
                                          className="userFinderDiv tappable"
                                          style = { this.state.userFinderStyle }
                                          onMouseLeave={ this.hideUserFinder }
                                  >
                                     { this.state.userFinderText }
                                  </div>
                              </CSSTransition>
                          </div>
                                
      return (
          <div className="searchContainer">
              { searchPostsDOM }
              { searchUsersDOM }
          </div>
      )
  }
}


export default SearchForms;