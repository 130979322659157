import "../styles/_PostContainer.scss";
import PostContainer        from "./PostContainer.jsx";
import SearchForms          from "./SearchForms.jsx";
import OauthFriendFinder    from "./OauthFriendFinder.jsx";
//var PostForm =        require("../components/PostForm.jsx";
import ReactDOM             from 'react-dom';

class ShowPosts extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            postsCompleteJSON       : this.props.childProps.postsJSON, 
            postsVisibleJSON        : this.props.childProps.postsJSON.slice(0, 1),
            postsMore               : this.props.childProps.more,
            postsCursor             : this.props.childProps.nextCursor,
            postLoadMsg             : null,
            lastPostShown           : 1,
            followRequests          : this.props.childProps.followRequests,
            suggestedFollows        : this.props.childProps.suggestedFollows,
            followersCount          : 0,
            followingCount          : 0,
            orientationPermission   : false, 
        };

        this.busyFetching                   = false;
        this.getMorePosts                   = this.getMorePosts.bind(this);
        this.setVisiblePosts                = this.setVisiblePosts.bind(this);
        this.preloadImages                  = this.preloadImages.bind(this);
        this.preloadVideos                  = this.preloadVideos.bind(this);
        this.addPost                        = this.addPost.bind(this);
        this.editPost                       = this.editPost.bind(this);
        this.deletePost                     = this.deletePost.bind(this);
        this.approveFollowHandler           = this.approveFollowHandler.bind(this);
        this.denyFollowHandler              = this.denyFollowHandler.bind(this);
        this.approveFollowSuggestionHandler = this.approveFollowSuggestionHandler.bind(this);
        this.denyFollowSuggestionHandler    = this.denyFollowSuggestionHandler.bind(this);
        this.getOrientationPermission       = this.getOrientationPermission.bind(this);
    }
    
    getOrientationPermission() {
        if (isMobile) {
            if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                DeviceOrientationEvent.requestPermission()
                    .then(response => {
                        if (response == 'granted') {
                            this.setState(
                                {
                                    orientationPermission   : true
                                }
                            )
                        } else {
                            console.log("DeviceOrientationEvent.requestPermission() not granted");
                        }
                    })  // end requestPermission.then
                    .catch(err => console.error("DeviceOrientationEvent.requestPermission error: " + err));
            } else {
                this.setState(
                    {
                        orientationPermission   : true
                    }
                )
            }
        }
    }
    
    //contextTypes: {
    //    router: React.PropTypes.func
    //}

    getMorePosts() {
        if (this.busyFetching) {
            return;
        }
        this.busyFetching = true;
        
        if (this.state.postsMore) {
            this.setState( {
                postLoadMsg:      "loading..."          
            });
            
            // we default to /p/trending
            var subPath = window.location.pathname;
            if ((subPath == "/" || subPath == "") && (this.props.globalProps.currentUser.username == "anonymous")) {
                // send not logged-in user to trending
                subPath = "/p/trending";
            }
            
            
            var url =   "/get_filtered_posts" + subPath + "?cursor=" + this.state.postsCursor;

            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                    
                        if ( responseJSON["msg"] == "success" ) { 
                            var newPosts =          responseJSON["json"].childProps.postsJSON;
                        
                            this.setState( {
                                postsCompleteJSON:  this.state.postsCompleteJSON.concat(newPosts), 
                                postLoadMsg:        "more loaded ↓",
                                postsMore:          responseJSON["json"].childProps.more,
                                postsCursor:        responseJSON["json"].childProps.nextCursor,
                                lastPostShown:      this.state.lastPostShown + 1,
                                postsVisibleJSON:   this.state.postsVisibleJSON.concat(newPosts[0])
                            }); 
                            /*
                            this.setState( {
                                postsJSON: previousPosts.concat(newPosts)         
                            });
                            */
                            this.busyFetching = false;
                                       
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                errorMsg: responseJSON["msg"]
                            });
                        }
               
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error( url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                ajaxReq.send();
        
        } else {
            this.setState( {
                postLoadMsg:      "-- end of feed --"          
            });
        }
    
    }

    setVisiblePosts() {
        var postsCompleteJSON = this.state.postsCompleteJSON;
        var lastPostShown =     this.state.lastPostShown;
        var nextPost;
        var cachedImg;
        var invisibleScreenHt = 600;
        
    
        if ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight + invisibleScreenHt) && (lastPostShown == postsCompleteJSON.length)) {
            this.getMorePosts();
        }
    
        // this operates the infinite scroll
        while ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight + invisibleScreenHt) && (this.state.lastPostShown < postsCompleteJSON.length)) {

            var lastPostShown = this.state.lastPostShown + 1;
            this.setState(
                { 
                    lastPostShown:      lastPostShown,
                    postsVisibleJSON:   this.state.postsVisibleJSON.concat(postsCompleteJSON[this.state.lastPostShown])
                }
            );
            
            
            // try caching next video
            setTimeout(function(){ 
                var postsCompleteJSON = this.state.postsCompleteJSON;
                
                // The +2 fails at the end of the fetched posts list, so try:catch
                try {
                    this.preloadImages([ 
                        //this.props.globalProps.GCS_VIDEO_PATH + "t/" + postsCompleteJSON[lastPostShown - 1].postID,
                        //this.props.globalProps.GCS_VIDEO_PATH + "t/" + postsCompleteJSON[lastPostShown].postID,
                        this.props.globalProps.GCS_VIDEO_PATH + "t/" + postsCompleteJSON[lastPostShown + 1].postID,
                        this.props.globalProps.GCS_VIDEO_PATH + "t/" + postsCompleteJSON[lastPostShown + 2].postID,
                    ]);
                }
                catch(err) {
                    var fake;
                }
                
                try {
                    this.preloadVideos([ 
                       //this.props.globalProps.GCS_VIDEO_PATH + "p/" + postsCompleteJSON[lastPostShown - 1].postID,
                       //this.props.globalProps.GCS_VIDEO_PATH + "p/" + postsCompleteJSON[lastPostShown].postID,
                       this.props.globalProps.GCS_VIDEO_PATH + "p/" + postsCompleteJSON[lastPostShown + 1].postID,
                       this.props.globalProps.GCS_VIDEO_PATH + "p/" + postsCompleteJSON[lastPostShown + 2].postID,
                    ]); 
                }
                catch(err) {
                    var fake2;
                }
                
            }.bind(this), 400);
            
        }
    }
    
    preloadImages(array) {
        if (!this.preloadImages.list) {
            this.preloadImages.list = [];
        }
        var list = this.preloadImages.list;
        for (var i = 0; i < array.length; i++) {
            var img = new Image();
            img.onload = function() {
                var index = list.indexOf(this);
                if (index !== -1) {
                    // remove image from the array once it's loaded
                    // for memory consumption reasons
                    //console.log(index)
                    //console.log(list)
                    list.splice(index, 1);
                    //console.log(list)
                }
            }
            list.push(img);
            img.src = array[i];
        }
        //console.log(list)
    }
    
    preloadVideos(array) {
        if (!this.preloadVideos.list) {
            this.preloadVideos.list = [];
        }
        var list = this.preloadVideos.list;
        for (var i = 0; i < array.length; i++) {
            var vid = document.createElement('video');
            
            //vid.addEventListener('loadedmetadata', function() {
            //vid.addEventListener('loadeddata', function() {
            vid.addEventListener('progress', function() {
                var index = list.indexOf(this);
                if (index !== -1) {
                    // remove image from the array once it's loaded
                    // for memory consumption reasons
                    list.splice(index, 1);
                }
            }, false);

            list.push(vid);
            vid.src = array[i];
        }
        //console.log(list)
    }

    
    componentDidMount() {   
        // didMount not finished yet, because post not loaded before this is mounted.
        // so, setVisiblePosts after Load
        
        this.getOrientationPermission();    // try to see if can avoid having to click every reload
        
        window.addEventListener(
          "pageshow",   // "load",      // pageshow works after "back" button in iOS "load" doesn't
            function() {
              this.setVisiblePosts();
                window.addEventListener(
                    "resize", 
                    function() {
                                this.setVisiblePosts();
                            }.bind(this), 
                    false
                );
                window.addEventListener(
                    "scroll", 
                    function() {
                            this.setVisiblePosts();
                        }.bind(this), 
                    false
                );
            }.bind(this), 
            false
        );
    }
    
    //********************************************************************
    //************  Note: addPost, editPost, deletePost  *****************
    //**************** are duplicated in Profile.jsx *********************
    //********************************************************************
    
    addPost(postFormComponent) {
        var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        var videoWidth =    ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        var videoHeight =   ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;
        
        var formData = new FormData();
            formData.append("video",            video);
            formData.append("caption",          caption);
            formData.append("videoDuration",    duration);
            formData.append("videoWidth",       videoWidth);
            formData.append("videoHeight",      videoHeight);
                
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =              JSON.parse(ajaxReq.responseText);
                    
                    if ( responseJSON["msg"] == "success" ) { 

                        var newPost = {
                            "postID":                responseJSON["postID"],
                            "userID":                this.props.globalProps.currentUser.userID,
                            "username":              this.props.globalProps.currentUser.username,
                            "userFullName":          this.props.globalProps.currentUser.fullName,
                            "video":                 {
                                "url":                  this.props.globalProps.GCS_VIDEO_PATH + 'p/' + responseJSON["postID"],
                                "time":                 responseJSON["time"],
                                "duration":             duration,
                                "width":                videoWidth,
                                "height":               videoHeight,
                                "caption":              caption,
                                "hashtags":             []
                                                     },
                            "likes":                 [],
                            "comments":              []
                        }
                    
                     
                        var statePostsJSON =        this.state.postsJSON;
                        statePostsJSON.unshift(newPost);
                        this.setState( {
                            postsCompleteJSON:              statePostsJSON
                        });
                        postFormComponent.resetForm();
                        
                        return;

                    } else {
                        console.error("error posting " + responseJSON["postID"]);
                        return "error posting " + responseJSON["postID"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/add_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/add_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
                
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }
    
    editPost(caption) {
        // this is not used.  Since we only edit caption, moved to PostCaption
        
        //var video =         ReactDOM.findDOMNode(postFormComponent.refs.video).files[0];
        //var caption =       ReactDOM.findDOMNode(postFormComponent.refs.caption).value;
        //var postID =        postFormComponent.props.postID;
        //var duration =      ReactDOM.findDOMNode(postFormComponent.refs.duration).value;
        //var videoWidth =   ReactDOM.findDOMNode(postFormComponent.refs.videoWidth).value;
        //var videoHeight =  ReactDOM.findDOMNode(postFormComponent.refs.videoHeight).value;
          
        var formData = new FormData();
        //if (video != undefined) {
        //    formData.append("video", video);
        //}
            formData.append("caption", caption);
            formData.append("postID", postID);
            //formData.append("videoDuration", duration);
            //formData.append("videoWidth", videoWidth);
            //formData.append("videoHeight", videoHeight);
                
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") {
 
                        var statePostsJSON =        this.state.postsCompleteJSON;
                        for (var index in statePostsJSON) {
                            if ( statePostsJSON[index]["postID"] == postID ) {
                                statePostsJSON[index].video.caption = caption;
                                this.setState( 
                                    {
                                        postsCompleteJSON:          statePostsJSON
                                    }
                                );
                                break;
                            }
                        }
                        postFormComponent.resetForm();

                    } else {
                        console.error("error posting " + responseJSON["msg"]);
                        return "error posting " + responseJSON["msg"];
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/edit_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/edit_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send(formData);
                
            /*
            async:          false,
            cache:          false,
            contentType:    false,
            processData:    false,
            */        
    }
    
    deletePost(postID) {
        
        var formData = new FormData();
            formData.append("postID", postID);
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") { 

                        var postsJSON =           this.state.postsVisibleJSON;
                         
                        for (var index in postsJSON) {
                            if ( postsJSON[index]["postID"] == postID ) {
                                postsJSON.splice(index, 1);
                                this.setState( 
                                    {
                                        postsVisibleJSON:          postsJSON
                                    }
                                );
                                break;
                            }
                        }

                    } else {
                        console.error("error deleting post " + postID);
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/delete_post/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/delete_post/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            ajaxReq.send(formData);
    }
     
    
    approveFollowHandler(userID) {
        var formData = new FormData();
            formData.append("userID",     userID);
          
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    if ( responseJSON["msg"] == "success" ) { 

                        var followRequests =  this.state.followRequests;
                        
                        for (var index in followRequests) {
                            if (followRequests[index]["userID"] == userID) {
                      	    followRequests.splice(index, 1);

                              this.setState( {
                                  followRequests: followRequests,
                                  followersCount: this.state.followersCount + 1       
                              });
                              break;
                            }
                        }
                        return;

                    } else {
                        this.setState( 
                            {
                                errorText:      "error"
                            }
                        );
                    }
                 
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/approve_follow_request/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/approve_follow_request/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
    }
    
    denyFollowHandler(userID) {
        var formData = new FormData();
            formData.append("userID",     userID);
          
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    if ( responseJSON["msg"] == "success" ) { 

                        var followRequests =  this.state.followRequests;

                        for (var index in followRequests) {
                            if (followRequests[index]["userID"] == userID) {
                      	    followRequests.splice(index, 1);

                              this.setState( {
                                  followRequests: followRequests         
                              });
                              break;
                            }
                        }
                        return;

                    } else {
                        this.setState( 
                            {
                                errorText:      "error"
                            }
                        );
                    }
                 
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/deny_follow_request/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/deny_follow_request/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
    }
    
    approveFollowSuggestionHandler(userID, index) {
        
        var myUsername =           this.props.globalProps.currentUser.userID;

        if (userID == undefined || myUserID == undefined) {
            this.setState( {
                errorMsg: "user undefined"
            });
            return; // should never get here
        }

        var formData = new FormData();
            formData.append("profileUserID", userID);
  
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if ( responseJSON["msg"] == "success" ) { 
                        //var profileFollowers =        this.state.childProps.profileJSON.followers;
                        //profileFollowers.push(myUsername);
              
                        //var iAmFollowing = this.state.following;
                        //iAmFollowing.push(followUsername);
                        
                        var suggestedFollows = this.state.suggestedFollows;
                            
                        for (var index in suggestedFollows) {
                            if (suggestedFollows[index]["userID"] == userID) {
                                suggestedFollows.splice(index, 1)
              
                                this.setState( {
                                    suggestedFollows: suggestedFollows         
                                });
                                break;
                            }
                        }
                        return;
                    } else if ( responseJSON["msg"] == "success.  no approval needed" ) { 
                        var profileFollowers =        this.state.childProps.profileJSON.followers;
                        profileFollowers.push(myUsername);
          
                        var iAmFollowing = this.state.following;
                        iAmFollowing.push(userID);
          
                        
                        var suggestedFollows = this.state.suggestedFollows;
                            
                        for (var index in suggestedFollows) {
                            if (suggestedFollows[index]["userID"] == userID) {
                                suggestedFollows.splice(index, 1)

                                this.setState( {
                                    suggestedFollows: suggestedFollows ,
                                    followingCount:   this.state.followingCount + 1         
                                });
                                break;
                            }
                        }
                        return;

                    } else {
                        // give user msg
                        this.setState( {
                            errorMsg: responseJSON["msg"]
                        });
                    }
         
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/follow/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/follow/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
        return;

    }
    
    denyFollowSuggestionHandler(userID, index) {
        var formData = new FormData();
            formData.append("userID",     userID);
          
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    if ( responseJSON["msg"] == "success" ) { 
                        
                        var suggestedFollows = this.state.suggestedFollows;
                            suggestedFollows.splice(index, 1)

                        this.setState( {
                            suggestedFollows: suggestedFollows         
                        });
                        return;

                    } else {
                        this.setState( 
                            {
                                errorText:      "error"
                            }
                        );
                    }
                 
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/deny_follow_suggestion/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/deny_follow_suggestion/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);
    }
    
    
    
  render() {
      var followRequests =          this.props.childProps.followRequests;
      
      var followRequestsDOM = null;
      if (followRequests != undefined && followRequests.length > 0 ) {
          followRequestsDOMinner =      followRequests.map(
                                          function (followRequest) {
                                            return (
                                              <tr key={`fr-${followRequest.userID}`}>
                                                <td style={{"width":"200px", "minWidth":"260px"}}>
                                                    <img  src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ followRequest.userID }_av_sm`}                                                                                              style={{"verticalAlign":"middle"}} 
                                                          className="avatar_sm margin4px"
                                                          alt=""/>
                                                    &nbsp; {`${ followRequest.name } (@${ followRequest.username })`}
                                                </td>
                                                <td>
                                                    <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.approveFollowHandler(followRequest.userID) }.bind(this)}>
                                                        Approve
                                                    </div>
                                                    <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.denyFollowHandler(followRequest.userID) }.bind(this)}>                             
                                                        Not now
                                                    </div>
                                                </td>
                                              </tr>
                                            );
                                        }.bind(this))
          followRequestsDOM =       <div className="choose_username_div" style={{"paddingTop":"20px", "width":"400px"}}>
                                        <div style={{"textAlign":"center"}}>
                                            The following user(s) would like to follow your posts:
                                        </div>
                                        <table style={{"fontSize":"11px"}}>
                                            <tbody>
                                            { followRequestsDOMinner }
                                            </tbody>
                                        </table>
                                    </div>
                              
      }
      
      var suggestedFollows =  this.props.childProps.suggestedFollows;
      
      var followSuggestionsDOM = null;
      if (suggestedFollows != undefined && suggestedFollows.length > 0 ) {
          followSuggestionsDOMinner =   suggestedFollows.map(
                                          function (followSuggestion, i) {
                                            return (
                                              <tr key={`sf-${followSuggestion.username}`}>
                                                <td style={{"width":"200px", "minWidth":"260px;"}}>
                                                        <img src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ followSuggestion.userID }_av_sm`} style={{"verticalAlign":"middle"}} alt=""/>
                                              
                                                        &nbsp; {`${ followSuggestion.name } (@${ followSuggestion.username })`}
                                                </td>
                                                <td>
                                                    <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.approveFollowSuggestionHandler(followSuggestion.username, i) }.bind(this)}>
                                                        Follow
                                                    </div>
                                                    <div className="l_c_button_off flex" style={{"marginTop":"15px","display":"inline"}} onClick={      function() {this.denyFollowSuggestionHandler(followSuggestion.username, i) }.bind(this)}>                             
                                                        Not now
                                                    </div>
                                                </td>
                                              </tr>
                                            );
                                        }.bind(this))
          followSuggestionsDOM =       <div className="choose_username_div width462" style={{"paddingTop":"20px"}}>
                                        <div style={{"textAlign":"center"}}>
                                        Here are some popular users, followed by your friends:
                                        </div>
                                        <table style={{"fontSize":"11px"}}>
                                            <tbody>
                                            { followSuggestionsDOMinner }
                                            </tbody>
                                        </table>
                                    </div>
                              
      }
      
      var oauthFriends =  this.props.childProps.oauthFriendsAvail;
      
      //oauthFriends = ['facebook', 'google', 'twitter'];
      
      var oauthFriendsDOM = null;
      if (oauthFriends != undefined && oauthFriends.length > 0 ) {
          var oauthFriendsNodes = oauthFriends.map(
              function (oauthNetwork) {
                return (
                    <OauthFriendFinder
                        network =           { oauthNetwork }
                        currentUser =       { this.props.globalProps.currentUser }
                        globalProps =       { this.props.globalProps }
                        key =               { `ff_${oauthNetwork}` }
                    />
                );
          }.bind(this));
      }
      
      
      if (this.state.postsVisibleJSON.length == 0) {
          // post not found
          var postNodes =     <div className = "feed" style={{"textAlign":"center","margin":"80px 0px 120px 0px"}}>
                                  Sorry, no matching post(s) found.  Please try again.
                              </div>;
      } else {
          var postNodes =      this.state.postsVisibleJSON.map(
              function (singlePostJSON, index) {
                var addPost =                 this.addPost;
                var editPost =                this.editPost;
                var deletePost =              this.deletePost;
              
                return (
                    <div className = "feed" key={`postCntr_${ singlePostJSON.postID }`}>
                        <PostContainer 
                            singlePostJSON =    { singlePostJSON }
                            addPost =           { addPost }
                            editPost =          { editPost }
                            deletePost =        { deletePost }
                            currentUser =       { this.props.globalProps.currentUser }
                            globalProps =       { this.props.globalProps }
                            childProps=         { this.props.childProps }
                            key =               { `${singlePostJSON.postID}` }
                            index =             { index }   // to fix iOS8 bug, waiting for load
                       />
                    </div>
                );
          }.bind(this));
      }
      
      var postLoadMsg = null;
      if (this.state.postLoadMsg) {
          postLoadMsg = <div className="center_this">
                          { this.state.postLoadMsg }
                     </div>
      }
      /*
      var postForm = (this.props.globalProps.currentUser.username == "anonymous")
                          ? null 
                          : <PostForm
                                globalProps =       { this.props.globalProps }
                                addPost =           { this.addPost }
                                key =               "postForm"
                            />
      */ 
      
      var tiltDOM = <div style={{"textAlign":"center"}}> Mouse over to interact </div>
      if (isMobile) {
          if (this.state.orientationPermission) {
              tiltDOM = <div style={{"textAlign":"center"}}> Tilt device to interact </div>
          } else {
              tiltDOM = <div 
                            style={{"textAlign":"center", "fontSize": "1.5em"}}
                            onClick = { function() {this.getOrientationPermission();}.bind(this) }
                        > 
                              Click Here to allow tilt interaction 
                         </div>
          }
      }    
                       
                                
      return (
          <div>
              { followRequestsDOM }
              { followSuggestionsDOM }
              { oauthFriendsDOM }
              <div ref="main" key="posts">
                  <div className="search_forms">
                      <SearchForms 
                           currentUser =          { this.props.globalProps.currentUser }
                           sortedRecentSearches = { this.props.childProps.sortedRecentSearches }
                      />
                  </div>
                  
                  { tiltDOM }    

                  {/* postForm */}
                  { oauthFriendsNodes }
                  { postNodes }
                  { postLoadMsg }
              </div>
          </div>
      )
  }
}


export default ShowPosts;