import "../styles/_PostContainer.scss";
import "../styles/_Login.scss";
                    
import FollowHandler    from "./FollowHandler.jsx";
import SearchForms      from "./SearchForms.jsx";

import ReactDOM         from 'react-dom';


class Follows extends React.Component {
      // this.props.location gets url
      // this.props is passed as spread: {...this.state} from parent
      // this.state is loaded with props in compWillRcvProps
    
    constructor(props) {
        super(props);
        
        var followersCounters = {};
            followersCounters[this.props.childProps.profileJSON.userID] = this.props.childProps.profileJSON.followersCount;
            
        var firstJSON = this.props.childProps.followsJSON; 
        for (var index in firstJSON) {
            followersCounters[firstJSON[index]["userID"]] = firstJSON[index]["followersCount"];
        }
        var emptyFollowingMsg = ( this.props.childProps.privacyMsg == "blocked by privacy" )
                                    ? "'s following is blocked by privacy"
                                    : " is not currently following anyone";
        var emptyFollowersMsg = ( this.props.childProps.privacyMsg == "blocked by privacy" )
                                    ? "'s followers are blocked by privacy"
                                    : " does not currently have any followers";
                                    
        this.state = {
            //  Profile stuff
            errorMsg:           '',
            followingCount:      this.props.childProps.profileJSON.followingCount,
            iAmFollowing:        this.props.childProps.profileJSON.iAmFollowing,
            
            // Follows stuff
            // here state.postsVisibleJSON are those on the page.  state.postsCompleteJSON is the entire feed avail
            followsCompleteJSON: firstJSON, 
            followsVisibleJSON:  firstJSON.slice(0, 1),
            // followsJSON:         firstJSON.slice(0, 1),    
            followsMore:         this.props.childProps.more,
            followsCursor:       this.props.childProps.nextCursor,
            followsLoadMsg:      null,
            lastFollowShown:     1,
            emptyFollowingMsg:   emptyFollowingMsg,
            emptyFollowersMsg:   emptyFollowersMsg,
            followersCounters:   followersCounters,
        };
        
        this.busyFetching               = false;
        this.goToProfile                = this.goToProfile.bind(this);
        this.incrementFollowersCount    = this.incrementFollowersCount.bind(this);
        this.getMoreFollows             = this.getMoreFollows.bind(this);
        this.setVisibleFollows          = this.setVisibleFollows.bind(this);
    }
    
      
    goToProfile(username) {
        window.location.href = "/profile/" + username;
    }

    
    incrementFollowersCount(userID, amount, fromWhere) { 
        // amount can be negative to decrement
        var followersCounters = this.state.followersCounters;

        followersCounters[userID] = followersCounters[userID] + amount;

        this.setState( {
            followersCounters: followersCounters         
        });
        
        if (fromWhere == "profile") {
            //add or remove user from view (user not in followsJSON)
            if (amount == 1) {
                this.setState( {
                    iAmFollowing:   true         
                });
            } else if (amount == -1) {
                this.setState( {
                    iAmFollowing:   false         
                });
            }
        } else if ((fromWhere == "follows list") && (this.props.childProps.profileJSON.userID == this.props.globalProps.currentUser.userID)) {
            // if looking at your OWN followers or following, unfollowing should remove them from the feed, and update your count.
            if (amount == -1) {
                var newFollowsJSON = this.state.followsCompleteJSON;
                for (var index in newFollowsJSON) {
                    if (newFollowsJSON[index]["userID"] == userID) {
                        newFollowsJSON.slice(index, 1);
                        break;
                    }
                }
                this.setState( {
                    followsCompleteJSON:   newFollowsJSON       
                });
            }
            this.setState( {
                followingCount:   this.state.followingCount + amount       
            });
        }  
    }
    
    getMoreFollows() {
        if (this.busyFetching) {
            return;
        }
        this.busyFetching = true;
        
        if (this.state.followsMore) {
            this.setState( {
                followLoadMsg:      "loading..."          
            });

            var url =   this.props.childProps.fetchFollowsURL;
            
            if (url != '/get_most_followers_json/') {
                url = url + this.props.childProps.profileJSON.userID;
            }
            url += "?cursor=" + this.state.followsCursor;
                        
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        if ( responseJSON["msg"] == "success" ) { 
                            var newFollows =          responseJSON["json"].childProps.followsJSON;
                            //var previousFollows =     this.state.followsCompleteJSON;
                            var followersCounters =   this.state.followersCounters;
                            
                            for (var index in newFollows) { 
                                followersCounters[newFollows[index]["userID"]] = newFollows[index]["followersCount"];
                            }
                            
                            this.setState( {
                                followsCompleteJSON:  this.state.followsCompleteJSON.concat(newFollows), 
                                followsLoadMsg:       "more loaded ↓",
                                followsMore:          responseJSON["json"].childProps.more,
                                followsCursor:        responseJSON["json"].childProps.nextCursor,
                                lastFollowShown:      this.state.lastFollowShown + 1,
                                followsVisibleJSON:   this.state.followsVisibleJSON.concat(newFollows[0]),
                                followersCounters:    followersCounters 
                            });
                            
                            this.busyFetching = false;
                                           
                            return;

                        } else {
                            // give user msg
                            this.setState( {
                                errorMsg: responseJSON["msg"]
                            });
                        }
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error( url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
                ajaxReq.send();
            
        } else {
            this.setState( {
                followsLoadMsg:      "-- end of feed --"          
            });
        }
        
    }
    
    setVisibleFollows() {
        var followsCompleteJSON =   this.state.followsCompleteJSON;
        var lastFollowShown =       this.state.lastFollowShown;
        if ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) && (lastFollowShown == followsCompleteJSON.length)) {
            this.getMoreFollows();
        }
        
        // this operates the infinite scroll
        while ((ReactDOM.findDOMNode(this.refs.main).getBoundingClientRect().bottom <= window.innerHeight) &&                                  (this.state.lastFollowShown < followsCompleteJSON.length)) {
            
            lastFollowShown = this.state.lastFollowShown + 1;
            this.setState(
                { 
                    lastFollowShown:        lastFollowShown,
                    followsVisibleJSON:     this.state.followsVisibleJSON.concat(followsCompleteJSON[this.state.lastFollowShown])
                }
            );
        }
    }
    
    componentDidMount() {    
        // didMount not finished yet, because follow not loaded?
        window.addEventListener(
            "load", 
            function() {
                this.setVisibleFollows();
                window.addEventListener(
                    "resize", 
                    function() {
                        this.setVisibleFollows();
                    }.bind(this), 
                    false
                );
                window.addEventListener(
                    "scroll", 
                    function() {
                        this.setVisibleFollows();
                    }.bind(this), 
                    false
                );
            }.bind(this), 
            false
        );
    } 
    
  render() {
      var profileJSON =  this.props.childProps.profileJSON;
      
      if ((profileJSON.msg.indexOf("not found") != -1) || (profileJSON.msg.indexOf("not activated") != -1)) {
          return (
              <div className="choose_username_div" style={{"padding":"30px", "margin-bottom":"200px"}}>
              <div className="profile_header">{ profileJSON.msg }</div>
              </div>
          )
      } else {
          var banUserDOM = null;
          if (this.props.globalProps.currentUser.https == "superuser" && profileJSON["msg"] == 'show profile') {
              banUserDOM = <div style={{"textAlign":"center","padding":"25px"}}>
                              <a href={`/obfusG7Y6UA/ban_user/${ profileJSON.userID }`}>ban this user</a><br />
                              <a href={`/obfusG7Y6UA/ban_user/${ profileJSON.userID }/true`}>ban this user, and delete his posts</a>
                           </div>;
          }
      
          var usernameDOM;
          if (profileJSON["name"] != undefined) {
              usernameDOM = profileJSON["name"];
          } else {
              usernameDOM = "< private >";
          }
          
          var emailDOM;
          if (profileJSON["email"] != undefined) {
              emailDOM = <div>
                              <a href={`mailto:${profileJSON["email"]}`}>{ profileJSON["email"] }</a>
                         </div>
          }
          
          var sloganDOM = null;
          if (profileJSON["slogan"] != undefined) {
              var slogan = profileJSON["slogan"];
              sloganDOM =       <div style={{"fontStyle":"italic"}}>
                                    {`"${slogan}"`}
                                </div>
          }
          
          var followingDOM =   (this.state.followingCount == 0)
                          ? "Following: 0"
                          : <a href={`/following/${profileJSON.username}`}>
                                {` Following:  ${this.state.followingCount}` }
                            </a>;
           
          var followersDOM =   (this.state.followersCount == 0)
                          ? "Followers: 0"
                          : <a href={`/followers/${profileJSON.username}`}>
                                {` Followers:  ${ this.state.followersCounters[profileJSON.userID] }` }
                            </a>;
                                
          var errorDOM = null;
          if (this.state.errorMsg) {
              errorDOM = <div className="center_this">
                              { this.state.errorMsg }
                         </div>
          }
          
          var followsLoadMsg = null;
          if (this.state.followsLoadMsg) {
              followsLoadMsg = <div className="center_this">
                              { this.state.followsLoadMsg }
                         </div>
          }
          
          var relPath = window.location.href;
          
          /*
          var followsTitle = (relPath.indexOf("/followers/most/") == -1)
                                ? (relPath.indexOf("/following/") != -1)
                                      ? (this.props.childProps.userFollowsMe == true)
                                          ? (this.state.followsCompleteJSON.length > 0)
                                              ? "Other than yourself, @" + profileJSON.username + " is following:"
                                              : "Other than yourself, @" + profileJSON.username + " is not currently following anyone"  
                                          : (this.state.followsCompleteJSON.length > 0)
                                              ? "@" + profileJSON.username + " is following:"
                                              : "@" + profileJSON.username + " is not currently following anyone"

                                      :  (this.state.followMsg == "Unfollow")
                                          ? (this.state.followsCompleteJSON.length > 0)
                                              ? "@" + profileJSON.username + "'s other followers:"
                                              : "Other than yourself, @" + profileJSON.username + " has no followers"
                                          : (this.state.followsCompleteJSON.length > 0)
                                              ? "@" + profileJSON.username + "'s followers:"
                                              : "@" + profileJSON.username + " does not currently have any followers"
                                 : "Freezify's most-followed users:"
                             ;
          */
          var followsTitle = ((relPath.indexOf("/followers/most/") == -1) && (relPath.indexOf("/n/") == -1))
                                  ? (relPath.indexOf("/following/") != -1)
                                        ? (this.state.followsCompleteJSON.length > 0)
                                                ? "@" + profileJSON.username + " is following:"
                                                : "@" + profileJSON.username + this.state.emptyFollowingMsg

                                        : (this.state.followsCompleteJSON.length > 0)
                                                ? "@" + profileJSON.username + "'s followers:"
                                                : "@" + profileJSON.username + this.state.emptyFollowersMsg
                                   : (relPath.indexOf("/followers/most/") > -1)
                                        ? "Freezify's most-followed users:"
                                        : "User search results"
                               ;
         
          var listMeDOM =  ((relPath.indexOf("/following/") == -1 && this.state.iAmFollowing) || ( relPath.indexOf("/following/") != -1 && profileJSON.userFollowsMe))
                               ? <div className="follows_feed" key="followCntr_me">
                                    <div    style={{"display":"inline-block"}} 
                                            onClick={function() {this.goToProfile(this.props.globalProps.currentUser.username) }.bind(this)} >
                                            <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ this.props.globalProps.currentUser.userID}_av_lg`} 
                                                    className="profile_avatar_url"
                                                    style={{"width":"80px", "height":"80px"}}
                                                    alt="{{ this.props.globalProps.currentUser.username }} avatar" 
                                            />
                                    </div>
                                    <div style = {{"display":"inline-block", "width":"420px", "paddingLeft":"20px"}}
                                        onClick = {function() {this.goToProfile(this.props.globalProps.currentUser.username) }.bind(this)}
                                    >
                                        <div>
                                            <a href={`/profile/${this.props.globalProps.currentUser.username}`}>@{ this.props.globalProps.currentUser.username }</a>
                                        </div>
                                        <div>
                                            <a href={`/profile/${this.props.globalProps.currentUser.username}`}>{ this.props.globalProps.currentUser.name }</a>
                                        </div>
                                        <div style={{"fontStyle": "italic"}}>
                                        { this.props.globalProps.currentUser.slogan }
                                        </div>
                                        <div>
                                            <a href={`/followers/${this.props.globalProps.currentUser.username}`}>{ this.state.followersCounters[this.props.globalProps.currentUser.userID] } Followers</a>
                                        </div>
                                    </div>
                                    <div style={{"position":"absolute","top":"20px","right":"0px"}}>
                                        <FollowHandler    incrementFollowersCount =   { this.incrementFollowersCount }
                                                          profileJSON =     { this.props.globalProps.currentUser }
                                                          currentUser =     { this.props.globalProps.currentUser } 
                                                          globalProps =     { this.props.globalProps }
                                                          fromWhere =       "follows list"
                                        />
                                    </div>
                                </div>
                              : null    
        ;
        
          var followNodes =      this.state.followsVisibleJSON.map(
              function (singleFollowJSON) {
                //if (singleFollowJSON.userID == this.props.globalProps.currentUser.userID) {
                //    return
                    //} else {
                var followsMeText = (singleFollowJSON.userFollowsMe)
                                        ? "(@" + singleFollowJSON.username + " follows me.)"
                                        : ""
                ;
                 
                 return (
                    <div    className="follows_feed"
                            key={`followCntr_${ singleFollowJSON.userID }`}
                    >
                        <div style={{"display":"inline-block"}}
                            onClick={function() {this.goToProfile(singleFollowJSON.username) }.bind(this)} >
                                    <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ singleFollowJSON.userID}_av_lg`} 
                                            className="profile_avatar_url"
                                            style={{"width":"70px", "height":"70px"}}
                                            alt="{{ singleFollowJSON.username }} avatar" 
                                    />
                        </div>
                        <div style = {{"display":"inline-block", "width":"420px", "paddingLeft":"20px"}}
                            onClick={function() {this.goToProfile(singleFollowJSON.username) }.bind(this)}
                        >
                            <div>
                                <a href={`/profile/${singleFollowJSON.username}`}>@{ singleFollowJSON.username }</a>
                            </div>
                            <div>
                                <a href={`/profile/${singleFollowJSON.username}`}>{ singleFollowJSON.name }</a>
                            </div>
                            <div style={{"fontStyle": "italic"}}>
                                { singleFollowJSON.slogan }
                            </div>
                            <div>
                                <a href={`/followers/${singleFollowJSON.username}`}>{ this.state.followersCounters[singleFollowJSON.userID] } Followers</a>
                            </div>
                            <div>
                                { singleFollowJSON.profileMsg }
                            </div>
                            <div style={{"textAlign":"center","fontSize":"10px"}}>
                                { followsMeText }
                            </div>
                        </div>
                        <div style={{"position":"absolute","top":"20px","right":"0px"}}>
                            <FollowHandler    incrementFollowersCount =   { this.incrementFollowersCount }
                                              profileJSON =     { singleFollowJSON }
                                              currentUser =     { this.props.globalProps.currentUser } 
                                              globalProps =     { this.props.globalProps }
                                              fromWhere =       "follows list"
                            />
                        </div>
                    </div>
                );
             //}
          }.bind(this));
          
          var followsMeText = (profileJSON.userFollowsMe)
                                    ? "(@" + profileJSON.username + " follows me.)"
                                    : ""
          ;
          
          var profileDOM = null;
          if (profileJSON["msg"] == 'show profile') {
              profileDOM = <div className="choose_username_div" style={{"paddingTop":"20px", "width":"462px"}}>
                              
                              <table style={{"width": "462px"}}>
		                          <tr>
		                              <td style={{"height": "125px", "width":"125px", "verticalAlign":"middle", "textAlign":"center"}}>
		                                  <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ profileJSON.userID }_av_lg`} 
		                                          className="profile_avatar_url"  
		                                          alt="{{ profile_username }} avatar" 
		                                  />
			                          </td>
								  </tr>
								  <tr>
					                  <td style={{"width":"400px", "textAlign":"center", "padding":"0 0 2px 0"}}>
					                      <div className="profile_header">@{ profileJSON.username }</div>
					                      { banUserDOM }
					                  </td>		
					              </tr>
								  <tr>
		                              <td style={{"width":"400px", "textAlign":"center", "padding":"0 0 2px 0"}}>
		                                  { usernameDOM }
		                              </td>							
		                          </tr>
								  <tr>
		                              <td style={{"width":"400px", "textAlign":"center", "padding":"0 0 2px 0"}}>
		                                  { sloganDOM }								  
		                              </td>
		                          </tr>		
										  
							  <tr>
		                          <td style={{"width":"400px", "textAlign":"center", "padding":"0px"}}>
									  <span style={{"width":"400px"}}>
										  <span style={{"float": "left", "padding": "0 8px 0 141px", "textAlign":"center"}} onClick={  this.handleFollowingClick }>
					                          { followingDOM }
					                      </span>
					                      <span style={{"float": "left", "padding": "0 8px", "textAlign":"center"}} onClick={  this.handleFollowersClick }>
					                          { followersDOM }
					                      </span>
									  </span>
		                          </td>
						
		                      </tr>
										    										  
                              </table>

                              <div style={{"fontSize":"10px"}}>
                                  { followsMeText }
                              </div>
                          
                              <FollowHandler    incrementFollowersCount =   { this.incrementFollowersCount }
                                                profileJSON =     { profileJSON }
                                                currentUser =     { this.props.globalProps.currentUser } 
                                                globalProps =     { this.props.globalProps }
                                                fromWhere =       "profile"
                              />
                                   
                              { errorDOM }
                          </div>
          }
		  
          return (
              <div>
                  <div className="search_forms">    
                     <SearchForms 
                          currentUser =          { this.props.globalProps.currentUser }
                          sortedRecentSearches = { this.props.childProps.sortedRecentSearches }
                     />
                  </div>
		                  { profileDOM }
						  
                  <div ref="main" key="follows">
                      <div style={{"textAlign": "center"}}>
                          { followsTitle }
                      </div>
                      { listMeDOM }
                      { followNodes }
                      { followsLoadMsg }
                  </div>
            </div>
          )            
      }  
  }
  
}


export default Follows;