                require("../styles/_EditPost.scss");
import LoginForm from "./LoginForm.jsx";


class FollowHandler extends React.Component {
      
    constructor(props) {
        super(props);
        this.state = {
              showForm:           'button',
              followMsg:          this.props.profileJSON.followMsg,
              errorMsg:           null
        };
        
        this.hideForm           = this.hideForm.bind(this);
        this.checkLogin         = this.checkLogin.bind(this);
        this.onFollowClick      = this.onFollowClick.bind(this);
        this.onUnFollowClick    = this.onUnFollowClick.bind(this);
    }

      
      UNSAFE_componentWillReceiveProps(nextProps) {    // after login, need to change these msgs
          // should we do an ajax call to update all the posts' likes?
          
          // only if user changed
          if (this.props.globalProps.currentUser.username !== nextProps.currentUser.username) {
              
              var formData = new FormData();
                  formData.append("userID",    this.props.profileJSON.userID)

              var ajaxReq =             new XMLHttpRequest();
                  ajaxReq.addEventListener(
                      "load", 
                      function() {
                          var responseJSON =          JSON.parse(ajaxReq.responseText);
                              
                          this.setState( 
                              {
                                  followMsg:          responseJSON["msg"]
                              }
                          );
                      }.bind(this), 
                      false
                  );
                  ajaxReq.addEventListener(
                      "error", 
                      function(xhr, status, err) {
                          console.error("/check_if_following/", status, err.toString());
                      }.bind(this), 
                      false
                  );
                  ajaxReq.open( "post", "/check_if_following/", true );
                  ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                  ajaxReq.send(formData); 
          }  
      }
      
      hideForm() {
          this.setState(
              {
                  showForm:             "button"
              }
          );
      }
    
      checkLogin(msg) {
          if (this.props.globalProps.currentUser.username == "anonymous"){
              this.setState(
                  {
                      showForm:         "login",
                      loginMsg:         msg
                  }
              );
              return 0;
          } else {
              return 1;
          }
      }

      onFollowClick(e) {
          e.preventDefault();
        
          if ( !this.checkLogin("You must be logged in to Follow") ) {
              return 0;
          }
          this.setState( {
              followMsg:      "...busy..."
          });
          var profileUserID =       this.props.profileJSON.userID;
          var currentUserID =       this.props.globalProps.currentUser.userID;
          
          if (profileUserID == undefined || currentUserID == undefined) {
              this.setState( {
                  errorMsg: "user undefined"
              });
              return; // should never get here
          }
        
          if (profileUserID == currentUserID) {
              this.setState( {
                  errorMsg: "Those who follow themselves in public appear crazy"
              });
              return; // no need to send a request for this
          }

          var formData = new FormData();
              formData.append("profileUserID", profileUserID);
            
          var ajaxReq =             new XMLHttpRequest();
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);

                      if ( responseJSON["msg"] == "success.  pending approval" ) { 
                                              
                          this.setState( {
                              followMsg:      "Follow requested",
                              errorMsg:       null  
                          });
                          return;
                        
                      } else if ( responseJSON["msg"] == "success" ) { 
                          
                          if (this.props.incrementFollowersCount != undefined) {
                              this.props.incrementFollowersCount(profileUserID, 1, this.props.fromWhere);
                          }
                          this.setState( { 
                              followMsg:          "Unfollow",
                              errorMsg:           null 
                          });
                          return;

                      } else {
                          // give user msg
                          this.setState( {
                              errorMsg: responseJSON["msg"]
                          });
                      }
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/follow/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/follow/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              ajaxReq.send(formData);
          return;
        
      }
    
      onUnFollowClick(e) {
          e.preventDefault();
          var profileUserID =       this.props.profileJSON.userID;
          var currentUserID =       this.props.globalProps.currentUser.userID;
          
          this.setState( {
              followMsg:      "...busy..."
          });
          
          if (profileUserID == undefined || currentUserID == undefined) {
              this.setState( {
                  errorMsg: "user undefined"
              });
              return; // should never get here
          }

          var formData = new FormData();
              formData.append("profileUserID",     profileUserID);
            
          var ajaxReq =             new XMLHttpRequest();
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
                    
                      if ( responseJSON["msg"] == "success" ) { 
                    
                          if (this.props.incrementFollowersCount != undefined) {
                              this.props.incrementFollowersCount(profileUserID, -1, this.props.fromWhere);
                          }
                          this.setState( {
                              followMsg:          "Follow",
                              errorMsg:           null
                          });
                        
                          return;

                      } else {
                          // give user msg
                          this.setState( {
                              errorMsg: responseJSON["msg"]
                          });
                      }
                   
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/unfollow/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/unfollow/", true );
              ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
              //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
              ajaxReq.send(formData);
          return;
      }
      
    render() {
        
        var profileUserID =       this.props.profileJSON.userID;
        var currentUserID =       this.props.globalProps.currentUser.userID;
        
        var followDOM =       (profileUserID == currentUserID)
                                  ? <div className="width54">
                                        
                                    </div>
                                  : (this.state.followMsg == "Unfollow")
                                        ? <div className="flex">
                                            <div className="l_c_button_on flex width54" onClick={this.onUnFollowClick}>
                                                Unfollow
                                            </div>
                                          </div>
                                        : (this.state.followMsg == "Follow")
                                            ? <div className="flex" key="transitionKey">
                                                <div    className="l_c_button_off _black flex width54" 
                                                        onClick={this.onFollowClick}>
                                                    { this.state.followMsg }
                                                </div>
                                              </div>
                                            : <div className="flex" key="transitionKey">
                                                <div   className="l_c_button_off _black flex width54">
                                                    { this.state.followMsg }
                                                </div>
                                              </div>
    
        var formChoices = {
            "button":           followDOM,
            "login":            <div className="login_expando">
                                    <LoginForm 
                                        msg =                   { this.state.loginMsg }
                                        globalProps =           { this.props.globalProps }
                                        hideForm =              { this.hideForm }
                                        key =                   "login-form-profile"
                                        style=                  {{"width":"110%","left":"-6%"}}
                                    />
                                </div>
        };  
 
        var errorDOM = (this.state.errorMsg != undefined)
                            ? <div>
                                { this.state.errorMsg }
                              </div>
                            : null
 
      return (
          <div className="flex flex-column">
                  { formChoices[this.state.showForm] }
                  { errorDOM }
          </div>
      );
    }
  }


export default FollowHandler;
