import "../styles/_EditPost.scss";

import FollowHandler from "./FollowHandler.jsx";

import ReactDOM             from 'react-dom';

class LikesHandler extends React.Component {
      
    constructor(props) {
        super(props);
        this.state = {
            numLikes:               this.props.numLikes,
            showLikers:             false,
            iLikedIt:               this.props.iLikedIt,
            likesJSON:              [],
            likesCursor:            'None',
            likesMore:              null,
            viewLikesText:          "view likes...",
            error:                  null,
            errorMsg:               '',
            likeDiv:                (this.props.iLikedIt)
                                        ? "alreadyLikedIt"
                                        : "notYetLikedIt"
        };
        
        this.busyFetching       = false;
        this.setNumLikesMsg     = this.setNumLikesMsg.bind(this);
        this.onLikeClick        = this.onLikeClick.bind(this);
        this.onUnlikeClick      = this.onUnlikeClick.bind(this);
        this.showUnlike         = this.showUnlike.bind(this);
        this.showLike           = this.showLike.bind(this);
        this.showLikers         = this.showLikers.bind(this);
        this.hideLikers         = this.hideLikers.bind(this);
        this.goToProfile        = this.goToProfile.bind(this);
        
    }
    
      
      setNumLikesMsg(numLikes) {

          var numLikesMsg =        '';
          if ( parseInt(numLikes) == 1 ) {
              numLikesMsg =        '1 like';
          } else if ( parseInt(numLikes) > 1) {
              numLikesMsg =        numLikes + ' likes';
          }
          return numLikesMsg;
      }


      
      componentDidUpdate(prevProps, prevState) {    // after login, need to change these msgs
          // should we do an ajax call to update all the posts' likes?
          
          // only if user changed
          if (this.props.globalProps.currentUser.username != prevProps.globalProps.currentUser.username) {
              
              var formData = new FormData();
                  formData.append("postID",    this.props.postID)
              
              fetch('/check_for_like/', {
                  method    : 'POST',
                  headers   : {
                      'Accept'        : 'application/json',
                      'X-CSRFToken'   : csrfToken
                  },
                  mode      : 'same-origin',
                  body      : formData
              })
              .then(
                  function(response) {
                      if (response.status !== 200) {
                          console.log('/check_for_like/ Status Code: ' + response.status);
                          return;
                      }

                      response.json().then(function(responseJSON) {
                          if (responseJSON["msg"] == "yes, liked") {
                              this.setState( 
                                  {
                                      likeDiv:  "alreadyLikedIt"
                                  }
                              );
                          } else {
                              this.setState( 
                                  {
                                      likeDiv:  "notYetLikedIt"
                                  }
                              );
                          }
                      }.bind(this));
                  }.bind(this)
              )
              .catch(function(err) {
                  console.log('/check_for_like/ Fetch Error :-S', err);
                  return;
              });
              return; 
          }  
      }

      
      onLikeClick() {
          if ( this.props.checkLogin("You must be logged in to Like") ) {
              this.setState( 
                  {
                      likeDiv:        "alreadyLikedIt"
                  }
              );

              var formData = new FormData();
                  formData.append("postID",    this.props.postID)
                  
              var ajaxReq =             new XMLHttpRequest();
                  ajaxReq.addEventListener(
                      "load", 
                      function() {
                          var responseJSON =       JSON.parse(ajaxReq.responseText);
                
                          if (responseJSON["msg"] == "success") {

                              this.setState( 
                                  {
                                      numLikes:    this.state.numLikes + 1
                                  }
                              );
                          } else {
                              this.setState( 
                                  {
                                      errorMsg:    responseJSON["msg"]
                                  }
                              );
                          }
                         
                      }.bind(this), 
                      false
                  );
                  ajaxReq.addEventListener(
                      "error", 
                      function(xhr, status, err) {
                          console.error("/like_it/", status, err.toString());
                      }.bind(this), 
                      false
                  );
                ajaxReq.open( "post", "/like_it/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                ajaxReq.send(formData);  
          }
      }
      
      onUnlikeClick() {
          this.setState( {
              likeDiv:          "notYetLikedIt"
          });
          
          var formData = new FormData();
              formData.append("postID",    this.props.postID);
              
          var ajaxReq =             new XMLHttpRequest();
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
            
                      if (responseJSON["msg"] == "success") { 

                          var numLikes =          this.state.numLikes - 1;
              
                          this.setState( {
                              numLikes:         numLikes
                          });

                      } else {
                          this.setState( 
                              {
                                  errorMsg:      responseJSON["msg"]
                              }
                          );
                      }
                     
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error("/unlike_it/", status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "post", "/unlike_it/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
              ajaxReq.send(formData);  
      }
      
      showUnlike() {
          ReactDOM.findDOMNode(this.refs.likedButton).innerHTML = "Unlike";
      }
      
      showLike() {
          ReactDOM.findDOMNode(this.refs.likedButton).innerHTML = "Liked";
      }
      
      showLikers() {
          if (this.busyFetching) {
              return;
          }
          this.busyFetching = true;
          
          this.setState( {
              showLikers:      true          
          });
        
          if (this.state.likesMore) {
              this.setState( {
                  viewLikesText:      "loading..."          
              });
          }
        
          //var formData = new FormData();
          //    formData.append("postID",       this.props.postID );
            
          var url =   "/get_likers_json/" + this.props.postID + "/?cursor=" + this.state.likesCursor;
        
          var ajaxReq =             new XMLHttpRequest();
              ajaxReq.addEventListener(
                  "load", 
                  function() {
                      var responseJSON =          JSON.parse(ajaxReq.responseText);
        
                      if ( responseJSON["msg"] == "success" ) { 
                          var newLikes =            responseJSON["json"]["likersJSON"];
                          
                          var updatedLikes = this.state.likesJSON.concat(newLikes);
                          
                          // whenever add a like, add it to visible.
                          // but after fetch of last like (more=false), only show fetched likes
                          
                          var more =            false;
                          var cursor =          'None';
                          var viewlikesText =   this.state.viewlikesText;
                          
                          if (responseJSON["json"]["more"]) {
                              viewlikesText =       "view more likes";
                              cursor =              responseJSON["json"]["nextCursor"];
                              more =                true;
                          }

                          this.setState( {
                              likesJSON:            updatedLikes, 
                              iLikedIt:             responseJSON["json"]["iLikedIt"],
                              likesMore:            more, 
                              viewlikesText:        viewlikesText,
                              likesCursor:          cursor
                          });
                          
                          this.busyFetching = false;
                          return;

                      } else {
                          // give user msg
                          console.log(responseJSON["msg"])
                          this.setState( {
                              errorMsg: responseJSON["msg"]
                          });
                      }
                  }.bind(this), 
                  false
              );
              ajaxReq.addEventListener(
                  "error", 
                  function(xhr, status, err) {
                      console.error( url, status, err.toString());
                  }.bind(this), 
                  false
              );
              ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
              ajaxReq.send();  
      }
      
      goToProfile(username) {
          window.location.href = "/profile/" + username;
      }
      
      hideLikers() {
          this.setState( 
              {
                  showLikers:      false,
                  likesJSON:       []
              }
          );
      }
      
    render() {
        var currentUser = this.props.globalProps.currentUser;
        
        var likedItDOM = null;
        if (this.state.iLikedIt) {
            
            likedItDOM =    <div className="likes_feed" key={`iLiked_${ this.props.postID }`}>
                              <table style={{"width": "440px"}}>
                                  <tr>
                                      <td onClick={function() {this.goToProfile(currentUser.username) }.bind(this)} >
                                          <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ currentUser.userID}_av_lg`} 
                                                  className="profile_avatar_url"
                                                  style={{"width":"44px", "height":"44px"}}
                                                  alt="{{ currentUser.username }} avatar" 
                                          />
                                      </td>
                                      <td style = {{"width":"300px", "paddingLeft":"20px"}}>
                                          <div>
                                              <a href={`/profile/${currentUser.username}`}>@{ currentUser.username }</a>
                                          </div>
                                          <div>
                                              <a href={`/profile/${currentUser.username}`}>{ currentUser.fullName }</a>
                                          </div>
                                          <div style={{"fontStyle": "italic"}}>
                                              { currentUser.slogan }
                                          </div>
                                
                                          <div>
                                              { currentUser.profileMsg }
                                          </div>
                                      </td>
                                      <td style={{"minWidth":"100px","marginRight":"20px"}}>
                                      </td>
                                  </tr>
                              </table>
                          </div>
        }

        var likeNodes =      this.state.likesJSON.map(
            function (singleLikeJSON) {
              //if (singleLikeJSON.userID == this.props.globalProps.currentUser.userID) {
              //    return
                  //} else {
              //var FollowsMeText = (singleLikeJSON.userFollowsMe)
              //                      ? "(@" + singleLikeJSON.username + " follows me.)"
              //                    : ""
              //;
               return (
                  <div className="likes_feed" key={`likeCntr_${ this.props.postID }_${ singleLikeJSON.userID }`}>
                      <table style={{"width": "440px"}}>
                          <tr>
                              <td onClick={function() {this.goToProfile(singleLikeJSON.username) }.bind(this)} >
                                  <img    src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ singleLikeJSON.userID}_av_lg`} 
                                          className="profile_avatar_url"
                                          style={{"width":"44px", "height":"44px"}}
                                          alt="{{ singleLikeJSON.username }} avatar" 
                                  />
                              </td>
                              <td style = {{"width":"300px", "paddingLeft":"20px"}}>
                                  <div>
                                      <a href={`/profile/${singleLikeJSON.username}`}>@{ singleLikeJSON.username }</a>
                                  </div>
                                  <div>
                                      <a href={`/profile/${singleLikeJSON.username}`}>{ singleLikeJSON.name }</a>
                                  </div>
                                  <div>
                                      { singleLikeJSON.profileMsg }
                                  </div>
                              </td>
                              <td>
                                  <div style={{"marginRight":"20px"}}>
                                      <FollowHandler    profileJSON =     { singleLikeJSON }
                                                        currentUser =     { this.props.globalProps.currentUser } 
                                                        globalProps =     { this.props.globalProps }
                                      />
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </div>
              );
           //}
        }.bind(this));
        
        var showLikersDOM = null;
        if (this.state.showLikers) {
            showLikersDOM = <div className="page_cover">
                                <div style={{"border":"1px solid goldenrod","backgroundColor":"black"}}>
                                    <div style={{"textAlign":"center","fontSize":"11px"}}>
                                        The following users like this post:
                                        <div    className = "deleteLikesDiv tappable"
                                                onClick = { this.hideLikers }
                                        >
                                            x
                                        </div>
                                    </div>  
                                    { likedItDOM }
                                    { likeNodes }
                                    { this.state.viewlikesText }
                                </div>
                            </div>
        }
        
        
        var display = {
            "alreadyLikedIt":       <div    className =     "l_c_button_on" 
                                            onMouseEnter =  { this.showUnlike } 
                                            onMouseLeave =  { this.showLike } 
                                            onClick =       { this.onUnlikeClick }
                                    >
                                        <span ref="likedButton">liked</span>
                                    </div>,
            "notYetLikedIt":        <div className="l_c_button_off" onClick={ this.onLikeClick }>
                                        <span>like</span>
                                    </div>
        }
        
        var likesCountDOM = (this.state.numLikes == 0)
                                ? null
                                : (this.state.errorMsg.length == 0)
                                    ? <div  className="likesCount tappable"
                                            onClick = { this.showLikers }>
                                          { this.setNumLikesMsg(this.state.numLikes) }
                                      </div>
                                    : <div className="likesCount">
                                          { this.state.errorMsg }
                                      </div>
      return (
          <div className="flex">
              { showLikersDOM }
              { likesCountDOM }
              { display[this.state.likeDiv] }
          </div>
      );
    }
  }


export default LikesHandler;
