import "../styles/_Comment.scss";
import "../styles/_Login.scss";

import ReactDOM             from 'react-dom';

class Activate extends React.Component {  
    
    constructor(props) {
        super(props);
        this.state = {
            generalErrorText:           '',
            emailErrorText:             '',
            emailAddress:               '',
            usernameErrorText:          '',
            usernameValidText:          '',
            needEmail:                  this.props.childProps.needEmail
        };
        
        this.checkUsernameAvailabilityPreRequest    = this.checkUsernameAvailabilityPreRequest.bind(this);
        this.checkUsernameAvailability              = this.checkUsernameAvailability.bind(this);
        this.handleEmailSubmit                      = this.handleEmailSubmit.bind(this);
        this.handleUsernameSubmit                   = this.handleUsernameSubmit.bind(this);
    }

    checkUsernameAvailabilityPreRequest() {
        // this function is duplicated in RegisterForm.jsx.  Any changes here should also be done there.
        var reservedUsernames = ['anonymous', 'admin', 'info', 'guest', 'private', 'public', 'trending', 'recent', 'most', 'following', 'followers', 'follow', 'followed', 'freezify', 'like', 'liked', 'freezify', 'freezy', 'freeze', 'frozen', 'froze', 'freezing', 'slomo', 'slo_mo', 'selfie', 'selfy', 'slomotion', 'slo_motion', 'nflfilms', 'gopro', 'go_pro', 'redbull', 'red_bull', 'twitter', 'instagram', 'facebook', '3dselfy', '3dselfie', '3d_selfie', 'stopaction', 'stop_action', "kimkardashian", "kanye", "drake", "jayz", "beyonce", "arianagrande", "selenagomez", "justinbieber", "taylorswift", "kendalljenner", "mileycyrus", "nickiminaj", "katyperry", "kourtneykardash", "kourtneykardashian", "cristiano", "natgeo", "nike", "kevinhart", "kevinhart4real", "harrystyles", "victoriassecret", "messi", "leomessi", "lionelmessi", "caradelevingne", "kingjames", 'jason', 'sacca', '0360', '360', '360video', "0_360", 'pano', 'panos', '360s', 'porn', 'burst' ];
        
        this.setState(
            {
                                    usernameErrorText:      '',
                                    usernameValidText:      '' 
            }
        )
        var username =              ReactDOM.findDOMNode(this.refs.username).value.trim();
    
        if (username.replace(/\s/g, '') == '') {
            this.setState(
                {
                                    usernameErrorText:      "Username required."
                }
            )
            return "no";
        }
    
        if (username.indexOf(' ') != -1) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, spaces not allowed in username."
                }
            )
            return "no";
        }
    
        if ( (username.length <= 3 ) || (reservedUsernames.indexOf(username.toLowerCase()) != -1) ) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, username " + username + " already in use."
                }
            )
            return "no";
        }
    
        if (username.length >= 20 ) {
            this.setState(
                {
                                    usernameErrorText:      "Sorry, username must be 30 characters or less."
                }
            )
            return "no";
        }
    
        var re = /^([a-zA-Z0-9_]*)$/;
        if (!re.test(username)) {
            this.setState(
                {
                                    usernameErrorText:      "Invalid username format.  Only letters, numbers, and underscores allowed."
                }
            )
            return "no";
        }
        return "OK";
    }
    
    checkUsernameAvailability() {
        // this function is duplicated in RegisterForm.jsx.  Any changes here should also be done there.
        // first do a local check, to save request
        doRequest = ( this.checkUsernameAvailabilityPreRequest() == "OK" );
        
        if (doRequest) {
            var username =          ReactDOM.findDOMNode(this.refs.username).value.trim();
            var url =               "/check_username_availability/" + username;
            
            var ajaxReq =           new XMLHttpRequest();
                //ajaxReq.addEventListener(
                //      "progress", this.updateProgress or serve some wait msg, false
                //);
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
            
                        if ( responseJSON["msg"] == "Username valid!" ) {
                            this.setState(
                                {
                                                    usernameValidText:     responseJSON["msg"]
                                }
                            )
                            return;

                        } else {
                            this.setState(
                                {
                                                    usernameErrorText:     responseJSON["msg"]
                                }
                            )
                            return;
                        }
       
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error(url, status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "get", url, true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                ajaxReq.send();    
        }
        return;
    }
    
    handleEmailSubmit(e) {
        e.preventDefault();
        
        this.setState(
            {
                                    generalErrorText:   '',
                                    emailErrorText:     '',
                                    emailAddress:       '' 
            }
        )
        
        var allowSubmit = true;
        
        var email = ReactDOM.findDOMNode(this.refs.email).value.trim();
        if (email.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                                    emailErrorText:      "Email address required."
                }
            )
        } else {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email)) {
                allowSubmit = false;
                this.setState(
                    {
                                    emailErrorText:      "Invalid email format."
                    }
                )
            }
        }
        if (allowSubmit) {
            ReactDOM.findDOMNode(this.refs.emailSubmitButton).value = 'Processing...';
            
            var formData = new FormData();
                formData.append("email",           email);
                formData.append("activationKey",   this.props.childProps.activationKey);
        
            var ajaxReq =             new XMLHttpRequest();
                //ajaxReq.addEventListener(
                //      "progress", this.updateProgress or serve some wait msg, false
                //);
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        if ( responseJSON["msg"] == "success" ) { 
                            // we just sent them an email with confirmation link
                            // now send them message to check email
                            // they are still not active, and have activation_key intact
                            window.location.href = "/registration_complete";
                            return;

                        } else {    // "New user", now get username
                            this.setState( 
                                {
                                    emailErrorText: responseJSON["msg"]
                                }
                            );
                            ReactDOM.findDOMNode(this.refs.emailSubmitButton).value = 'submit';
                            return;
                        }
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/handle_twitter_email/", status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/handle_twitter_email/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                ajaxReq.send(formData);
        }
    }
        
    handleUsernameSubmit(e) {
        e.preventDefault();
    
        this.setState(
            {
                                errorText:          '',
                                generalErrorText:   ''
            }
        )
    
        // first do a local check, to save request
        allowSubmit = ( this.checkUsernameAvailabilityPreRequest() == "OK" );
    
        if (allowSubmit) {
            
            ReactDOM.findDOMNode(this.refs.usernameSubmitButton).value = 'Processing...';
            
            var formData = new FormData();
                formData.append("username",         ReactDOM.findDOMNode(this.refs.username).value.trim());
                formData.append("activationKey",    this.props.childProps.activationKey);
            
        
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        if ( responseJSON["msg"] == "success" ) { 
                            location.href = '/thank_you'
                            return;

                        } else {
                            this.setState( 
                                {
                                    generalErrorText:      responseJSON["msg"]
                                }
                            );
                            ReactDOM.findDOMNode(this.refs.usernameSubmitButton).value = 'submit';
                        }
               
                    }.bind(this), 
                    false                    
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/save_username/", status, err.toString());
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/save_username/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
                ajaxReq.send(formData);
        }
    
        return;
    }

    render() {
        var generalErrorDOM =   null;
        var emailErrorDOM =     null;
        var usernameErrorDOM =  null;
        var usernameValidDOM =  null;
        
        if (this.state.emailErrorText.length > 0) {
            emailErrorDOM =     <div className="errors">{ this.state.emailErrorText }</div>
        }
        if (this.state.generalErrorText.length > 0) {
            generalErrorDOM =   <div className="errors">{ this.state.generalErrorText }</div>
        }
        if (this.state.usernameErrorText.length > 0) {
            usernameErrorDOM =  <div className="errors">{ this.state.usernameErrorText }</div>
        }
        if (this.state.usernameValidText.length > 0) {
            usernameValidDOM =  <div className="valid">{ this.state.usernameValidText }</div>
        }

        
        if (this.state.needEmail == "Yes") {
            formDOM =   <form onSubmit={ this.handleEmailSubmit }>
                            <input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />
                            <div>
                                <div style={{"textAlign":"center"}}>
                                    Please provide your email address:
                                </div>

                                    { emailErrorDOM }
                                
                                <div>
                                    <input  ref="email" 
                                            className="form_input" 
                                            type="email" 
                                    />     
                                </div>
                                <div        style={{"fontSize":"10px","textAlign":"center", "marginBottom":"10px"}}>
                                    ( Email addresses are not shared without your authorization)
                                </div>
                                    { generalErrorDOM }
                                
                                <div style={{"textAlign":"center"}}>
                                    <input  ref="emailSubmitButton" 
                                            type="button" value="submit" 
                                            className="formButton" 
                                            style={{"marginTop":"10px"}} 
                                            onClick={ this.handleEmailSubmit } 
                                    />
                                </div>
                            </div>
                        </form>
        } else {
            formDOM =   <form onSubmit={ this.handleUsernameSubmit }>
                            <input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />
                            <div>
                                <div    className="profile_field">
                                    <div    className="inline_block"><label>Please select a Username:</label> { usernameValidDOM }</div>
                                    <div>
                                        { usernameErrorDOM }
                                        <input  ref="username" 
                                                className="form_input" 
                                                type="text"  
                                                style={{"width":"216px"}}  
                                        />
                                        <input  type="button" 
                                                defaultValue="check availability" 
                                                style={{"width":"120px", "textAlign":"center", "marginLeft":"6px"}} 
                                                onClick={ this.checkUsernameAvailability } 
                                        />
                                    </div>
                                </div>
                    
                                <div>
                                  <div style={{"textAlign":"center"}}>
                                    <input  ref="usernameSubmitButton" 
                                            type="button" 
                                            value="submit" 
                                            className="formButton" 
                                            style={{"marginTop":"10px"}} 
                                            onClick={ this.handleUsernameSubmit } 
                                    />
                                  </div>
                                </div>
                                { generalErrorDOM }
                            </div>
                        </form>
        }

        return  (
            <div className="choose_username_div">
                
                    { formDOM }
                
            </div>
        );
    }
}


export default Activate;