require("../styles/_PostContainer.scss");
import CommentsContainer    from "./CommentsContainer.jsx";
import VideoContainer       from "./VideoContainer.jsx";
import PostHeader           from "./PostHeader.jsx";
import PostCaption          from "./PostCaption.jsx";

class PostContainer extends React.Component {
    // These Comments functions are up here because
    // they are used by both PostCaption and CommentsContainer
    // and we keep comments in state, for updating without refresh
    
    constructor(props) {
        super(props);
        
        var viewCommentsText =  (this.props.singlePostJSON.hasComments == 1)
                            ? "view " + this.props.singlePostJSON.hasComments + " comment..."
                            : "view " + this.props.singlePostJSON.hasComments + " comments...";
                            
        this.state = {
            hasComments:            this.props.singlePostJSON.hasComments,
            fetchedCommentsJSON:    [],
            addedCommentsJSON:      [],
            combinedCommentsJSON:   [],
            commentsCursor:         'None',
            commentsMore:           null,
            viewCommentsText:       viewCommentsText,
            error:                  null,
            reported:               false
        };
        
        this.busyFetching           = false;
        this.editComment            = this.editComment.bind(this);
        this.addComment             = this.addComment.bind(this);
        this.deleteComment          = this.deleteComment.bind(this);
        this.fetchMoreComments      = this.fetchMoreComments.bind(this);
        this.reported               = this.reported.bind(this);
    }
    
  
    editComment(commentID, newText) {

        var formData = new FormData();
            formData.append("text",         newText);
            formData.append("commentID",    commentID);
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if (responseJSON["msg"] == "success") { 

                        var combinedCommentsJSON =        this.state.combinedCommentsJSON;
        
                        for (var index in combinedCommentsJSON) {
                            if ( combinedCommentsJSON[index]["commentID"] == commentID ) {
                                combinedCommentsJSON[index]["commentText"] = newText;
                                this.setState( {
                                    combinedCommentsJSON:          combinedCommentsJSON
                                });
                                break;
                            }
                        }
                    } else {
                        this.setState( 
                            {
                                error:      responseJSON["msg"]
                            }
                        );
                    }
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/edit_comment/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/edit_comment/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
            ajaxReq.send(formData);
    }
    
    addComment(text) {
        var currentUser =   this.props.globalProps.currentUser;

        var formData = new FormData();
            formData.append("text",         text);
            formData.append("postID",       this.props.singlePostJSON.postID);

        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
          
                    if ( responseJSON["msg"] == "success" ) {  
                        
                        var addedComments = this.state.addedCommentsJSON;
                        var newComment = {   // pass as JSON object
                                            "postID":               this.props.singlePostJSON.postID,
                                            "commentID":            responseJSON["commentID"],
                                            "commenterID":          currentUser.userID,
                                            "commenterUsername":    currentUser.username,   
                                            "commenterName":        currentUser.fullName,
                                            "commentText":          text,
                                            "dateEpoch":            'tbd'
                                         };
                        addedComments.push(newComment);
                                            
                        // whenever add a comment, add it to visible.
                        // but after fetch of last comment (more=false), only show fetched comments   
                        var fetchedComments = this.state.fetchedCommentsJSON;
                        var combinedComments = fetchedComments.concat(addedComments)
    
                        this.setState(
                            {
                                addedCommentsJSON:      addedComments,
                                combinedCommentsJSON:   combinedComments
                            }
                        );
                        
                    } else {
                        console.error("error adding comment: " + responseJSON["commentID"]);
                    }
                    return;
                   
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/add_comment/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/add_comment/", true );
            ajaxReq.setRequestHeader("X-CSRF-Token", this.props.globalProps.csrfToken);
            //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
            ajaxReq.send(formData);  
    }
    
    deleteComment(commentID) { 

        var formData = new FormData();
            formData.append("commentID", commentID);  
            
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    
                    if ( responseJSON["msg"] == "success" ) { 
                        var fetchedCommentsJSON =         this.state.fetchedCommentsJSON;
                        var combinedCommentsJSON =        this.state.combinedCommentsJSON;
                        
                        for (var index in fetchedCommentsJSON) {
                            if ( fetchedCommentsJSON[index]["commentID"] == commentID ) {
                                fetchedCommentsJSON.splice(index, 1);
                                this.setState( {
                                    fetchedCommentsJSON:          fetchedCommentsJSON
                                });
                                break;
                            }
                        }
                        for (var index in combinedCommentsJSON) {
                            if ( combinedCommentsJSON[index]["commentID"] == commentID ) {
                                combinedCommentsJSON.splice(index, 1);
                                this.setState( {
                                    combinedCommentsJSON:          combinedCommentsJSON
                                });
                                break;
                            }
                        }
                    } else {
                        this.setState( 
                            {
                                error:          responseJSON["msg"]
                            }
                        );
                    }  
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/delete_comment/", status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", "/delete_comment/", true );
                ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
            ajaxReq.send(formData);  
    }
    
    fetchMoreComments() {
        if (this.busyFetching) {
            return;
        }
        this.busyFetching = true;
        
        if (this.state.commentsMore) {
            this.setState( {
                viewCommentsText:      "loading..."          
            });
        }
        
        var formData = new FormData();
            formData.append("postID",       this.props.singlePostJSON.postID );
            
        var url =   "/get_comments/?cursor=" + this.state.commentsCursor;
        
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =  JSON.parse(ajaxReq.responseText);
        
                    if ( responseJSON["msg"] == "success" ) { 
                        var newComments =       responseJSON["json"];
                        
                        var previouslyFetchedComments =  this.state.fetchedCommentsJSON;
            
                        var fetchedComments = previouslyFetchedComments.concat(newComments);

                        this.setState( {
                            //commentsJSON:         newCommentsJSON,
                            fetchedCommentsJSON:    fetchedComments, 
                            commentsMore:           responseJSON["more"],
                            commentsCursor:         responseJSON["nextCursor"]     
                        });
                        
                        // whenever add a comment, add it to visible.
                        // but after fetch of last comment (more=false), only show fetched comments
                        if (responseJSON["more"]) {
                            var combinedCommentsJSON = fetchedComments.concat(this.state.addedCommentsJSON)
                            this.setState({
                                viewCommentsText:       "view more comments",
                                combinedCommentsJSON:   combinedCommentsJSON
                            });
                        } else {
                            var combinedCommentsJSON= this.state.fetchedCommentsJSON;
                            this.setState({
                                hasComments:            false,
                                combinedCommentsJSON:   combinedCommentsJSON
                            });
                        }
                
                        this.busyFetching = false;
                        return;

                    } else {
                        // give user msg
                        this.setState( {
                            errorMsg: responseJSON["msg"]
                        });
                    }
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error( url, status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "post", url, true );
            ajaxReq.setRequestHeader("X-CSRFToken", csrfToken);
            ajaxReq.send(formData);  
    }
    
    reported() {
        this.setState({
            reported:            true
        });
    }
    
    
  render() {
      var singlePostJSON =              this.props.singlePostJSON;
      
      if (this.state.reported) {
          return (
              <div className="postContainer">
                  <div className="caption2">
                      Thank you for reporting.  We will investigate.
                  </div>
              </div>
          );
      }
      
      return (
          <div className="stack">
            <PostHeader 
              singlePostJSON =          { singlePostJSON }
              globalProps =             { this.props.globalProps }
              currentUser =             { this.props.globalProps.currentUser }
            />
            <VideoContainer 
              singlePostJSON =          { singlePostJSON }
              globalProps =             { this.props.globalProps }
              index =                   { this.props.index }
            />
            { this.state.error }                 
            <PostCaption 
                singlePostJSON =        { singlePostJSON }
                addComment =            { this.addComment }
                addPost =               { this.props.addPost }
                editPost =              { this.props.editPost }
                deletePost =            { this.props.deletePost }
                globalProps =           { this.props.globalProps }
                childProps =            { this.props.childProps }
                currentUser =           { this.props.globalProps.currentUser }
                reported =              { this.reported }
            />
                                        
            <CommentsContainer 
                postID =                { singlePostJSON.postID }
                hasComments =           { this.state.hasComments }
                combinedCommentsJSON =  { this.state.combinedCommentsJSON }
                commentsCursor =        { this.state.commentsCursor }
                commentsMore =          { this.state.commentsMore }
                viewCommentsText =      { this.state.viewCommentsText }
                addComment =            { this.addComment }
                editComment =           { this.editComment }
                deleteComment =         { this.deleteComment }
                fetchMoreComments =     { this.fetchMoreComments }
                globalProps =           { this.props.globalProps }
                currentUser =           { this.props.globalProps.currentUser }
            />
          </div>
      )
  }
}

export default PostContainer;