                    	require("../styles/_Common.scss");
                    	require("../styles/_PostContainer.scss");
                   	 	require("../styles/_VideoContainer.scss");
import VideoContainer  from "./VideoContainer.jsx";



class Preview extends React.Component { 
    
    constructor(props) {
        super(props);
                            
        this.state = {
            videoJSON: { "video": {"duration": "4", "width": "512", "height": "512"}, "postID": "6253835978801152", "preview": true, "previewLoadVid": false }
        };

        this.switchVideo    = this.switchVideo.bind(this);
    }
	
	componentDidMount() {
		this.switchVideo(0, false);				
	}
	
	switchVideo(index, preload) {

		var postIDArraySplit = this.props.childProps.postIDArray.split(" | ");
		var json = postIDArraySplit[index];
		var postJSON = JSON.parse(json);
		
		var postID = postJSON["postID"];
		var duration = postJSON["duration"];
		var width = postJSON["width"];
		var height = postJSON["height"];

	    var buttonWrapper1 = document.getElementById("customVideoToggleButtons");
		
		var buttonChildren1 = buttonWrapper1.children;
		for (var i = 0; i < buttonChildren1.length; i++) {
			buttonChildren1[i].style.backgroundColor = "rgba(0,0,0,1)";
			buttonChildren1[i].style.color = "#aaa";
			buttonChildren1[i].style.border = "1px solid #aaa";
			buttonChildren1[i].disabled = false;
		}

	    var buttonWrapper2 = document.getElementById("standardVideoToggleButtons");
		
		var buttonChildren2 = buttonWrapper2.children;
		for (var i = 0; i < buttonChildren2.length; i++) {
			buttonChildren2[i].style.backgroundColor = "rgba(0,0,0,1)";
			buttonChildren2[i].style.color = "#aaa";
			buttonChildren2[i].style.border = "1px solid #aaa";
			buttonChildren2[i].disabled = false;
		}

		var buttonWithID = document.getElementById("button_" + postID);
		buttonWithID.style.backgroundColor = "rgba(218,165,32,1)";
		buttonWithID.style.color = "#fff";
		buttonWithID.style.border = "1px solid rgba(218,165,32,1)";	
		buttonWithID.disabled = true;
		
		
        this.setState( {
            videoJSON: { "video": {"duration": duration, "width": width, "height": height}, "postID": postID, "preview": true, "previewLoadVid": preload }        
        });
			
	}
	
	
    render() {
        
        var preview_id =            this.props.childProps.preview_id;
        var path =                  this.props.childProps.path;
        var title =                 this.props.childProps.title;
        var firstText =             this.props.childProps.firstText;
        var endText =               this.props.childProps.endText;
        var postIDArray =       	this.props.childProps.postIDArray.split(" | ");
		var customVid = 			false;
		
		var customPostButtonsDOM = postIDArray.map(
			function(json, index) {
				var postJSON = JSON.parse(json);
				var postID = postJSON["postID"];
				
				if ( postJSON["customVid"] == "true" ) {
					customVid = true;
				} else if ( postJSON["customVid"] != "noCopyright" ) {
					return
				}
				
				var fontSize = 100;
				if (isMobile) {
					fontSize = 130
				}

				if (index == 0 ) {
					return (
						
						<button id = { "button_" + postID } key={ "buttonKey_" + index + "." + postID } onClick={this.switchVideo.bind(this, index, true)} style={{"display":"inline-block","fontSize":fontSize + "%","backgroundColor":"rgba(218,165,32,1)","border":"1px solid rgba(218,165,32,1)","padding":"5px 12px","margin":"0 5px 5px 5px","color":"#fff"}}>
							{ postJSON["postName"] }
						</button>
					);
				} else {

					return (
						<button id = { "button_" + postID } key={ "buttonKey_" + index + "." + postID } onClick={this.switchVideo.bind(this, index, true)} style={{"display":"inline-block","fontSize":fontSize + "%","backgroundColor":"rgba(0,0,0,1)","border":"1px solid #aaa","padding":"5px 12px","margin":"0 5px 5px 5px","color":"#aaa"}}>
							{ postJSON["postName"] }
						</button>
					);
				}	
			}.bind(this)
		);
		
		var standardPostButtonsDOM = postIDArray.map(
			function(json, index) {
				var postJSON = JSON.parse(json);
				var postID = postJSON["postID"];
				
				if ( postJSON["customVid"] == "true" || postJSON["customVid"] == "noCopyright" ) {
					return
				}

				var fontSize = 100;
				if (isMobile) {
					fontSize = 130
				}

				if (index == 0 && !customVid ) {
					return (
						
						<button id = { "button_" + postID } key={ "buttonKey_" + index + "." + postID } onClick={this.switchVideo.bind(this, index, true)} style={{"display":"inline-block","fontSize":fontSize + "%","backgroundColor":"rgba(218,165,32,1)","border":"1px solid rgba(218,165,32,1)","padding":"5px 12px","margin":"0 5px 5px 5px","color":"#fff"}}>
							{ postJSON["postName"] }
						</button>
					);
				} else {

					return (
						<button id = { "button_" + postID } key={ "buttonKey_" + index + "." + postID } onClick={this.switchVideo.bind(this, index, true)} style={{"display":"inline-block","fontSize":fontSize + "%","backgroundColor":"rgba(0,0,0,1)","border":"1px solid #aaa","padding":"5px 12px","margin":"0 5px 5px 5px","color":"#aaa"}}>
							{ postJSON["postName"] }
						</button>
					);
				}	
			}.bind(this)
		);

		var pressReleaseLinkDOM = null;
		
		if ( path == "press" ) {
			pressReleaseLinkDOM = <a style={{"textDecoration":"underline"}} href="https://www.freezify.com/press_release">View our Press Release here.</a>;
		}
		var copyrightDOM = null;
		var viewVidTextDOM = <div style={{"margin":"10px 0"}}>
								Freezify is an iOS app, and a platform to create &amp; share interactive photography.  Essentially, you control videos by tilting your phone:
							 </div>;

		if ( customVid ) {
			copyrightDOM = <div style={{"width":"100%"}}>
								<div style={{"margin":"10px 0"}}>
									(I hope you don't mind that we used some of your content for the demo above.  We respect your copyright, and that content is only viewable on this private link.  We just want to show you why we think freezify is the best platform for your footage.)
								</div>
								<div style={{"margin":"10px 0"}}>
									If  you have any questions at all, email me (morgan@freezify.com).
								</div>
						   </div>;
		
			viewVidTextDOM = <div style={{"width":"100%"}}>
								<div style={{"margin":"10px 0"}}>
									Freezify is an iOS app, and a platform to create &amp; share interactive photography.  Essentially, you control videos by tilting your phone.
								 </div>
								 <div style={{"margin":"10px 0"}}>
									Your content would be awesome on freezify:
								 </div>
							 </div>;
		}
		
		var gifLinks = [{"name":"balloon","postID":"6130005"},{"name":"splash","postID":"6261261440385024"},{"name":"marin","postID":"5692526434451456"},{"name":"timelapseDark","postID":"1310007"},{"name":"geese","postID":"6330001"},{"name":"golf","postID":"5704147139559424"}];
		
		var mediaGifs = gifLinks.map(
                                        function (dict, index) {
										  var name = dict["name"];
										  var postID = dict["postID"]; 
										  
                                          return (
                                              <div    style = {{"width":"200px","padding":"10px 15px","display":"inline-block"}}
                                                      key = {`div_${index}`}
                                              >
												  <video webkit-playsinline="true" webkit-playsinline="true" webkitplaysinline="true"
	                                                      className =       "previewViewer"
	                                                      type =            "video/mp4"
	                                                      src =             {`${this.props.globalProps.GCS_VIDEO_PATH}preview/generic/gifs/${name}.mp4`}
	                                                      autoPlay =        "true"
	                                                      loop =            "true"
	                                                      key =             {`${postID}_${index}`}
                                                          muted = {true}
	                                              />
												  <a href={`${this.props.globalProps.GCS_VIDEO_PATH}preview/generic/gifs/${name}.mp4`} target="_blank">mp4</a> &nbsp;&nbsp;|&nbsp;&nbsp; <a href={`${this.props.globalProps.GCS_VIDEO_PATH}preview/generic/gifs/${name}.gif`} target="_blank">gif</a> &nbsp;&nbsp;|&nbsp;&nbsp; <a href={`https://www.freezify.com/i/${postID}`} target="_blank">link</a>
                                              </div> 
                                          );
                                      }.bind(this))
		
		var mediaDOM = null;
		if (!isMobile) {
			mediaDOM = <div style={{"width":"100%"}}>
							<div style={{"margin":"15px 0","height":"1px","width":"100%","backgroundColor":"#666"}}></div>
				
							<div style={{"width":"100%","fontSize":"20px","fontWeight":"200","padding":"10px 0"}}>
								Media
							</div>
							<div style={{"width":"100%"}}>
								<image
							            alt =     "freezify logo"
									  id = 	    "logo"
							            src =     "https://www.freezify.com/static/img/freezifyLogo-200.png"
									  style =	{{"width":"200px","height":"100px"}}
									/>
							</div>
							<div style={{"width":"100%"}}>									  
								<a href="https://www.freezify.com/static/img/freezifyLogo-200.png" target="_blank">200px</a> &nbsp;&nbsp;|&nbsp;&nbsp;	<a href="https://www.freezify.com/static/img/freezifyLogo-450.png" target="_blank">450px</a>  &nbsp;&nbsp;|&nbsp;&nbsp; <a href="https://www.freezify.com/static/img/freezifyLogo-1024.png" target="_blank">1024px</a>								  
							</div>
							<div style={{"width":"100%"}}>
								<image
				                      alt =     "freezify icon"
									  id = 	    "icon"
				                      src =     "https://www.freezify.com/static/img/freezifyIcon-100.png"
									  style =	{{"width":"100px","height":"100px"}}
						  		/>
							</div>
							<div style={{"width":"100%","marginBottom":"25px"}}>
								<a href="https://www.freezify.com/static/img/freezifyIcon-100.png" target="_blank">100px</a> &nbsp;&nbsp;|&nbsp;&nbsp;	<a href="https://www.freezify.com/static/img/freezifyIcon-200.png" target="_blank">200px</a> &nbsp;&nbsp;|&nbsp;&nbsp;	<a href="https://www.freezify.com/static/img/freezifyIcon-450.png" target="_blank">450px</a>  &nbsp;&nbsp;|&nbsp;&nbsp; <a href="https://www.freezify.com/static/img/freezifyIcon-1024.png" target="_blank">1024px</a>								  
							</div>
						
							<div style={{"width":"150%","left":"-25%"}}>
								{mediaGifs}
							</div>
						</div>;
		}
		
		var tiltImg = <image
	                      alt =     "tilt to view"
						  id = 	  "tiltImg"
	                      src =     {`${this.props.globalProps.GCS_VIDEO_PATH}preview/generic/tiltToView.png`}
						  style =	  {{"position":"absolute","width":"110px","height":"144px","left":"0px","pointerEvents":"none","WebkitTransform":"translateZ(257px)","MozTransform":"translateZ(257px)","transform":"translateZ(257px)"}}
			  		  />;
		if (!isMobile) {
			tiltImg = <div id="tiltImg" style = {{"position":"absolute","width": "100%","left":"0px","bottom":"15%","color":"rgba(255,255,255,0.65)","fontSize": "20px","fontWeight":"600","textShadow": "1px 1px 3px rgba(0,0,0,0.65)","pointerEvents":"none","WebkitTransform":"translateZ(257px)","MozTransform":"translateZ(257px)","transform":"translateZ(257px)"}}>
						<div style={{"width":"100%"}}>
							mouse over to view
						</div>
						<div style={{"width":"100%","fontSize":"15px"}}>
							(or open on phone to experience with tilt)
						</div>
					  </div>;
		}
			
		this.switchVideo.bind(this, 0);
							
        return  (    
            <div className="centerThis">
 
				<div style={{"width":"100%","fontSize":"20px","fontWeight":"bold","margin":"25px 10px 10px 10px"}}>
                    { title }
                </div>
    
	            <div className="flex previewBlock">
	
					<div style={{"margin":"10px","width":"100%"}}>
	                    { firstText }
					</div>
					
					<div style={{"width":"100%"}}>
						<h2>What is freezify?</h2>
					</div>
					
					{ viewVidTextDOM }

					<div id = "videoWrapper" style={{"marginBottom":"20px"}}>
						{ tiltImg }
						
			            <VideoContainer 
			              singlePostJSON =          { this.state.videoJSON }
			              globalProps =             { this.props.globalProps }
						  index = 			  	  	{ 0 }
			            />
						
   				    </div>
	                					
					<div id="customVideoToggleButtons" style={{"marginTop":"5px"}}>
						{ customPostButtonsDOM }
					</div>
					<div id="standardVideoToggleButtons" style={{"margin":"5px 0 15px 0"}}>
						{ standardPostButtonsDOM }
					</div>

					<div style={{"margin":"10px","width":"100%"}}>
						You control the speed.  You control the direction.  All simply by tilting your phone.  
					</div>
					<div style={{"margin":"0px","width":"100%"}}>
						{ copyrightDOM }
					</div>
	                <div style={{"margin":"10px","width":"100%"}}>
	                    { endText }
	                </div>
	                <div style={{"margin":"10px","width":"100%"}}>
						{ pressReleaseLinkDOM }
	                </div>

					<div style={{"margin":"15px 0","height":"1px","width":"100%","backgroundColor":"#666"}}></div>
		
					<div style={{"width":"100%","fontSize":"20px","fontWeight":"200","margin":"10px"}}>
						Download now
					</div>
					<div style={{"marginTop":"10px","width":"100%"}}>
						Freezify is available in the Apple App Store now @ 
					</div>
					<div style={{"marginBottom":"10px"}}>
						<a href="http://appstore.com/freezify" target="_blank">http://appstore.com/freezify</a>  
					</div>
				
					<div style={{"margin":"15px 0","height":"1px","width":"100%","backgroundColor":"#666"}}></div>
		
					<div style={{"width":"100%","fontSize":"20px","fontWeight":"200","margin":"10px 0"}}>
						Features
					</div>
						
					<div style={{"width":"100%"}}>
						Freezify is great for:
					</div>
					<div style={{"marginBottom":"10px"}}>
						slow motion | time-lapses | 3D selfies | panoramas | light field simulation
					</div>
						
					<div style={{"width":"100%","margin":"10px","padding":"0 15%","textAlign":"center"}}>						
							<div style={{"width":"100%"}}>• Use any video on your phone.</div>
							<div style={{"width":"100%"}}>• Take and edit videos in the app.</div>
							<div style={{"width":"100%"}}>• Follow your favorite accounts.</div>
							<div style={{"width":"100%"}}>• Share to your followers.</div>						
							<div style={{"width":"100%"}}>• Videos are 120 frames.</div>
					</div>
						
					<div style={{"margin":"10px"}}>
							<div style={{"width":"100%"}}>Plus, every video on freezify is VR-ready!</div>
							<div style={{"width":"100%"}}>This makes freezify the easiest way for anyone to create and experience VR.</div>
					</div>
					
					<div style={{"width":"100%","margin":"30px 0"}}>
						<image
		                    alt =     "vr gif"
		                    src =     {`${this.props.globalProps.GCS_VIDEO_PATH}preview/generic/vr-ready.gif`}
							style =	  {{"width":"281px","height":"200px"}}
		                />
					</div>
					
                </div>
														
				<div className="flex previewBlock">
				    { mediaDOM }
				</div>
            </div>
        );
    }
}



export default Preview;
