import "../styles/_Common.scss";
import "../styles/_Comment.scss";
import "../styles/_Login.scss";

import ReactDOM from 'react-dom';

class LoginForm extends React.Component {
        
    constructor(props) {
        super(props);
        this.state = {
            errorText:              ''
        };
    

        this.handleSubmit       = this.handleSubmit.bind(this);
        this.oauthClickHandler  = this.oauthClickHandler.bind(this);
        
    }
    
    handleSubmit(e) {
        e.preventDefault();
        this.setState(
            {
                errorText:  ""
            }
        )
        
        var username =              ReactDOM.findDOMNode(this.refs.username).value;
        var password =              ReactDOM.findDOMNode(this.refs.password).value;
        
        var handleLogin =           this.props.globalProps.handleLogin;
        
        if (!username || !password) {
            this.setState(
                {
                    errorText:  "Both username and password required"
                }
            )
            return;
        }
        
        // not changing to JSON else mobile app would need to be changed
        var formData = new FormData();
            formData.append("username",     username);
            formData.append("password",     password);
        
        fetch('/ajax_login/', {
            method    : 'POST',
            headers   : {
                'Accept'        : 'application/json',
                'X-CSRFToken'   : csrfToken
            },
            mode      : 'same-origin',
            body      : formData
        })
        .then(
            function(response) {
                if (response.status !== 200) {
                    console.log('/ajax_login/ Status Code: ' + response.status);
                    return;
                }

                response.json().then(function(responseJSON) {
                    //console.error(responseJSON)
                    if ( responseJSON["msg"] == "success" ) { 
                        handleLogin(
                            {
                                username:       responseJSON["currentUserUsername"],
                                fullName:       responseJSON["currentUserFullName"],
                                userID:         responseJSON["currentUserID"],
                                https:          responseJSON["https"],
                                loggedInto:     responseJSON["loggedInto"],
                            }
                        );
                        if  ( (window.location.href.indexOf("/not_logged_in") != -1) ||
                              (window.location.href.indexOf("/reset_password_complete") != -1)
                            ) {
                            // don't keep them on not_logged_in page
                            window.location.href = "/";
                        } else {
                            this.props.hideForm();
                            return;
                        }

                        return;
                    } else {
                        this.setState( 
                            {
                                errorText: responseJSON["msg"]
                            }
                        );
                        return;
                    }
                }.bind(this));
            }.bind(this)
        )
        .catch(function(err) {
            console.log('/ajax_login/ Fetch Error :-S', err);
            return;
        });
        return;
    }
    
    oauthClickHandler(whichOauth) {
        window.location.href = "/oauth/" + whichOauth + "/login/?next=/";
    }

    render() {
        var oauthClickHandler = this.oauthClickHandler;
        var errorDOM =          null;
        
        if (this.state.errorText.length > 0) {
            errorDOM =          <div className="errors">{ this.state.errorText }</div>
        }

        return  (
            <div className="loginForm" key={ this.props.transitionKey }>
                <div style={{"marginTop": "32px"}}>
                    { this.props.msg }
                </div>
                { errorDOM }
                <div className="oauth_bar">
                    <div>sign in with:  
                        <div className="facebook_login" onClick={ function() {oauthClickHandler('facebook')} }></div>
                        <div className="google_login" onClick={ function() {oauthClickHandler('google')} }></div>
                        <div className="twitter_login" onClick={ function() {oauthClickHandler('twitter')} }></div>
                    </div>
                </div>
                <div className="login1">
                    <form 
                        className="add_comment" 
                        action="/" 
                        method="post" 
                        onSubmit={ this.handleSubmit }
                    >
                        <table className="loginTable">
                            <tbody>
                            <tr>
                        		<td align="left">username or email:<br /><input ref="username" style={{"width": "150px"}} type="text" autoFocus /></td>
                        		<td align="left">password:<br />
                                    <input ref="password" style={{"width": "150px"}} type="password" /> &nbsp; 
                                        <a href="/reset_password" style={{"fontSize": "9px"}}>reset password</a>
                                </td>
                        	</tr>
                            </tbody>
                        </table>
                        <div className="flex" style={{"marginTop": "18px"}}>
                            <input type="submit" value="submit" className="l_c_button_off _black" />
                            <div className="edit_cancel tappable" onClick={ this.props.hideForm }> cancel</div>
                        </div>
                        <div className="flex" style={{"marginTop": "14px"}}>
                            <span><a href="/register">register</a>&nbsp; to use freezify.com</span>
                        </div>
                    </form>
                </div>
           </div>
        );
    }
}


export default LoginForm;
