import "../styles/_Common.scss";

class Message extends React.Component {
    
  render() {
      // React is safe, and encodes <> to prevent XSS injections
      // so, we put separate paragraphs in a list
      var textAlign =               this.props.childProps.textAlign;
      var msgParagraphs =           this.props.childProps.msg.map(
          function (paragraph, i) {
            return (
                <div style={{"margin":"10px"}} key={`msg_${i}`}>
                    { paragraph }
                </div>
            );
      }.bind(this));

      return (
          <div className="choose_username_div" style={{"textAlign":textAlign}}>
              { msgParagraphs }
          </div>
      )            
  }
}


export default Message;
