import "../styles/_Comment.scss"
import CommentDisplay from "./CommentDisplay.jsx";

import { CSSTransition } from 'react-transition-group';
 
class CommentsContainer extends React.Component {
    render() {
        var hasCommentsDOM =     (this.props.hasComments)
                                  ? <div className="flex" key="transitionKey">
                                        <div  className="l_c_button_off _black flex" 
                                              style={{
                                                  "padding": "1px 22px 1px 26px",
                                                  "fontSize": "10px"
                                              }} 
                                              onClick={this.props.fetchMoreComments}
                                        >
                                              { this.props.viewCommentsText }
                                        </div>
                                    </div>
                                  : null;

        if (this.props.combinedCommentsJSON.length > 0) {
            var commentNodes =    this.props.combinedCommentsJSON.map(
                function (singleCommentJSON) {
                    return (
                        <CommentDisplay 
                            singleCommentJSON   = { singleCommentJSON }
                            key                 = { singleCommentJSON.commentID }
                            globalProps         = { this.props.globalProps }
                            currentUser         = { this.props.globalProps.currentUser }
                            addComment          = { this.props.addComment }
                            editComment         = { this.props.editComment }
                            deleteComment       = { this.props.deleteComment }
                        />
                    );
                }.bind(this)
            );
        }
      
        return (
          
            <div className="commentsContainer">
                <CSSTransition in={false} timeout={200} classNames="easeTransition">
                    <div>
                        { commentNodes }
                        { hasCommentsDOM }
                    </div>
                </CSSTransition>
            </div>
              
        )
    }
}

export default CommentsContainer;