import "../styles/_Comment.scss";

import ReactDOM from 'react-dom';

class CommentForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            errorText:            '',
            userFinderText:       null,
            userFinderStyle:      { "display": "none" }
        };
        
        this.handleSubmit   = this.handleSubmit.bind(this);
        this.insertUser     = this.insertUser.bind(this);
        this.findUser       = this.findUser.bind(this);
        this.parseUsers     = this.parseUsers.bind(this);
    }


    handleSubmit(e) {
        e.preventDefault();
        var text =              ReactDOM.findDOMNode(this.refs.text).value;
        
        if (!text) {
            this.setState(
                {
                                errorText: "Forget something?"
                }
            )
            return;
        }
        
        this.props.hideForm();
        
        if (this.props.editCommentID != undefined) {   // we only pass these if editing comment
            this.props.editComment(this.props.editCommentID, text);
        } else {
            this.props.addComment(text);
        }
        
        
        
        return;
      }
    
    insertUser(username) {
        var thisTextDOM =           ReactDOM.findDOMNode(this.refs.text)
        var thisTextDOMValue =      thisTextDOM.value;
        var newText =               thisTextDOMValue.slice(0,thisTextDOMValue.lastIndexOf("@")+1) + username;
        thisTextDOM.value =         newText;
        this.setState(
            {
                                    userFinderText:     null,
                                    userFinderStyle:    { "display": "none" }
            }
        );
    }
                
    findUser() {
        var thisText =              ReactDOM.findDOMNode(this.refs.text).value;
        var words =                 thisText.split(" ");
        var lastWord =              words[words.length-1];
        var insertUser =            this.insertUser;
        //var userFinderDiv =         ReactDOM.findDOMNode(this.refs.userFinderDiv);
        var userFinderText =        '';
        var followingJSON =         this.props.globalProps.currentUser.followingJSON;
        var gcsVideoPath =          this.props.globalProps.GCS_VIDEO_PATH;

        if ((lastWord[0] == "@") && (followingJSON.length > 0)) {
            var userFinderText = followingJSON.map(
                function (member) {
                    if ( (member.username.indexOf(lastWord.slice(1)) == 0) || (lastWord.length == 1) ) {
                        return (
                            <div    className="tappable"
                                    onClick={ function(){ 
                                         insertUser( member.username ) 
                                        } 
                                    }
                            > 
                                <img    src={`${gcsVideoPath}a/${ member.followingID }_av_sm`} 
                                        className="avatar_sm margin4px" alt={ `${ member.followingUsername } avatar` }/>{ member.followingUsername } - { member.followingName } 
                            </div>
                        );
                    }
                }
            );
            
            // remove empty items from mapped list
            userFinderText = userFinderText.filter(function(e){return e});
             
            if (userFinderText.length > 0) {
                this.setState(
                    {
                                    userFinderStyle: { "display": "block" },
                                    userFinderText: userFinderText
                    }
                );
            } else {
                this.setState(
                    {
                                    userFinderStyle:    { "display": "none" }
                    }
                );
            }
            
        } else {
            this.setState(
                {
                                    userFinderStyle:    { "display": "none" }
                }
            );
        }     
    }
    
    parseUsers(listOfTexts) {
        var userList = [];
        
        for (var counter in listOfTexts) {
            if (listOfTexts[counter] != undefined ) {
                var words = listOfTexts[counter].toLowerCase().split(" ")
                for(var i=0; i<words.length; i++) {
                    //console.log(words[i], words[i].indexOf("@"))
                    if (words[i].indexOf("@") == 0) {
                        userList.push(words[i].replace(/[^\w-]/g, '')); // alphanumeric, dash, underscore only. Strips punctuation and @
                    }
                }
            }
        }    
        return userList;
    }

    render() {     
        var singleCommentJSON = this.props.singleCommentJSON;
        var currentUser =       this.props.globalProps.currentUser;
        
        if ( this.props.editCommentID != undefined ) {     // editing comment
            var initText =      this.props.editText;
        } else {
            /////////////////////////////////////////////////////
            //  ******  Pre-populate comment with users mentioned
            /////////////////////////////////////////////////////
        
            var usersMentioned =    this.parseUsers( [ singleCommentJSON.commentText, singleCommentJSON.caption  ]); // get users mentioned in original comment
            var thisIncludes =      singleCommentJSON.commenterUsername
                                        ? [ singleCommentJSON.commenterUsername ]
                                        : [];

            for (var i=0; i<usersMentioned.length; i++) {   // this makes uniques, with orig poster first
                if (thisIncludes.indexOf(usersMentioned[i]) == -1) {
                    thisIncludes.push(usersMentioned[i]); 
                }
            }

            var initText = '';

            for (var i=0; i<thisIncludes.length; i++) {
                initText += "@" + thisIncludes[i] + " ";
            }
            /////////////////////////////////////////////////////
            //  ******  end Pre-populate comment 
            /////////////////////////////////////////////////////
        }
        
        
        var errorDOM =          null;
        
        if (this.state.errorText.length > 0) {
            errorDOM =          <div className="errors">{ this.state.errorText }</div>
        }
        
        return  (
            <div className="commentForm">       
                <div>
                    <img src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ currentUser.userID }_av_sm`} 
                        alt="small avatar" className="avatar_sm" />
                    <span className="post_as"> comment as: 
                        &nbsp;<a href={`/profile/${ currentUser.username }`}>{ currentUser.fullName }</a> 
                        &nbsp; &#64;{ currentUser.username }
                    </span>        
                </div>
    
                <form 
                    className="add_comment" 
                    action="/" 
                    method="post" 
                    onSubmit={this.handleSubmit} 
                >
                    <div>
                       { errorDOM }
                        <textarea ref="text" 
                           className="commentFormField post_form_text" 
                           placeholder="comment" 
                           onKeyUp={ this.findUser }
                           defaultValue={ initText }
                           autoFocus >
                       </textarea>
                    </div>

                    <div className="flex">
                        <input type="submit" value="submit" className="formButton" />
                        <div className="edit_cancel tappable" onClick={ this.props.hideForm }> cancel</div>
                    </div>
                </form>
                <div    ref="userFinderDiv" 
                        className="userFinderDiv alignLeft"
                        style = { this.state.userFinderStyle }>
                   { this.state.userFinderText }
                </div>
           </div>
        );
    }
}

export default CommentForm;
