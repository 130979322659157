import "../styles/_Common.scss";
import "../styles/_Login.scss";
import LoginForm from "./LoginForm.jsx";

import ReactDOM from 'react-dom';

class Header extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showLogoutWarning:        false,
            showLoginForm:            false,
            showMessages:             false,
            messagesCount:            this.props.childProps.messagesCount,
            messages:                 [],
            numUnread:                0
        };
        

        this.oauthClickHandler          = this.oauthClickHandler.bind(this);
        this.oauthMouseEnter            = this.oauthMouseEnter.bind(this);
        this.oauthMouseLeave            = this.oauthMouseLeave.bind(this);
        this.handleLoginClick           = this.handleLoginClick.bind(this);
        this.handleLogoutClick          = this.handleLogoutClick.bind(this);
        this.doLogout                   = this.doLogout.bind(this);
        this.handleLogoutConfirmClick   = this.handleLogoutConfirmClick.bind(this);
        this.hideForm                   = this.hideForm.bind(this);
        this.toggleMessages             = this.toggleMessages.bind(this);
    }
    

    
    oauthClickHandler(whichOauth) {
        window.location.href = "/oauth/" + whichOauth + "/login/?next=/";
    }
    
    oauthMouseEnter(whichOauth) {
        ReactDOM.findDOMNode(this.refs[whichOauth + "_btn"]).className = whichOauth + "_login_mouseenter";
    }
    
    oauthMouseLeave(whichOauth) {
        ReactDOM.findDOMNode(this.refs[whichOauth + "_btn"]).className = whichOauth + "_login";
    }
    
    handleLoginClick() {
        this.setState(
            { 
                "showLoginForm":        true
            }
        );
    }
    
    handleLogoutClick(fromWhom) {
        
        if (["google", "facebook", "twitter"].indexOf(fromWhom) >= 0) {
            // we warn users that they may still be logged into their oauth accounts.
            this.setState(
                { 
                    "showLogoutWarning":        true
                }
            );
        } else {
            this.doLogout();
        }                     
    }
    
    doLogout() {
        // just send to /logout
        var ajaxReq =             new XMLHttpRequest();
        ajaxReq.addEventListener(
            "load", 
            function() {
                var responseJSON =          JSON.parse(ajaxReq.responseText);
                
                if (responseJSON["msg"] == "success") {
                    this.props.globalProps.handleLogout();
                } else {
                    console.error("failed to logout: " + responseJSON["msg"]);
                };
                return;

            }.bind(this), 
            false
        );
        ajaxReq.addEventListener(
            "error", 
            function(xhr, status, err) {
                console.error(url, status, err.toString());
            }.bind(this), 
            false
        );
        ajaxReq.open( "get", "/logout", true );
        ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
        //ajaxReq.setRequestHeader("Content-type", false);
        ajaxReq.send();
    }
    
    handleLogoutConfirmClick(whichAuth) {
        switch(whichAuth) {
            case "google_both":
                this.doLogout();
                window.location.href = "/oauth/google/logout_both/?next=/logged_out"
                break;
            case "facebook_both":
                this.doLogout();
                window.location.href = "/oauth/facebook/logout_both/?next=/logged_out" 
                break;
            default:
                this.doLogout();
        }
/*
        var ajaxReq =             new XMLHttpRequest();
            //ajaxReq.addEventListener(
            //      "progress", this.updateProgress or serve some wait msg, false
            //);
            ajaxReq.addEventListener(
                "load", 
                function() {
                    if (ajaxReq.responseText == "success") {
                        this.props.handleLogout();
                    } else {
                        console.error("failed to logout: " + ajaxReq.responseText);
                    };
                    return;

                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error(url, status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "get", url, true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send();
*/
    }
    
    hideForm() {
        this.setState(
            { 
                "showLoginForm":            false
            }
        );
    }
    
    toggleMessages() {
        if (!this.state.showMessages) {
            // go get messages, only if not yet showing
            
            var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                
                    if (responseJSON["msg"] == "success") {
                        this.setState(
                            { 
                                messages:       responseJSON["messages"],
                                numUnread:      responseJSON["numUnread"]
                            }
                        );
                    }
                    return;

                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error(url, status, err.toString());
                }.bind(this), 
                false
            );
            ajaxReq.open( "get", "/get_messages_json/" + this.props.globalProps.currentUser.userID, true );
            ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
            //ajaxReq.setRequestHeader("Content-type", false);
            ajaxReq.send();
        }
        this.setState(
            { 
                showMessages:   !this.state.showMessages,
                messagesCount:      0
            }
        );
    }
    
  render() {
      var currentUser       = this.props.globalProps.currentUser;
      var messagesCount     = this.state.messagesCount;
      var loginDOM          = null;
      var logoutWarningDOM  = null;
      
      var loginPlacement    = {
                                    "top": "-126px",
                                    "right": "-88px"
                                }; 
      var textStyle         = {
                                    "textAlign": "center",
                                    "lineHeight": "29px"
                                };
      var textStyle2        = {
                                     "width":"100%",
                                     "textAlign":"right",
                                     "fontSize":"11px"
                                };
      if (isMobile) {
          loginPlacement =      {
                                    "top": "-126px",
                                    "right": "-12px"
                                };
          textStyle =           {
                                     "display": "block",
                                     "textAlign": "center",
                                     "lineHeight": "21px"
                                };
          textStyle2 =          {
                                     "textAlign":"right",
                                     "fontSize":"11px",
                                     "lineHeight":"20px", 
                                     "display":"block"
                                };
      }  
     
      
      if (this.state.showLogoutWarning) {
          switch(currentUser.loggedInto) {
              // Google and Facebook leave the user signed into their accounts (security issue), so we warn
              // LinkedIn does not log you into your account in the background, so just log out from our app
              case "google":
                  logoutWarningDOM = <div className="logout_warning">
                                        <div className="button" onClick={ function() {this.handleLogoutConfirmClick('google') }.bind(this)}>
                                            Log out of freezify, but remain logged into Google.
                                        </div>
                                        <div className="button" onClick={ function() {this.handleLogoutConfirmClick('google_both') }.bind(this)}>
                                            Log out of BOTH freezify and all Google accounts.
                                        </div>
                                    </div>
                  break;
              case "facebook":
                  logoutWarningDOM = <div className="logout_warning">
                                        <div className="button" onClick={ function() {this.handleLogoutConfirmClick('facebook') }.bind(this)}>
                                            Log out of freezify, but remain logged into Facebook.
                                        </div>
                                        <div className="button" onClick={ function() {this.handleLogoutConfirmClick('facebook_both') }.bind(this)}>
                                            Log out of freezify, then visit Facebook to log out.
                                        </div>
                                    </div>
                  break;
              case "twitter":
                  logoutWarningDOM = <div className="logout_warning">
                                        <div className="button" onClick={ function() {this.handleLogoutConfirmClick('twitter') }.bind(this)}>
                                            Click to log out of freezify, but remain logged into Twitter.
                                        </div>
                                    </div>
                  break;
              default:
                  logoutWarningDOM =    null;
          }
      }
      var messagesDOM = null;

      
      if (currentUser.username == "anonymous") {
          if (this.state.showLoginForm) {
              loginDOM =        <LoginForm 
                                    globalProps =           { this.props.globalProps }
                                    key =                   "login_form_main"
                                    hideForm =              { this.hideForm }
                                    msg =                   "Log in using Freezify credentials"
                                />
          } else {
              loginDOM =        <div id="header_login_container" style={loginPlacement}>
                                  <span style={textStyle}>sign in with:</span> 
                                  <span>
                                      <div    className="f_login" 
                                              onClick={ function()      {this.handleLoginClick() }.bind(this)} 
                                      />
                                      <div   className="facebook_login" 
                                              onClick={      function() {this.oauthClickHandler('facebook') }.bind(this)} 
                                              onMouseEnter={ function() {this.oauthMouseEnter('facebook') }.bind(this)} 
                                              onMouseLeave={ function() {this.oauthMouseLeave('facebook') }.bind(this)} 
                                              ref="facebook_btn" 
                                      />
                                      <div    className="google_login" 
                                              onClick={      function() {this.oauthClickHandler('google') }.bind(this)} 
                                              onMouseEnter={ function() {this.oauthMouseEnter('google') }.bind(this)}
                                              onMouseLeave={ function() {this.oauthMouseLeave('google') }.bind(this)} 
                                              ref="google_btn" 
                                      />
                                      <div    className="twitter_login"
                                              onClick={      function() {this.oauthClickHandler('twitter') }.bind(this)} 
                                              onMouseEnter={ function() {this.oauthMouseEnter('twitter') }.bind(this)}
                                              onMouseLeave={ function() {this.oauthMouseLeave('twitter') }.bind(this)} 
                                              ref="twitter_btn" 
                                      />
                                  </span>
                                </div>
          }
      } else {
          var messagesCountDOM = null;
          if (messagesCount != undefined) {
              messagesCountDOM = <div  className = "messages_circle"
                                      onClick = { this.toggleMessages }
                                >
                                  { messagesCount }
                                </div>
          }
          
          if (this.state.showMessages) {
              var messagesDOM = this.state.messages.map(
                  function (message, index) {
                
                      var keyJoin =       message[0].split('_');
                      var postNum =       keyJoin[0];
                      var actionType =    keyJoin[1];
                      var msgProps =      message[1]; // {'actions':{}, 'others':[]}
                      var usersText =     msgProps['actions'].map(
                                              function (action, i) {
                                            
                                                  if (i == 0) {
                                                      return (
                                                          <span>
                                                              <a href={`/profile/${action[1]}`}>
                                                                  {`@${action[1]}` }
                                                              </a>
                                                          </span>
                                                      );
                                                  } else if (i == msgProps['actions'].length - 1) {
                                                      return (
                                                          <span>{` and `}<a href={`/profile/${action[1]}`}>
                                                                            {`@${action[1]}` }
                                                                         </a>
                                                          </span>
                                                      );
                                                  } else {
                                                      return (
                                                          <span>{`, `}<a href={`/profile/${action[1]}`}>
                                                                          {`@${action[1]}` }
                                                                      </a>
                                                          </span>
                                                      );
                                                  }
                                              }
                                          );
                
                      var othersText =    (msgProps['others'] > 0)
                                              ? <span>{` and ${msgProps['others']} others `}</span>
                                              : " "
                      var lastText;
                      
                      switch (actionType) {
                          case "followed":
                              lastText = <span>followed you</span>;
                              break;
                          case "liked":
                              lastText = <span>{`liked your `}<a href={`/i/${postNum}`}>{`post`}</a></span>;
                              break;
                          case "commented":
                              lastText = <span>{`commented on your `}<a href={`/i/${postNum}`}>{`post`}</a></span>;
                              break;
                          case "mentioned&comment":
                              lastText = <span>{`mentioned you in a `}<a href={`/i/${postNum}`}>{`comment`}</a></span>;
                              break;
                          case "mentioned&post":
                              lastText = <span>{`mentioned you in a `}<a href={`/i/${postNum}`}>{`post`}</a></span>;
                              break;
                          default:
                              lastText = <span>{`commented on your `}<a href={`/i/${postNum}`}>{`post`}</a></span>;
                      }
                                        
                      var bgColor = (index < messagesCount) 
                                          ? "#333"
                                          : "#000";
                  
                      return (
                          <div key={`action_${message[0]}_${msgProps['actions'][0][2]}`} style={{"fontSize":"11px","width":"514px", "border":"1px solid grey", "padding":"5px", "backgroundColor":bgColor,"marginTop":"2px"}}>
                              {usersText}{othersText}{lastText}.
                          </div>
                      );
                
                  }
              );
          }
          
          var pulldownArrow =       (["google", "facebook"].indexOf(currentUser.loggedInto) >= 0) ? "▼" : null;
          var loginDOM =            <div id="header_login_container"  style={loginPlacement}>
                                    <span>
                                        <span>
                                          <img src={`${this.props.globalProps.GCS_VIDEO_PATH }a/${ currentUser.userID }_av_sm`} 
                                               alt="small avatar" 
                                               className="avatar_sm"
                                          />
                                        </span>
                                        <span className="v_middle">
                                        {` Hello, `}<a href={`/profile/${ currentUser.username }`}>{`${currentUser.username}.`}</a>
                                        </span>
                                          { messagesCountDOM }
                                    </span>
                                     
                                    <span   className="tappable v_middle" 
                                            onClick={ function() {this.handleLogoutClick(currentUser.loggedInto) }.bind(this)}
                                            style = {textStyle2}
                                    >
                                            &nbsp;&nbsp;log out { pulldownArrow }
                                            { logoutWarningDOM }
                                    </span>    
            
                                </div>
                                
      }

      return (
        <div>
              { loginDOM }
              { messagesDOM }
        </div>
      )
  }
}


export default Header;
