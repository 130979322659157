import "../styles/_VideoContainer.scss";

import ReactDOM from 'react-dom';

//React.initializeTouchEvents(true);

class VideoContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            numDeg              : 0,
            played              : false,
            touching            : false,
            scrubbing           : false,
            scrolling           : false, 
        };

        this.video              = null;
        this.setVideoParams     = this.setVideoParams.bind(this);
        this.resetOrigState     = this.resetOrigState.bind(this);
        this.playPauseVideo     = this.playPauseVideo.bind(this);
        this.handleTouchEnd     = this.handleTouchEnd.bind(this);
        this.handleTouchStart   = this.handleTouchStart.bind(this);
        this.handleTouchMove    = this.handleTouchMove.bind(this);
    }
    
    playPauseVideo() {
        if (!this.state.played) {
            this.refs.playArrow.style.display = "none";
            
            if (this.video !== null) {
                const playPromise = this.video.play();

                // In browsers that don’t yet support this functionality, playPromise won’t be defined.
                if (typeof playPromise !== "undefined") {
                    playPromise
                        .then(function() {
                            this.video.pause();
                        }.bind(this))
                        .catch(err => console.error("playPromise error: " + err));
                } else {
                    console.error("playPromise undefined!!!");
                    this.video.pause();
                }
            
                //this.video.controls = false; 
                this.setState(
                    {
                        played: true 
                    }
                );
            } else {
                console.error("video null!");   // just for testing
            }
        } 
    }
    
    componentDidMount() {
        this.video = this.refs.theVideo;
        //console.log(this.props.index );
        //this.video =    ReactDOM.findDOMNode(this.refs.theVideo);
        
        if (isMobile) {
            this.video.controls = false;
            
            
            // NOTE: this is to fix a bug is iOS8, where a video does not count as loaded (window.onload doesn't fire) 
            // with preload = "none".  This triggers window.onload so infinite scroll can work
            if (this.props.index == 0) {
                this.video.preload = "auto";
                //this.video.currentTime = Math.round( 1000 * this.props.singlePostJSON.video.duration * 0.5 )/1000;
                //this.video.play();
                //this.video.pause();
                //this.setVideoParams(e);
            }
            
            
            window.addEventListener('deviceorientation', function(event) {
                if (this.state.touching) {
                    return false; // touch overrides tilt
                }
                
                var vidPosition =       this.video.getBoundingClientRect();
                
                // only play visible videos
                if ( !( vidPosition.bottom < 0 || vidPosition.top > window.innerHeight) ) {

                    var rotAngle = -event.gamma;

                    if (Math.abs(window.orientation) === 90) {
                        rotAngle = event.beta * -2;  // beta +/-90, while gamma +/-180
                    } 

                    var center = 0;
                    if (rotAngle - center < -15) { center = rotAngle + 15; }
                    if (rotAngle - center >  15) { center = rotAngle - 15; }
                    var percentX = (rotAngle - center + 15) / 30;

                    this.video.currentTime = Math.round( 1000 * this.props.singlePostJSON.video.duration * percentX )/1000;

                    if (!this.state.played) {
                        this.playPauseVideo();
                    }
                    

                    // consider setting the style directly, instead of via state
                    // this handles the perspective of the video viewport. on mobile, just leave it rect.
                    //this.setState(
                    //    {
                    //        numDeg:         Math.floor( percentX * -60 + 30) 
                    //    }
                    //); 
                } 
            }.bind(this), false);
        }   // end if isMobile
    }
	
    componentDidUpdate(prevProps, prevState) {  

		if (this.props.singlePostJSON.preview == true ) {
	        this.setState( {
	            played: false
	        });
            this.refs.playArrow.style.display = "block";
			
            /*			if (this.props.singlePostJSON.previewLoadVid == true) {   // from Preview.jsx only
				console.log("gonna load");
				this.playPauseVideo();
			}			
                */
		}
    }
    
	/*
    UNSAFE_componentWillReceiveProps() {
		
		if (this.props.singlePostJSON.preview == true ) {
	        this.setState( {
	            played: false
	        });
            ReactDOM.findDOMNode(this.refs.playArrow).style.display = "block";
			
                //if (this.props.singlePostJSON.previewLoadVid == true) {   // from Preview.jsx only
				//console.log("gonna load");
				//this.playPauseVideo();
			//}			

		}
	}
    */
    
    setVideoParams(e) {
        
        var videoPos =  this.video.getBoundingClientRect();

        if (isMobile) {

            /*
            if (!this.state.played) {
                this.video.play();
                this.video.pause();
                this.setState(
                    {
                        played: true 
                    }
                );
            }
            this.video.controls = false;
            */
            //event.preventDefault();
            
                var touchX =    e.touches[0].clientX;
                var percentX =  rangeBound( (touchX - videoPos.left) / ( videoPos.right - videoPos.left), 0, 1);
            
        } else {    
            var percentX =      (e.pageX - videoPos.left) / ( videoPos.right - videoPos.left); 
            
            this.setState(
                {
                    numDeg:     Math.floor( percentX * 60 - 30) 
                }
            );    
        }

        if (!this.video.seeking) {
            this.video.currentTime = Math.round( 1000 * this.props.singlePostJSON.video.duration * percentX )/1000;
        } 

    }
    
    resetOrigState() {
        this.setState(
            {
                numDeg:         0
            }
        );
        this.video.currentTime = Math.round( 1000 * this.props.singlePostJSON.video.duration / 2 )/1000;
        this.video.pause();
        //this.pauseVideo();
        this.video.controls = false;
    }
    
    handleTouchEnd(e) {
        if (!this.state.scrubbing && !this.state.scrolling) {
            this.playPauseVideo();
        }
        
        // inertial scroll
        if (this.state.scrolling) {
            var pixelsPerMoveY =    this.state.pixelsPerMove;
			
			// scroll or scrub
            if (pixelsPerMoveY >= 0) {
                (function inertialScroll(i) {          
                   setTimeout(function () {   
                      window.scrollTo(0, window.scrollY + i); 
                      i = i * 0.88;              
                      if ( i >= 1) inertialScroll(i);
                   }, 8)
                })(pixelsPerMoveY);

            } else {
                (function inertialScroll(i) {          
                   setTimeout(function () {   
                      window.scrollTo(0, window.scrollY + i); 
                      i = i * 0.88;             
                      if (i <= -1) inertialScroll(i);
                   }, 8)
                })(pixelsPerMoveY);
            }  
        }
        
        this.setState(
            {
                touching:         false,
                scrolling:        false,
                scrubbing:        false
            }
        )
    }
    
    handleTouchStart(e) {
        e.preventDefault();
        //this.playPauseVideo();
        //this.setVideoParams(e);
        this.setState(
            {
                touching    : true,
                touchdownX  : e.touches[0].clientX,
                touchdownY  : e.touches[0].clientY,
                scrollY     : window.scrollY,
            }
        )
    }
    
    handleTouchMove(e) {
        
        e.preventDefault();
        e.stopPropagation();    // to stop scroll
        
        var deltaX = this.state.touchdownX - e.touches[0].clientX;
        var deltaY = this.state.touchdownY - e.touches[0].clientY;
        
        var pixelsPerMove = (this.state.lastY == undefined)
                                ? deltaY
                                : this.state.lastY - e.touches[0].clientY;
        
        this.setState(
            {
                pixelsPerMove: pixelsPerMove,
                lastY:         e.touches[0].clientY,
            }
        );
        
        
        if (!this.state.scrubbing && !this.state.scrolling) {
            if (deltaY > 5 || deltaY < -5) {
                this.setState(
                    {
                        scrolling:         true,
                    }
                );
            } else if (deltaX > 5 || deltaX < -5) {
                this.setState(
                    {
                        scrubbing:         true,
                    }
                );
            }
        }

        if (this.state.scrubbing) { 
            this.setVideoParams(e);
        } else if (this.state.scrolling) {
            window.scrollTo(0, this.state.scrollY + deltaY);
        }
        
        /*
        if ((deltaY > 60) || (deltaY < -60)) {
            window.scrollTo(0, this.state.scrollY + deltaY)
        } else {
            this.setVideoParams(e);
        }
        
        if (e.touches.length > 2) {
            e.preventDefault();
            this.setVideoParams(e);
            this.setState(
                {
                    touching:         true
                }
            )
        } else {
            this.setState(
                {
                    touching:         false
                }
            )
            return false;
        }
        */
    }
    
  render() {
      var singlePostJSON =      this.props.singlePostJSON;
      //var setVideoParams =      this.setVideoParams;
      //var resetOrigState =      this.resetOrigState; 
      //var handleTouchStart =    this.handleTouchStart;
      //var handleTouchEnd =      this.handleTouchEnd;
      //var handleTouchMove =     this.handleTouchMove;
      //var playPauseVideo =           this.playPauseVideo;
      
      var width  = (singlePostJSON.video.width)
                      ? singlePostJSON.video.width.toString() + "px"   
                      : "512px";
      var height = (singlePostJSON.video.height)
                      ? singlePostJSON.video.height.toString() + "px"
                      : "384px";
                      

      var arrowLeft =   parseInt(width.replace("px", "")) / 2  - 25;
          arrowLeft =   arrowLeft + "px";
      var arrowTop =    parseInt(height.replace("px", "")) / 2 - 25;
          arrowTop =    arrowTop + "px";
      
      //var currentTime =         2 * this.state.percentX;
      // "backgroundImage":    "url(" + this.props.globalProps.GCS_VIDEO_PATH + "t/" + singlePostJSON.postID + ")"
      
      return (
          <div  className = 	"noControls" 
		  		style =         {{
                                      "height"  : height, 
                                      "width"   : width,
                                }}
		  >
          <video webkit-playsinline="true" webkitplaysinline="true" playsInline
              ref               = "theVideo"
              className         = "viewer noControls noTouchAction"
              type              = "video/mp4"
              poster            = {`${this.props.globalProps.GCS_VIDEO_PATH}t/${singlePostJSON.postID}`}
              src               = {`${this.props.globalProps.GCS_VIDEO_PATH}p/${singlePostJSON.postID}`}
              muted             = {true}
              preload           = "none"
              style             = {{
                                      //"backgroundColor": "rgba(0, 0, 0, 0)",
		                        	  "height"          : height,
			                          "width"           : width,
                                      "transform"       : "perspective(3000px) rotateY(" + -1 * this.state.numDeg + "deg)",
                                      "WebkitTransform" : "perspective(3000px) rotateY(" + -1 * this.state.numDeg + "deg)",
                                      "MozTransform"    : "perspective(3000px) rotateY(" + -1 * this.state.numDeg + "deg)",
                                      "MsTransform"     : "perspective(3000px) rotateY(" + -1 * this.state.numDeg + "deg)",
                                      //"backgroundImage":    "url(" + this.props.globalProps.GCS_VIDEO_PATH + "t/" + singlePostJSON.postID + ")"
                                      //"position":           "absolute",
                                }}
              onContextMenu     = {function(e){
                                    e.preventDefault();
                                    return false;
                                }}
              onTouchStart      = {function(e){
                                    e.preventDefault();
                                    return false;
                                }}
              onTouchMove       = {function(e){
                                    e.preventDefault();
                                    return false;
                                }}
          /> 
          <div      ref = "videoOverlay"
                    className = "noTouchAction"
                    style={{
                        "display"       : "block",
                        "position"      : "absolute", 
                        "zIndex"        : 999,
                        //"border"      : "1px solid blue",
                        "height"        : height,
                        "width"         : width,
                        "top"           : 0,
                        "left"          : 0,
                    }}
                    onMouseEnter =  { this.playPauseVideo.bind(this) }
                    onMouseLeave =  { function(e){
                                        this.resetOrigState();
                                    }.bind(this)}
          		    onMouseMove =   {function(e){
                                        if (!isMobile) {
                                            this.setVideoParams(e);
                                        }
          		                    }.bind(this)}
                    onTouchMove =   {function(e){
                                        e.preventDefault();
        		                        this.handleTouchMove(e)
                                        //
                                        //setVideoParams(e);
                                    }.bind(this)}
          		    onTouchStart =  {function(e){
                  			            //e.preventDefault();
                                        this.handleTouchStart(e);
                                        //setVideoParams(e);
          		                    }.bind(this)}
        		    onTouchEnd =    {function(e){
                			            //e.preventDefault();
                                        this.handleTouchEnd(e);
        		                    }.bind(this)}
          >
              <div  ref="playArrow"
                    style={{
                        "display"   : "block",
                        "position"  : "absolute", 
                        "top"       : arrowTop,
                        "left"      : arrowLeft,
                    }}
          		    onTouchStart    = {function(e){
                  			            //e.preventDefault();
                                        this.handleTouchStart(e);
                                        //setVideoParams(e);
          		                    }.bind(this)}
        		    onTouchEnd      = {function(e){
                			            //e.preventDefault();
                                        this.handleTouchEnd(e);
        		                    }.bind(this)}
              >
                  <img src="/static/img/playArrow.png" alt="" />
              </div>
            </div>
        </div>
      );
    }
}


export default VideoContainer;
